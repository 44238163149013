import React from 'react'
import './main.css';
import footerIcon from '../../assests/icons/footer-logo.png';
import { updatePrivacyPolicyFlag, updateTermofUseFlag } from '../../redux/invoice';
import { useDispatch } from 'react-redux';
import PrivacyPolicy from './privacypolicy';
import TermofUse from './termofuse';

const FooterPage = () => {
    const dispatch = useDispatch()
    return (
        <div>
            <footer className="website-footer py-5 shadow bg-white">
                <div className="container">
                    <div className="footer-info">
                        <div className="row">
                            <div className="col-md-5 mb-4 mb-md-0">
                                <div className="footer-logo">
                                    <img src={footerIcon} />
                                    <p>Join Live Look specializes in enterprise solutions for all businesses across the globe.</p>
                                </div>
                            </div>
                            {/* <div className="col-md-3 mb-4 mb-md-0">
                                <div className="footer-nav">
                                    <h4>Navigation</h4>
                                    <ul className="list-unstyled m-0 p-0">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="">Web Development</a></li>
                                        <li><a href="">Blog</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="footer-nav">
                                    <h4>Useful Links</h4>
                                    <ul className="list-unstyled m-0 p-0">
                                        {/* <li><a href="">Help Center</a></li> */}
                                        <li><a href="/contact-us">Contact Us</a></li>
                                        <li  onClick={(e) => { e.preventDefault(); dispatch(updatePrivacyPolicyFlag(true))}}><a href="#">Privacy Policy</a></li>
                                        <li onClick={(e) => {e.preventDefault(); dispatch(updateTermofUseFlag(true))}}><a href="#">Terms of Use</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="git">
                                    <h4>Get In Touch</h4>
                                    <ul className="list-unstyled m-0 p-0">
                                        <li><i className="fa fa-map-marker"></i><a href="#!">Gilbert, Arizona, 85233, United States</a></li>
                                        {/* <li><i className="fa fa-phone"></i><a href="tel:+123456789">(123) 456-789</a></li> */}
                                        <li><i className="fa fa-envelope"></i><a href="mailto:team@contact.com">info@joinlivelook.com</a></li>
                                    </ul>
                                </div>
                                <div className="footer-social">
                                    <ul className="list-unstyled m-0 p-0 d-flex">
                                        <li><a href=""><i className="fa fa-instagram"></i></a></li>
                                        {/* <li><a href=""><i className="fa fa-twitter"></i></a></li> */}
                                        {/* <li><a href=""><i className="fa fa-linkedin-square"></i></a></li> */}
                                        <li><a href=""><i className="fa fa-facebook"></i></a></li>
                                        {/* <li><a href=""><i className="fa fa-youtube-play"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="footer-copyright pt-4 mt-md-4">
                        <div className="row">

                            <div className="col-md text-center text-md-end order-md-1"><a  onClick={(e) => { e.preventDefault(); dispatch(updatePrivacyPolicyFlag(true))}} href="#">Privacy Policy</a> | <a onClick={(e) => {e.preventDefault(); dispatch(updateTermofUseFlag(true))}} href="#">Terms of Use</a></div>
                            <div className="col-md text-center text-md-start">&copy; 2024 JLL. All Rights Reserved.</div>
                        </div>
                    </div>
                </div>
            </footer>
            <PrivacyPolicy />
            <TermofUse />
        </div>
    )
}

export default FooterPage
