import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UpgradeConfirmationModal = ({ show, handleClose, paymentDetails }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userLists = useSelector(state => state.allUserData.users)
  const projectList = useSelector(state => state.project.projectsData)

  const handleConfirm = async (event) => {
    event.currentTarget.disabled = true;
    try {
      console.log('Subscription Upgrade...')
      navigate('/choose-plans', { state: { firstName: userData.first_name, unique_url: userData.owned_by ? userData.owned_by : userData.unique_url, email: userData.email, paymentDetails, currentUsers: userLists.length, currentProject: projectList.length } });
      handleClose()
    } catch (error) {
      console.error(error, 'Error is here in handleAlpha UpgradeConfirmationModal')
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Upgrade</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to upgrade your subscription</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default UpgradeConfirmationModal
