import React, { useState } from 'react'
import './otp.css';
import Logo from "../../assests/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom'
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useSelector } from 'react-redux';

const JiraSignup = () => {
    const navigate = useNavigate()
    const [jiraToken, setJiraToken] = useState("")
    const [email, setEmail] = useState("")
    const [jiraDomain, setJiraDomain] = useState("")

    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    let UniqueUrl = userData?.unique_url

    let projectNamesArray = localStorage.getItem("JLL_ProjectList")
    projectNamesArray = projectNamesArray ? JSON.parse(projectNamesArray) : {}

    const handleUniqueUrlCheck = async () => {

        const payload = {
            jira_token: jiraToken,
            jira_domain: jiraDomain,
            jira_useremail: email
        }
        try {
            // const response = await apiServiceHandler("POST", "api/auth/jiraSignup", payload);
            // console.log(response)
            // toastr.success(response.message)
            navigate(`/${UniqueUrl}`)
        } catch (e) {

            console.error(e)
            // toastr.success("Login via code here")
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleUniqueUrlCheck();
        }
    };
    return (
        <div className="bg-image">
            <div className="bg-box">
                <div className="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="signupPage" className="signup-page shadow">
                    <p className="mb-3">Welcome to <strong>Live Look</strong></p>
                    <h2 className="mb-4">Enter your Jira Details</h2>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">

                            <div className="col-md-6 mb-3">
                                <label for="exampleFormControlInput1" className="form-label" >Email</label>
                                <input name="email" className="form-control" id="exampleFormControlInput1" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your jira email" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label for="exampleFormControlInput1" className="form-label" >Jira Domain</label>
                                <input name="jira_domain" className="form-control" id="exampleFormControlInput1" value={jiraDomain} onChange={(e) => setJiraDomain(e.target.value)} placeholder="Enter your jira domain" />
                                {/* <p className="helper-text">Want to generate a Jira Token?{' '}<a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/#Create-an-API-token/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >click here</a></p> */}
                            </div>
                            <div className="col-md-12 mb-5">
                            <label for="exampleFormControlInput1" className="form-label" >Jira Token</label>
                                <input name="jira_token" onChange={(e) => setJiraToken(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Jira Token"
                                //  value={uniqueUrlvalue} onChange={(e) => setUniqueUrlvalue(e.target.value)}
                                />
                                <p className="helper-text">Want to generate a Jira Token?{' '}<a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/#Create-an-API-token/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >click here</a></p>
                            </div>
                            <div className="btn-group-signin">
                                {/* <button onClick={() => navigate("/Dashboard/UniqueUrl")} type="button" className="prev-btn">Back</button> */}
                                <button onClick={handleUniqueUrlCheck} type="button" className="next-btn">Next</button>
                            </div>

                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default JiraSignup
