import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { updateTermofUseFlag } from '../../redux/invoice';
import { useDispatch, useSelector } from 'react-redux';

const TermofUse = () => {
    const termofUseFlag = useSelector((state) => state.invoice.termofUseFlag);
    const dispatch = useDispatch()

    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${termofUseFlag ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabIndex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: termofUseFlag ? "block" : "none" }}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content border-0 ">
                        <div className="modal-header">
                            <h2 className="modal-title">Terms of Use</h2>
                            <div style={{ display: "flex" }}>

                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(updateTermofUseFlag(false))}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>

                        </div>

                        <div className="modal-body">
                            
                                <h3>Introduction</h3>

                                <p>These terms and conditions ("Terms") govern the use of this website and any services provided through it (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services.</p>

                                <h3>Use of Services</h3>

                                <p>The Services are intended for use by businesses, entrepreneurs, and investors seeking to establish or grow their operations in United States. You may use the Services only for lawful purposes and in accordance with these Terms.
                                </p>
                                <h3>Content and Information</h3>

                                <p>The content and information provided on this website is for general informational purposes only and is not intended as advice or consultation for any specific business or situation. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.
                                </p>
                                <h3>Intellectual Property</h3>

                                <p>The content and information on this website, including but not limited to text, graphics, logos, images, and software, is the property of JoinLiveLook or its licensors and is protected by copyright and trademark laws. You may not use any content or information on this website for commercial purposes without the express written consent of Perlin Ventures.
                                </p>
                                <h3>Limitation of Liability</h3>

                                <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                                </p>
                                <h3>Changes to Terms</h3>

                                <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the modified Terms on this website. Your continued use of the Services following the posting of any changes to these Terms will constitute your acceptance of those changes.
                                </p>
                                <h3>Contact</h3>

                                <p>If you have any questions or concerns about these Terms or the Services, please contact us at info@joinlivelook.com
                                </p>
                                <h3>Governing Law</h3>

                                <p>These terms and conditions are governed by and construed in accordance with the laws of the United States of America and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                                </p>
                           

                        </div>

                        {/* <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleSave}>Save</button>
                        </div> */}

                    </div>
                </div>
            </div>

        </div>
    )
}

export default TermofUse;
