import React, { useEffect, useState } from 'react'
import CopyIcon from '../../assests/images/copy.svg';
import SaveIcon from '../../assests/images/save.svg';
import { updateLastSelectedProjectId, updateRefreshProjectList } from '../../redux/project';
import apiServiceHandler from '../../service/apiService';
import { useDispatch, useSelector } from 'react-redux';
import './welcome.css';



const ProgressProjectList = ({value}) => {
    const [projectDetails, setProjectDetails] = useState([])
    const [isOpenAcc, setIsOpenAcc] = useState(false);
    const dispatch = useDispatch()
    const projects = useSelector(state => state.project?.showProjectList)
    const [editDetails, setEditDetails] = useState({})
    const [selectedProject, setSelectedProject] = useState(null);
    let role = localStorage.getItem('userData')
    role = role ? JSON.parse(role) : []
    role = role.role

    const handleSave = (value) => {
        // setEditDetails(value)
        updateProjectDetails(value?._id)
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const updateProjectDetails = async (id) => {
        // e.preventDefault()
        let newObj = {
            id: id,
            project_url: editDetails?.project_url,
            project_description: editDetails?.project_description,
         }

        try {
            const response = await apiServiceHandler("POST", "api/project/update", newObj)
            console.log(response)
            dispatch(updateRefreshProjectList(true))
            dispatch(updateLastSelectedProjectId(value._id));

        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }
    // useEffect(() => {
    //     if (projects && projects.length > 0) {
    //         setEditDetails({ project_url: projects[0].project_url,
    //                          project_description: projects[0].project_description });
    //     }
    //     setProjectDetails(projects)
    // }, [projects]);

    useEffect(() => {

        setEditDetails({
            project_url: value?.project_url,
            project_description: value?.project_description 
        })
    }, [value])

    const toggleAccordion = (value) => {
        setIsOpenAcc(!isOpenAcc);
        // setSelectedProject(value); 
        // if (!isOpenAcc) {
        //     const selectedProjectDetails = projects.find(project => project._id === value._id);
        //     setEditDetails({
        //         project_url: selectedProjectDetails?.project_url || "",
        //         project_description: selectedProjectDetails?.project_description || ""
        //     });
        // }
    };
  return (
    <div>
        <div className="accordion-item" key={value._id}>
                                <div className="accordion-header d-flex align-items-center justify-content-between" id={`progress${value._id}`}>
                                    {value.project_url ? 
                                    <h3><a href={value.project_url} target='_blank'>{value?.project_name}</a></h3> :
                                    <h3>{value?.project_name}</h3>
                                    }
                                    <div className="progree-btn d-flex align-items-center">
                                        {/* <button type="button" className="no-default" ><img src={PencilIcon} /></button> */}
                                        <button type="button" className="edit-icon" onClick={() => handleSave(value)}><img src={SaveIcon} /></button>
                                        {/* <button type="button" className="no-default"><img src={CloseBIcon} /></button> */}
                                        <button className="accordion-button collapsed no-default" type="button" onClick={() => toggleAccordion(value)} data-bs-toggle="collapse" data-bs-target={`#progressView${value._id}Btn`} aria-expanded="true" aria-controls={`progressView${value._id}Btn`}></button>
                                    </div>

                                </div>
                                <div id={`progressView${value._id}Btn`} className={`accordion-collapse collapse${isOpenAcc && selectedProject?._id === value._id ? ' show' : ''}`} aria-labelledby={`progress${value._id}`} data-bs-parent="#viewProgress">
                                    <div className="accordion-body pt-0 px-0 ">
                                        <div className="mb-2 url-input">
                                            <input disabled={role === "client"} type="text" name='project_url' placeholder="project URL" value={editDetails?.project_url} className="form-control" onChange={(e) => handleChange(e)} />
                                            <button type="button" className="edit-icon"><img src={CopyIcon} /></button>
                                        </div>
                                        <div className="progress-txt">
                                            <textarea disabled={role === "client"} className="form-control" placeholder="Add Description" name='project_description' value={editDetails?.project_description || ""} rows="3" onChange={(e) => handleChange(e)}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
      
    </div>
  )
}

export default ProgressProjectList
