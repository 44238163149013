// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const Invoice = createSlice({
  name: 'invoice',
  initialState: {
    openInvoicepopup: false,
    openInvoiceFromTimeLogpopup: false,
    openPayPalsetup: false,
    invoiceApprovrFlag: false,
    refreshInvoiceList: false,
    selectedInvoiceForActions: {},
    paypalSetupData: {},
    totalTimeLogData: {},
    selectedDateRange: '',
    privacyPolicyFlag: false,
    termofUseFlag: false,

  },
  reducers: {
    updateopenInvoicepopup: (state, action) => {
      state.openInvoicepopup = action.payload; // Update the count using the payload
    },
    updatopenPayPalsetup: (state, action) => {
      state.openPayPalsetup = action.payload; // Update the count using the payload
    },
    updatopenInvoiceApprovrFlag: (state, action) => {
      state.invoiceApprovrFlag = action.payload; // Update the count using the payload
    },
    updateRefreshInvoiceList: (state, action) => {
      state.refreshInvoiceList = action.payload; // Update the count using the payload
    },
    updateSelectedInvoiceForActions: (state, action) => {
      state.selectedInvoiceForActions = action.payload; // Update the count using the payload
    },
    updatePaypalSetupData: (state, action) => {
      state.paypalSetupData = action.payload; // Update the count using the payload
    },
    updateOpenInvoiceFromTimeLogpopup: (state, action) => {
      state.openInvoiceFromTimeLogpopup = action.payload; // Update the count using the payload
    },
    updateTotalTimeLogData: (state, action) => {
      state.totalTimeLogData = action.payload; // Update the count using the payload
    },
    updateSelectedDateRange: (state, action) => {
      state.selectedDateRange = action.payload; // Update the count using the payload
    },
      updatePrivacyPolicyFlag: (state, action) => {
      state.privacyPolicyFlag = action.payload; // Update the count using the payload
    },
    updateTermofUseFlag: (state, action) => {
      state.termofUseFlag = action.payload; // Update the count using the payload
    },
  },  
});

export const { updateopenInvoicepopup, updatopenPayPalsetup, updatopenInvoiceApprovrFlag, updateRefreshInvoiceList,
  updateSelectedInvoiceForActions, updatePaypalSetupData, updateOpenInvoiceFromTimeLogpopup,
  updateTotalTimeLogData, updateSelectedDateRange, updatePrivacyPolicyFlag, updateTermofUseFlag  } = Invoice.actions;
export default Invoice.reducer;
