import React, { useEffect, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Select from "react-select";
import apiServiceHandler from "../../service/apiService";

const ShareFolder = ({ isOpen, onClose, indexData }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const usersData = useSelector((state) => state.allUserData.users);

  useEffect(() => {
    setUsers(
      usersData.map((user) => ({
        value: user.unique_url,
        label: user.unique_url,
        _id: user._id
      }))
    );
  }, []);

  const handleChange = (selectedUsers) => {
    setSelectedUsers(selectedUsers || []);
  };

  const handleShareWithAll = async () => {
    let payload = { id: indexData, permissions: users.map((user) => user._id) };

    let response = [];

    try {
      response = await apiServiceHandler(
        "POST",
        "api/file/management/update/permission",
        payload
      );
      // dispatch(updateRefreshFolderData(true));
      // dispatch(updateRefreshFolders(true));
      onClose();
      toastr.success(response?.message);
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

  const handleShare = async () => {
    let payload = { id: indexData, permissions: selectedUsers.map((user) => user._id) };

    let response = [];

    try {
      response = await apiServiceHandler(
        "POST",
        "api/file/management/update/permission",
        payload
      );
      // dispatch(updateRefreshFolderData(true));
      // dispatch(updateRefreshFolders(true));
      onClose();
      toastr.success(response?.message);
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  }

  return (
    <div>
      <div
        className={`modal fade add-user edit-task-viewmore ${
          isOpen ? "show backdrop" : " "
        }`}
        id="addUserModal"
        tabindex={-1}
        aria-labelledby="addUserModalLabel"
        aria-hidden={true}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h2 className="modal-title">Share Folder</h2>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CloseIcon} />
              </button>
            </div>

            <div className="modal-body">
              <button
                type="submit"
                className="bg-btn"
                style={{ width: "100%", marginBottom: "15px" }}
                onClick={handleShareWithAll}
              >
                Share With All Project Members
              </button>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5 style={{ fontWeight: "bolder" }}>OR</h5>
              </div>
              <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                Share will the following users:
              </div>
              <form>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <Select
                      options={users}
                      onChange={handleChange}
                      value={selectedUsers}
                      isSearchable={true}
                      isMulti
                      className="multi-select"
                      placeholder="Share With*"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer justify-content-between">
              <button type="submit" className="bg-btn" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="bg-btn" onClick={handleShare}>
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareFolder;
