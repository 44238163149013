// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const Clickup = createSlice({
  name: 'clickup',
  initialState: {
    clickupStatus: false,
    clickupJLLProjectsList: [],
    // clickupJLLProjectsList: [],
    
  },
  reducers: {
    updateClickupStatus: (state, action) => {
      state.clickupStatus = action.payload; // Update the count using the payload
    },
    updateClickupJLLProjectsList: (state, action) => {
      state.clickupJLLProjectsList = action.payload; // Update the count using the payload
    },
    
  },
});

export const { updateClickupStatus, updateClickupJLLProjectsList} = Clickup.actions;
export default Clickup.reducer;
