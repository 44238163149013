export const isEmpty = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    }
    
    if (typeof obj !== 'object') {
      return true;
    }
    
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    
    return true;
  }
  