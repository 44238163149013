// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const loader = createSlice({
  name: 'loader',
  initialState: {
    loaderStatus: false,
    
    
  },
  reducers: {
    updateloaderStatus: (state, action) => {
      state.loaderStatus = action.payload; // Update the count using the payload
    },
    
   
    
  },
});

export const { updateloaderStatus  } = loader.actions;
export default loader.reducer;
