import { Navigate, useNavigate } from 'react-router-dom';

// interface PrivateRouteProps {
//   //path: string;
//   element: React.ReactElement;
//   //isLoggedIn: boolean;
// }

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate()
  let id = window.location.href.split("/")
  id = id[id.length - 1]
  const isLoggedIn = localStorage.getItem("JLL_TOKEN")
  return isLoggedIn ? (
    element
  ) : (
    // navigate(`/dashboard/${id}`)
    // <Navigate to={`/dashboard/${id}`} replace />
    <Navigate to="/signin-url" replace />
  );
};

export default PrivateRoute;
