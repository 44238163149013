import React, { useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const RoleModal = ({ isOpen, onClose, setRefreshRoleList }) => {
    const [newRoleName, setNewRoleName] = useState("")
    const user = JSON.parse(localStorage.getItem('userData'));

    const handleAddNewRole = async () => {
        if (newRoleName === "") {
            toastr.error("Please add a new role")
            return
        }
        let payload = {
            company_id: user.company_id ? user.company_id : user.parent_compnay_id,
            role: newRoleName,
            accessToApis: {}
        }
        try {
            const response = await apiServiceHandler("PUT", "api/auth/company_details", payload)
            // console.log(response)
            setRefreshRoleList(true)
            onClose()
            toastr.success("Role added successfully")
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.error(err)
        }
    }
    return (
        <div>
            <div className={`modal fade enter-role ${isOpen ? "show backdrop" : " "}`}
                // className="modal fade enter-role" 
                id="enterRoleModal"
                tabindex={-1}
                aria-labelledby="enterRoleModalLabel"
                aria-hidden="true"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">Enter Role</h2>
                            <button data-toggle="tooltip" data-placement="top" title="Close" type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <img src={CloseIcon} />
                            </button>
                        </div>

                        <div className="modal-body">
                            <form>
                                <label>Enter Role Name</label>
                                <input onChange={(e) => setNewRoleName(e.target.value)} type="text" className="form-control" placeholder="Enter role name*" />
                            </form>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button onClick={onClose} type="button" data-bs-dismiss="modal" className="border-btn">Cancel</button>
                            <button onClick={() => handleAddNewRole()} type="submit" className="bg-btn">Add Role</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default RoleModal
