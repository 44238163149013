import React, { useEffect, useRef, useState, useCallback } from "react";
import "./documents.css";
import Folder from "../../assests/images/folder.png";
import ExpandIcon from "../../assests/images/expand.svg";
import FileFolder from "../../assests/images/FileFolder.svg";
import DragIcon from "../../assests/images/drag.svg";
import Arrow from "../../assests/images/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import {
  updateDocumentsPopUp,
  updateupcomingHeadline,
} from "../../redux/viewmore";
import apiServiceHandler from "../../service/apiService";
import {
  updateCrumbsData,
  updateFolderData,
  updateFolderIndex,
  updateFoldersList,
  updateRefreshFolderData,
  updateRefreshFolders,
} from "../../redux/documents";
import AllFiles from "./AllFiles";
import Dots from '../../assests/images/vertical-dots.svg'
// import { useDropzone } from "react-dropzone";

const Documents = ({ setDocumentsProps, flag, showPageFlag, setShowPageFlag }) => {
  const dispatch = useDispatch();
  //   const [showPageFlag, setShowPageFlag] = useState("");
  const upcomingPopup = useSelector((state) => state.viewmore);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);
  const [openNewFileModal, setOpenNewFileModal] = useState(false);
  const [openRenameFolderModal, setOpenRenameFolderModal] = useState(false);
  const [deleteFolderModal, setDeleteFolderModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [indexData, setIndexData] = useState();
  const [openChildFolderModal, setOpenChildFolderModal] = useState(false);
  const [openShareFolderModal, setOpenShareFolderModal] = useState(false);
  const [openRenameChildFolderModal, setOpenRenameChildFolderModal] =
    useState(false);
  const [crumbs, setCrumbs] = useState([]);
  const refreshFolders = useSelector((state) => state.documents.refreshFolders);

  const folderData = useSelector((state) => state.documents.foldersList);
  const activeProject = useSelector((state) => state.project?.projectsData[0])

  const handleNewFolder = () => {
    setOpenNewFolderModal(true);
  };

  const handleNewFile = () => {
    setOpenNewFileModal(true);
  };

  const handleDeleteFolder = (id) => {
    setDeleteFolderModal(true);
    setIndexData(id);
  };

  const handleChildNewFolder = () => {
    setOpenChildFolderModal(true);
  };

  const handleChildRenameFolder = (id) => {
    setOpenRenameChildFolderModal(true);
    setIndexData(id);
  };

  const handleRenameFolder = (id) => {
    setOpenRenameFolderModal(true);
    setIndexData(id);
  };

  const handleShareFolder = (id) => {
    setOpenShareFolderModal(true);
    setIndexData(id);
  };

  const handleClose = () => {
    setOpenNewFolderModal(false);
    setOpenNewFileModal(false);
    setDeleteFolderModal(false);
    setOpenRenameFolderModal(false);
    setOpenChildFolderModal(false);
    setOpenRenameChildFolderModal(false);
    setOpenShareFolderModal(false);
  };

  useEffect(() => {
    if (openNewFolderModal) {
      setDocumentsProps({
        ModalName: "AddNewFolder",
        isOpen: openNewFolderModal,
        onClose: handleClose,
        activeProject: activeProject

      });
    } else if (openNewFileModal) {
      setDocumentsProps({
        ModalName: "AddNewFile",
        isOpen: openNewFileModal,
        onClose: handleClose,
        activeProject: activeProject
      });
    } else if (deleteFolderModal) {
      setDocumentsProps({
        ModalName: "DeleteFolder",
        isOpen: deleteFolderModal,
        onClose: handleClose,
        indexData: indexData,
        crumbs: crumbs,
      });
    } else if (openRenameFolderModal) {
      setDocumentsProps({
        ModalName: "RenameFolder",
        isOpen: openRenameFolderModal,
        onClose: handleClose,
        indexData: indexData,
        folders: folders,
        crumbs: crumbs,
      });
    } else if (openChildFolderModal) {
      setDocumentsProps({
        ModalName: "AddChildFolder",
        isOpen: openChildFolderModal,
        onClose: handleClose,
        indexData: indexData,
        activeProject: activeProject
      });
    } else if (openRenameChildFolderModal) {
      setDocumentsProps({
        ModalName: "RenameChildFolder",
        isOpen: openRenameChildFolderModal,
        onClose: handleClose,
        indexData: indexData,
        folders: subFolders,
      });
    } else if (openShareFolderModal) {
      setDocumentsProps({
        ModalName: "ShareFolder",
        isOpen: openShareFolderModal,
        onClose: handleClose,
        indexData: indexData,
      });
    } else {
      setDocumentsProps(null);
    }
  }, [
    openNewFolderModal,
    openNewFileModal,
    deleteFolderModal,
    openRenameFolderModal,
    openChildFolderModal,
    openRenameChildFolderModal,
    openShareFolderModal,
  ]);

  const listRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (listRef.current && !listRef.current.contains(event.target)) {
        closeList();
      }
    }

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const getFolders = async () => {

    let payload = {
      projectId: activeProject?._id,
    }

    try {
      let response = [];

      if (activeProject?._id) {
        response = await apiServiceHandler(
          "POST",
          "api/file/management/get/folder",
          payload
        );
      }

      if (response?.data) {
        response?.data.forEach((res) => {
          res.listStatus = false;
        });
      }
      setFolders(response?.data);
      dispatch(updateFoldersList(response?.data));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const displayList = (id) => {
    setFolders(
      folders?.map((fold) => {
        if (fold._id === id) {
          return { ...fold, listStatus: !fold.listStatus };
        } else {
          return { ...fold, listStatus: false };
        }
      })
    );

    setSubFolders(
      subFolders?.map((fold) => {
        if (fold._id === id) {
          return { ...fold, listStatus: !fold.listStatus };
        } else {
          return { ...fold, listStatus: false };
        }
      })
    );
  };

  const closeList = () => {
    setFolders(
      folders?.map((fold) => {
        return { ...fold, listStatus: false };
      })
    );

    setSubFolders(
      subFolders?.map((fold) => {
        return { ...fold, listStatus: false };
      })
    );
  };

  const [subFolders, setSubFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [index, setIndex] = useState();

  const getAllFiles = async (id) => {
    try {
      let response = [];
      if (id)
        response = await apiServiceHandler(
          "GET",
          `api/file/management/get/folder/${id}`,
          {}
        );

      if (response?.data?.sub_folder) {
        response?.data?.sub_folder.forEach((res) => {
          res.listStatus = false;
        });
      }

      setSubFolders(response?.data?.sub_folder);
      setIndexData(id);
      setIndex(id);
      dispatch(updateFolderIndex(id));
      dispatch(updateFolderData(response?.data));
      setFiles(response?.data?.file);

      if (crumbs?.length == 0) {
        folderData.map((data) => {
          if (data._id === id) {
            setCrumbs([data]);
            dispatch(updateCrumbsData([data]));
          }
        });
      } else {
        const newCrumb = subFolders?.find((fol) => fol._id === id);

        if (!newCrumb) return;
        setCrumbs((crumbs) => [...crumbs, newCrumb]);
        dispatch(updateCrumbsData([...crumbs, newCrumb]));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const refreshFolderData = useSelector(
    (state) => state.documents.refreshFolderData
  );

  useEffect(() => {
    if (refreshFolderData) {
      getAllFiles(index);
      dispatch(updateRefreshFolderData(false));
    }
  }, [refreshFolderData]);

  useEffect(() => {
    getFolders();
  }, [activeProject]);

  useEffect(() => {
    if (refreshFolders) {
      getFolders();
      dispatch(updateRefreshFolders(false));
    }
  }, [refreshFolders]);

  const crumbsData = useSelector((state) => state.documents.crumbsData);

  const getCrumbData = async (val) => {
    try {
      let response = [];
      response = await apiServiceHandler(
        "GET",
        `api/file/management/get/folder/${val?._id}`,
        {}
      );

      if (response?.data?.sub_folder) {
        response?.data?.sub_folder.forEach((res) => {
          res.listStatus = false;
        });
      }

      setSubFolders(response?.data?.sub_folder);
      setIndexData(val._id);
      setIndex(val._id);
      dispatch(updateFolderIndex(val._id));
      dispatch(updateFolderData(response?.data));
      setFiles(response?.data?.file);

      const crumbIndex = crumbsData?.indexOf(val);
      const crumbs_data = crumbsData?.slice(0, crumbIndex + 1);
      setCrumbs(crumbs_data);
      dispatch(updateCrumbsData(crumbs_data));
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <section class="find-files mb-4">
        <div
          // class="bg-white shadow files-inner"
          class={flag ? "bg-white shadow files-inner-viewmore" : "bg-white shadow files-inner"}
        >
          <div class="file-search">

            <div class="row align-items-center documents-header">
              <div class="col-lg-4">
                {!flag && <h2>Files and Folders</h2>}
              </div>
              {!upcomingPopup.documentsPopup && (
                <div
                  class="col-lg-8"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div class="documents d-flex justify-content-between align-items-center">
                    <div class="file-style tooltip_element">
                      <button
                        type="button"
                        class="type-2"
                        onClick={
                          indexData ? handleChildNewFolder : handleNewFolder
                        }
                      >
                        <div
                          width={window.screen.width < 760 ? "30" : "40"}
                          height={window.screen.width < 760 ? "20" : "30"}
                          style={{ marginRight: "10px" }}
                        >
                          <img src={FileFolder} />
                        </div>
                      </button>
                      <span className="tooltip">Create a new folder</span>
                      {window.screen.width > 760 && <button
                        type="button"
                        class="add-icon"
                        onClick={() => {
                          dispatch(updateDocumentsPopUp(true));
                          dispatch(updateupcomingHeadline("Files and Folders"));
                        }}
                      >
                        <img src={ExpandIcon} />
                      </button>}
                      <button type="button" class="no-default drag-me">
                        <img src={DragIcon} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="file-body">
            {/* {!upcomingPopup.documentsPopup && showPageFlag == "allFiles" && ( */}
            {showPageFlag == "allFiles" && (
              <div
                style={{
                  borderBottom: "1px solid #cecece",
                  marginBottom: "15px",
                  paddingBottom: "10px",
                  position: "sticky",
                }}
              >
                <div class="col-md-8 d-flex align-items-center gap-2">
                  {crumbsData?.length > 0 && (
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPageFlag("");
                        setCrumbs([]);
                        setIndexData();
                        dispatch(updateCrumbsData([]));
                        dispatch(updateFolderData([]));
                        dispatch(updateFolderIndex(""));
                      }}
                    >
                      Folders
                    </div>
                  )}
                  {crumbsData &&
                    crumbsData?.map((crumb) => (
                      <>
                        <img width="6px" height="10px" src={Arrow} alt="" />
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                          onClick={() => getCrumbData(crumb)}
                        >
                          {crumb?.folder_name?.slice(0, 1).toUpperCase() +
                            crumb?.folder_name.slice(1)}
                          {/* {crumb} */}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}
            <div class="row align-items-center">
              <div class="col-lg-4"></div>
              {showPageFlag == "" && (
                <div class="col-lg-12">
                  <div class="files-listed">
                    <div class="file-folder list-type">
                      <div class="row g-0">
                        <div class="col-2 folders" ref={listRef}>
                          {folders &&
                            folders.map((folder) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  key={folder?._id}
                                  class="folder-type"
                                // onMouseDown={(e) => {
                                //   if (e.button === 2) {
                                //     displayList(folder._id);
                                //   }
                                // }}

                                >
                                  <div className="dots" onClick={() => displayList(folder._id)}>
                                    <img src={Dots} />
                                  </div>
                                  <div
                                    class="file-image"
                                    onClick={() => {
                                      getAllFiles(folder._id);
                                      setShowPageFlag("allFiles");
                                    }}
                                  >
                                    <img src={Folder} />
                                  </div>
                                  {/* <label class="file-name">
                                  {folder?.folder_name}
                                </label> */}
                                  {folder.listStatus && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        padding: "5px 18px",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        boxShadow:
                                          "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
                                        zIndex: 1,
                                        position: "absolute",
                                        left: 70,
                                        top: 30,
                                        height: "120px",
                                        borderRadius: "6px",
                                        background: "#fff",
                                      }}
                                    >
                                      <div
                                        className="list-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          getAllFiles(folder?._id);
                                          setShowPageFlag("allFiles");
                                        }}
                                      >
                                        Open
                                      </div>
                                      <div
                                        className="list-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleShareFolder(folder._id)
                                        }
                                      >
                                        Share
                                      </div>
                                      <div
                                        className="list-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleRenameFolder(folder._id)
                                        }
                                      >
                                        Rename
                                      </div>
                                      <div
                                        className="list-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDeleteFolder(folder._id)
                                        }
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <label className="file-name text-limit">
                                  {folder?.folder_name}
                                  {/* {folder?.folder_name.length > 10 ? `${folder?.folder_name.substr(0, 12)}...` : folder?.folder_name} */}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPageFlag == "allFiles" && (
                <AllFiles
                  listRef={listRef}
                  displayList={displayList}
                  closeList={closeList}
                  folders={subFolders}
                  files={files}
                  indexData={indexData}
                  isOpen={openChildFolderModal}
                  isClose={handleClose}
                  setShowPageFlag={setShowPageFlag}
                  getAllFiles={getAllFiles}
                  handleDeleteFolder={handleDeleteFolder}
                  handleRenameFolder={handleRenameFolder}
                  handleChildRenameFolder={handleChildRenameFolder}
                  index={index}
                  handleShareFolder={handleShareFolder}
                  activeProject={activeProject}
                />
              )}
            </div>
          </div>
        </div>

        {window.screen.width > 760 && !flag && (
          <button
            type="button"
            onClick={() => {
              dispatch(updateDocumentsPopUp(true));
              dispatch(updateupcomingHeadline("Files and Folders"));
            }}
            class="view-more"
          >
            View More
          </button>
        )}
      </section>
    </>
  );
};

export default Documents;
