import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshInvoiceList, updateopenInvoicepopup, updatopenInvoiceApprovrFlag, updatopenPayPalsetup } from '../../redux/invoice';
import "./createInvoice.css";
import Select from "react-select";

const InvoiceApproval = ({ setRefreshData, edituserData }) => {
    const invoiceFlag = useSelector((state) => state.invoice.invoiceApprovrFlag);
    const dispatch = useDispatch()
    const [payPalData, setPayPalData] = useState({})
    const usersList = useSelector((state) => state.allUserData.users);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectedInvoice = useSelector(state => state.invoice.selectedInvoiceForActions)
    const [userUpdatedData, setUserUpdatedData] = useState({
        email: "",
        paypal_client_id: "",
        paypal_client_secret: "",
    })
    const handleChange = (selectedValues) => {
        setSelectedOptions(selectedValues);
    };

    const transformData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.first_name} ${item.last_name} (${item.role})`,
        }));
    };
    const options = transformData(usersList) || [];

    // const handleChange = (event) => {
    //     const { name, value } = event.target;

    //     setUserUpdatedData((prevUser) => ({
    //         ...prevUser,
    //         [name]: value,
    //     }));
    // };

    const handleSave = async () => {

        const payload = {
            invoice_approval_status: 'pending',
            invoice_approval_to: selectedOptions?._id,
            invoice_number: selectedInvoice?.invoice_number
        }

        try {
            let response = await apiServiceHandler("PUT", "api/invoice/approval", payload)
            toastr.success(response?.message)
            dispatch(updatopenInvoiceApprovrFlag(false))
            dispatch(updateRefreshInvoiceList(true))
        } catch (error) {
            toastr.error(error?.message)
            console.log(error)
        }
    }

    // fetch paypal setup data

    const fetchPayPalSetupData = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/payment/paypal/setup", {})
            setPayPalData(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     fetchPayPalSetupData()
    // }, [])

    useEffect(() => {
        if (payPalData) {
            setUserUpdatedData({
                email: payPalData?.client_email || "",
                paypal_client_id: payPalData?.client_id || "",
                paypal_client_secret: payPalData?.client_secret || "",
            })
        }
    }, [payPalData])
    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${invoiceFlag ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabindex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: invoiceFlag ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 ">
                        <div className="modal-header">
                            <h2 className="modal-title">Invoice Approval Request</h2>
                            <div style={{ display: "flex" }}>
                                {/* <button type="button" style={{ width: "50px !important", marginRight: "10px" }} className="bg-btn" onClick={() => dispatch(updateopenInvoicepopup(false))}>
                                    Help
                                </button> */}
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(updatopenInvoiceApprovrFlag(false))}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">

                                    <div className="col-12 mb-2">
                                        <label className="add-project-label">Select user for getting approval:</label>
                                        <Select
                                            options={options}
                                            // isMulti
                                            onChange={handleChange}
                                            value={selectedOptions}
                                            required
                                        />
                                    </div>
                                </div>

                            </form>

                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleSave}>Submit</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default InvoiceApproval;
