import React, { useState } from 'react'
import './otp.css';
import Logo from "../../assests/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

const WelcomeCode = () => {
    const baseUrl = "http://localhost:8080/login"
    const { id } = useParams()
    const navigate = useNavigate()
    const [firstDigitOtp, setFirstDigitOtp] = useState("")
    const [secondDigitOtp, setSecondDigitOtp] = useState("")
    const [thirdDigitOtp, setThirdDigitOtp] = useState("")
    const [fourthDigitOtp, setFourthDigitOtp] = useState("")
    const digitValidate = (ele) => {
        ele.target.value = ele.target.value.replace(/[^0-9a-zA-Z]/g, '');
    }
    const tabChange = (val) => {
        const ele = document.querySelectorAll('input')
        if (ele[val - 1]?.value !== '') {
            ele[val]?.focus();
        } else if (ele[val - 1]?.value === '') {
            if (ele[val - 2]) {
                ele[val - 2].focus();
            } else if (ele[val - 3]) {
                ele[val - 3].focus();
            }
        }
    };
    return (
        <div class="bg-image">
            <div class="bg-box">
                <div class="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="enterOTP" class="enter-opt shadow">
                    <h1 className="mb-2">Setup Welcome Code</h1>
                    <p className="mb-3">Welcome to <strong>Live Look</strong></p>
                    <form>
                        <div class="opt-col my-4 pb-1">
                            <input onChange={(e) => setFirstDigitOtp(e.target.value)} className="form-control" type="text" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(1)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setSecondDigitOtp(e.target.value)} className="form-control" type="text" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(2)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setThirdDigitOtp(e.target.value)} className="form-control" type="text" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(3)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setFourthDigitOtp(e.target.value)} className="form-control" type="text" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(4)} maxLength={1} placeholder="" />
                        </div>
                        
                        <button type="submit" class="submit-btn">Submit</button>
                        {/* <div className="btn-group">
                            <button type="button" className="prev-btn"> Back </button>
                            <button type="button" className="next-btn">Submit</button>

                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default WelcomeCode
