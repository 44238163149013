import React, { useEffect, useState } from 'react'
import './tasksList.css';
import PlusIcon from '../../assests/icons/plusg.svg';
import Filter from '../../assests/images/filter.svg';
import SearchIcon from '../../assests/images/search.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import EditIcon from '../../assests/images/edit.svg';
import EditwhiteIcon from '../../assests/images/pencil-white.svg';
import StartIcon from '../../assests/images/start.svg';
import StopRed from '../../assests/images/stop-red.svg';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from '../../service/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditTaskData, updateRefreshTaskList, updateTasksList } from '../../redux/task';
import { updatetaskPopup, updateupcomingHeadline } from '../../redux/viewmore';
import { convertArrayToMinutes, formatDateToISOString, getTimeDifference, getCurrentFormattedDate } from './timeTracking'
import { updateTicketsList } from '../../redux/chatsandtickets';

const TasksList = ({ setTasksListProps, flag }) => {
    const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
    const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
    const [openTimeTrackingModal, setOpenTimeTrackingModal] = useState(false);
    const dispatch = useDispatch();
    const task = useSelector(state => state.task) || []
    const usersList = useSelector(state => state.allUserData.users)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const projectList = useSelector(state => state.project.projectList)
    const usersTaskList = useSelector(state => state.task.TasksList?.showToClient)
    const [taskUserList, setTaskUserList] = useState([])
    const [currentProject, setCurrentProject] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const upcomingPopup = useSelector(state => state.viewmore)
    let userData = localStorage.getItem("userData");
    userData = userData ? JSON.parse(userData) : {};
    let UniqueUrl = userData?.unique_url

    const FolderSpaces = useSelector(state => state.clickup?.clickupJLLProjectsList) || []

    const projectData = useSelector((state) => state.project.projectsData[0]);
    let isActiveProjectInClickup = false
    if (FolderSpaces)
        isActiveProjectInClickup = Object.keys(FolderSpaces).some(projectName =>
            projectName === projectData?.project_name
        );

    let debounceTimer;
    const debounceTimeout = 500;

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    // const convertTaskObject = (inputs) => {
    function convertTaskObject(inputs) {
        return inputs.map((input) => {
            const output = {
                "_id": input.id,
                "assigned_to": input.assignees.map(assignee => assignee.id),
                "assignees": input.assignees.map(assignee => assignee.username),
                "task_name": input.name,
                "task_details": input.description,
                "status": input?.status?.status,
                "priority": input?.priority?.priority ? (input?.priority?.priority == "normal" ? "Medium" : input?.priority?.priority.replace(/^\w/, (c) => c.toUpperCase())) : "undefined",
                "due_date": new Date(parseInt(input.due_date)).toISOString(),
                "created_by": input.creator.id,
                "creator": input.creator.username,
                "createdAt": new Date(parseInt(input.date_created)).toISOString(),
                "updatedAt": new Date(parseInt(input.date_updated)).toISOString(),

                "dependent_task": input.dependent_task || "undefined", // Assuming this field is not provided

                "project_name": input.project.name,
                "category": "clickup",
                "supported_docs": input.attachment,
                "visible_to": input.watchers.map(watcher => watcher.id),
                "live_time_tracking_flag": input?.live_time_tracking_flag,
                "live_time_tracking_start_time": input.live_time_tracking_start_time
            };
            return output

        })
    }
    function convertTicketObject(inputs) {
        return inputs.map((input) => {
            const output = {
                "_id": input.id,
                "assigned_to": input.assignees.map(assignee => assignee.id),
                "assignees": input.assignees.map(assignee => assignee.username),
                "ticket_subject": input.name,
                "ticket_description": input.description,
                "ticket_status": input?.status?.status,
                "priority": input?.priority?.priority ? (input?.priority?.priority == "normal" ? "Medium" : input?.priority?.priority.replace(/^\w/, (c) => c.toUpperCase())) : "undefined",
                "due_date": input.due_date ? new Date(parseInt(input.due_date)).toISOString() : null,
                "created_by": input.creator.id,
                "created_by": input.creator.username,
                "createdAt": new Date(parseInt(input.date_created)).toISOString(),
                "updatedAt": new Date(parseInt(input.date_updated)).toISOString(),
                "project_name": input.project.name,
                "ticket_category": "clickup",
                "category": input.category,
                "supported_doc": input.attachment,
            };
            return output

        })
    }



    const fetchTaskListData = async (id) => {

        if (id) {


            let payload = {

                "project_id": id
                // "projectId": projects?.[1]?._id
            }

            let clickupPayload = {
                "folderId": FolderSpaces ? FolderSpaces[projectData?.project_name] : null,
                "uniqueUrl": UniqueUrl
            }

            try {
                let response = await apiServiceHandler("POST", `api/task/get`, payload);
                const resTickets = await apiServiceHandler("POST", `api/tickets/get`, payload);


                let isActiveProjectInClickup = Object.keys(FolderSpaces).some(projectName =>
                    projectName === projectData.project_name
                );
                let resClickup = isActiveProjectInClickup ? await apiServiceHandler("POST", `api/task/get_task_list_clickup_project`, clickupPayload) : null;

                let data1 = response?.data;
                let ticketsRes = resTickets?.data?.tickets

                if (data1 && resClickup && ticketsRes) {


                    if (data1.assignedToMe && resClickup["Assigned To Me"]) {
                        const updatedShowToClient = data1.assignedToMe.concat(convertTaskObject(resClickup["Assigned To Me"]));
                        data1.assignedToMe = updatedShowToClient
                        // dispatch(updateTasksList(data1));

                    }
                    if (data1.allTaskofProject && resClickup["All Task Of Project"]) {
                        const updatedShowToClient = data1.allTaskofProject.concat(convertTaskObject(resClickup["All Task Of Project"]));
                        data1.allTaskofProject = updatedShowToClient
                        // dispatch(updateTasksList(data1));
                    }
                    if (data1.waitingForApproval && resClickup["Waiting For Approval"]) {
                        const updatedShowToClient = data1.waitingForApproval.concat(convertTaskObject(resClickup["Waiting For Approval"]));
                        data1.waitingForApproval = updatedShowToClient
                        // dispatch(updateTasksList(data1));
                    }
                    if (ticketsRes && resClickup["Tickets"]) {
                        const updatedShowToClient = ticketsRes.concat(convertTicketObject(resClickup["Tickets"]));
                        ticketsRes = updatedShowToClient

                        // dispatch(updateTasksList(data1));
                    }
                    dispatch(updateTasksList(data1));
                    setTaskUserList(data1.allTaskofProject);
                    dispatch(updateTicketsList(ticketsRes));


                } else {
                    dispatch(updateTasksList(data1));
                    dispatch(updateTicketsList(resTickets?.data?.tickets));
                    setTaskUserList(data1.allTaskofProject);
                    console.log("Either response.data or resClickup is null or undefined");
                }
                // dispatch(updateTasksList(data1));
                // setTaskUserList(data1.allTaskofProject);

            } catch (err) {
                // dispatch(updateloaderStatus(false))
                toastr.error(err?.response?.data?.message)
                console.log(err)
            }
        }

    }

    const fetchTaskbyId = async (id) => {

        try {
            const response = await apiServiceHandler("GET", `api/task/${id}`);
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }
    }

    const handleNewTaskButton = () => {

        if (projects.length > 0) {
            setOpenNewTaskModal(true)
        } else {
            toastr.error("No Project Found")
        }

    }

    useEffect(() => {
        if (openNewTaskModal) {
            setTasksListProps({
                ModalName: 'AddNewTask',
                isOpen: openNewTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openEditTaskModal) {
            setTasksListProps({
                ModalName: 'EditTask',
                isOpen: openEditTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } else if (openTimeTrackingModal) {
            setTasksListProps({
                ModalName: 'TimeTracking',
                isOpen: openTimeTrackingModal,
                onClose: handleClose
            })
        }
        else {
            setTasksListProps(null)
        }
    }, [openNewTaskModal, openEditTaskModal, openTimeTrackingModal, projects[0]])

    useEffect(() => {
        fetchTaskListData(projects[0]?._id)
        // fetchTaskbyId()
    }, [projects[0]?._id])

    useEffect(() => {
        if (FolderSpaces.length != 0) fetchTaskListData(projects[0]?._id)
        // fetchTaskbyId()
    }, [FolderSpaces])

    useEffect(() => {
        if (task.refreshTaskList) {
            fetchTaskListData(projects[0]?._id)
            dispatch(updateRefreshTaskList(false))
        }
    }, [task.refreshTaskList])


    const userAssigned = (existingUser) => {

        return existingUser.map((userId) => {
            const user = usersList.find((user) => user._id === userId);
            return user ? `${user.first_name} ${user.last_name} ` : 'Unknown';
        });

    }

    const createdBy = (userId) => {
        const user = usersList.find((user) => user._id === userId);
        return user ? `${user.first_name} ${user.last_name} ` : 'Unknown';
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (taskUserList) {
            const filterData = taskUserList.filter(value => (value?.task_name?.includes(searchValue.toLowerCase()) || value.task_name.toLowerCase().includes(searchValue.toLowerCase())
                || searchValue.trim() === "") && value?.task_status?.[0] !== 'appointment')

            setFilteredData(filterData)
        }
        // if (taskUserList) {
        //     // Filter taskUserList based on conditions
        //     const filterData = taskUserList?.filter(value => {
        //         // Check if value is defined and has necessary properties
        //         if (value?.category != "clickup") {
        //             // Ensure value.task_status is an array and has at least one element
        //             if (value) {
        //             // if (Array.isArray(value.task_status) && value.task_status.length > 0) {
        //                 // Check conditions for filtering
        //                 return (value.task_name.includes(searchValue.toLowerCase()) || value.task_name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue.trim() === "") && value.task_status[0] !== 'appointment';
        //             }
        //         }
        //         else{
        //             return (value.task_name.includes(searchValue.toLowerCase()) || value.task_name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue.trim() === "") 
        //         }
        //         return false; // Return false if any condition fails
        //     });

        //     setFilteredData(filterData);
        // } 

    }

    const handleClose = () => {
        setOpenNewTaskModal(false)
        setOpenEditTaskModal(false)
        setOpenTimeTrackingModal(false)
    }
    const handleEditTask = (value, e) => {
        e.stopPropagation();
        setOpenEditTaskModal(true)
        dispatch(updateEditTaskData(value))
    }
    const handleTimeTracking = (value) => {
        setOpenTimeTrackingModal(true)
        dispatch(updateEditTaskData(value))
    }

    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => { } })
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, taskUserList]);

    let currentUserId = userData?.user_id

    const handleClickStart = async (value) => {
        const isAssigned = value.assigned_to.includes(currentUserId);

        if (isAssigned) {
            // Update UI immediately
            setFilteredData(prevData => {
                return prevData.map(e => {
                    if (e._id === value._id) {
                        return {
                            ...e,
                            live_time_tracking_flag: true,
                            live_time_tracking_start_time: new Date()
                        };
                    }
                    return e;
                });
            });
            let payload = {
                "id": value._id,
                "live_time_tracking_flag": true,
                "live_time_tracking_start_time": new Date()
            };
            try {
                await updateTask(payload, false, value);
            } catch (error) {
                console.error("Error updating task:", error);
                // Revert UI state if update fails
                setFilteredData(prevData => {
                    return prevData.map(e => {
                        if (e._id === value._id) {
                            return {
                                ...e,
                                live_time_tracking_flag: false,
                            };
                        }
                        return e;
                    });
                });
            }
        } else {
            // Display message indicating user is not assigned to this task
            toastr.error("You are not assigned to this task.")
        }
    }
    const handleClickStop = async (value) => {
        const isAssigned = value.assigned_to.includes(currentUserId);
        if (isAssigned) {
            // Update UI immediately
            setFilteredData(prevData => {
                return prevData.map(e => {
                    if (e._id === value._id) {
                        return {
                            ...e,
                            live_time_tracking_flag: false,
                        };
                    }
                    return e;
                });
            });
            let payload = {
                "id": value._id,
                "live_time_tracking_flag": false,
            };
            try {
                await updateTask(payload, true, value);
            } catch (error) {
                console.error("Error updating task:", error);
                // Revert UI state if update fails
                setFilteredData(prevData => {
                    return prevData.map(e => {
                        if (e._id === value._id) {
                            return {
                                ...e,
                                live_time_tracking_flag: true,
                            };
                        }
                        return e;
                    });
                });
            }
        } else {
            // Display message indicating user is not assigned to this task
            toastr.error("You are not assigned to this task.")
        }
    }
    // Task Update API
    // const updateTask = async (payload, flag, value) => {
    //     try {
    //         await apiServiceHandler("POST", "api/task/update", payload)
    //         dispatch(updateRefreshTaskList(true))
    //         if (flag) addStartStopTime(value)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const updateTask = async (payload, flag, value) => {
        try {
            if (value?.category == "clickup") {
                payload.isClickUpTask = true
                payload.clickup_id = payload.id
                delete payload["id"]
            }
            await apiServiceHandler("POST", "api/task/update", payload)
            dispatch(updateRefreshTaskList(true))
            //   dispatch(updateEditTaskData(myTimeTrackingTaskData))
            if (flag && value?.category != "clickup") {
                addStartStopTime(value)
            } else if (flag && value?.category == "clickup") {
                addClickupStartStopTime(value)
            }
        } catch (err) {
            console.log(err)
        }
    }
    const addStartStopTime = async (value) => {
        let currentTime = new Date()
        let parsedTimeStart = formatDateToISOString(currentTime)
        let time = getTimeDifference(value.live_time_tracking_start_time, parsedTimeStart)
        const descTimeStamph = `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`;
        let payload = {
            "timeTracked": time,
            "taskId": value._id,
            "description": `Time Tracked ( ${descTimeStamph} )`,
            "date": getCurrentFormattedDate(),
            "to": parsedTimeStart,
            "from": value.live_time_tracking_start_time
        };
        try {
            await apiServiceHandler("POST", "api/tasks/update/time/tracking", payload)
        } catch (err) {
            console.log(err)
        }
    }

    const addClickupStartStopTime = async (value) => {
        let currentTime = new Date()
        let parsedTimeStart = formatDateToISOString(currentTime)
        let time = getTimeDifference(value.live_time_tracking_start_time, parsedTimeStart)
        const descTimeStamph = `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`;
        let payload = {
            "timeTracked": time,
            "id": value._id,
            "description": `Time Tracked (${descTimeStamph})`,
            "date": getCurrentFormattedDate()
        };
        try {
            await apiServiceHandler("POST", "api/tasks/track_time", payload)
            //   dispatch(updateEditTaskData(myTimeTrackingTaskData))
            //   getClickupAllTimeTracking()
        } catch (err) {
            console.log(err)
        }
    }

    function truncateNames(names, limit) {
        const maxLength = 40; // Maximum length for each name
        let truncatedNames = [];

        // If names are provided and the limit is specified
        if (names && limit) {
            for (let i = 0; i < names.length && i < limit; i++) {
                if (names[i].length > maxLength) {
                    truncatedNames.push(names[i].slice(0, maxLength - 3) + '...'); // Truncate name if it exceeds maximum length
                } else {
                    truncatedNames.push(names[i]);
                }
            }
        }

        return truncatedNames;
    }

    return (
        <div className='current-working-task-two-section'>
            <section className="current-working-task-two mb-4">
                <div className={flag ? "bg-white shadow working-inner-two-viewmore" : "bg-white shadow working-inner-two"}>
                    <div className="working-task-two">
                        {!flag && <h2> All Tasks</h2>}
                        <div className={flag ? "search-group-two d-flex align-items-center" : "search-group-two d-flex align-items-center view-line"}>
                            <form onSubmit={handleSearch}>
                                <input type="text" onChange={(e) => setSearchValue(e.target.value)} name="searchusers" placeholder="Find Task..." className="form-control" />
                                <button type="submit"><img src={SearchIcon} /></button>
                            </form>
                            <div className="d-flex align-items-center task-btn">
                                <button type="button" data-bs-target="#newFilter" className="filter-icon-two"><img src={Filter} /></button>
                                {!upcomingPopup.taskPopup && <>  <button type="button" data-bs-target="newtaskModal" className="add-icon-two"
                                    onClick={() => handleNewTaskButton()}
                                ><img src={PlusIcon} /></button>
                                    {window.screen.width > 760 && <button type="button" onClick={() => {
                                        dispatch(updatetaskPopup(true));
                                        dispatch(updateupcomingHeadline(" All Tasks"))
                                    }} className="task-expand"><img src={ExpandIcon} /></button>}
                                    <button type="button" className="drag-me"><img src={DragIcon} /></button></>}
                            </div>
                        </div>
                        <div className="current-tasks-col-two">
                            <div className="current-tasks-label-two d-none d-lg-block mb-3">
                                <div className="row g-0">
                                    <div className="col-lg-2">
                                        <h3>Task Name</h3>
                                    </div>
                                    <div className="col-lg-2 text-lg-center">
                                        <h3>Project</h3>
                                    </div>
                                    <div className="col-lg-2 text-lg-center">
                                        <h3>Created By</h3>
                                    </div>
                                    <div className="col-lg-2 text-lg-center">
                                        <h3>Assignee</h3>
                                    </div>
                                    <div className="col-lg-2 text-lg-center">
                                        <h3>Time Tracking</h3>
                                    </div>
                                    <div className="col-lg-1 text-lg-center">
                                        <h3>Due Date</h3>
                                    </div>
                                    <div className="col-lg-1 text-lg-center">
                                        <h3>Action</h3>
                                    </div>
                                </div>
                            </div>
                            <div className={flag ? "current-tasks-data-two-viewmore" : "current-tasks-data-two"}>
                                {filteredData && filteredData?.map((value) => (
                                    <div style={{ cursor: "pointer" }} className="row tasks-row g-0">
                                        <div className="col-lg-2">
                                            {/* <label className="mb-0">Task Name</label> */}
                                            <p className="mb-0">{value?.task_name}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Project</label> */}
                                            <p className="mb-0">{value?.project_name}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Project</label> */}
                                            <p className="mb-0">{value?.category != "clickup" ? createdBy(value?.created_by) : value?.creator}</p>
                                        </div>
                                        <div className="col-lg-2 text-lg-center">
                                            {/* {
                                                (value?.category !== "clickup" ?
                                                    truncateNames(userAssigned(value?.assigned_to), 2) :
                                                    truncateNames(value?.assignees.join(' '), 2))
                                            } */}
                                            {/* <label className="mb-0">Assignee</label> */}
                                            <p className="mb-0">{value?.category != "clickup" ? userAssigned(value?.assigned_to) : value?.assignees.join(' ')}</p>
                                            {/* {value?.category != "clickup" ? 'userAssigned(value?.assigned_to).length > 40 ? `${userAssigned(value?.assigned_to).substr(0, 40)}...` : value?.task_name' : }</p> */}

                                        </div>
                                        {/* <div className="assignee-label-two">
                                                <span>AN{userNames}</span> 
                                                 <span>DA</span>
                                                    <span>JA</span>
                                            </div> */}
                                        <div className="col-lg-2 text-lg-center">
                                            {/* <label className="mb-0">Time Tracking</label> */}
                                            <div className="mb-0 task-time-track">
                                                <button type="button" className="no-default m-1">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={StartIcon} width={18} onClick={() => handleClickStart(value)} style={{ display: value?.live_time_tracking_flag ? "none" : "block" }} />
                                                        <img src={StopRed} width={18} onClick={() => handleClickStop(value)}
                                                            style={{ display: value?.live_time_tracking_flag ? "block" : "none", color: "red" }} />
                                                        <span onClick={() => handleTimeTracking(value)}>&nbsp;{convertArrayToMinutes(value?.time_tracking)}</span>
                                                    </div>
                                                </button>
                                                {/* {value?.time_tracking_log} */}
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-1 text-lg-center">
                                                <label className="mb-0">Show to Client</label>
                                                <button type="button" className="user-action" onClick={() => fetchTaskbyId(value?._id)}><img src={value?.show_to_client ? EyeIcon : CloseeyeIcon} /></button>
                                            </div> */}
                                        <div className="col-lg-1 text-lg-center">
                                            {/* <label className="mb-0">Due Date</label> */}
                                            <p className="mb-0">{getFormattedDate(value?.due_date)}</p>
                                        </div>
                                        <div className="col-lg-1 text-lg-center">
                                            {/* <label className="mb-0">Action</label> */}
                                            <button type="button" className="edit-task-action-two" data-bs-target="editTaskModal" onClick={(e) => handleEditTask(value, e)}>{window.screen.width > 760 ? <img src={EditIcon} /> : <img src={EditwhiteIcon} />}</button>
                                        </div>
                                    </div>))}

                            </div>
                        </div>
                    </div>
                </div>
                {window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                    dispatch(updatetaskPopup(true));
                    dispatch(updateupcomingHeadline("All Tasks"))
                }} class="view-more">View More</button>}
            </section>
        </div>
    )
}

export default TasksList;
