import React, { useEffect, useRef, useState } from 'react';
import ChatsdotsIcon from '../../assests/images/chat-dots.svg';
import BackIcon from '../../assests/images/back.svg';
import SendIcon from '../../assests/images/send.svg';
import CloseIcon from '../../assests/images/close.svg';
import { rtdb } from '../firebase/firebase';
import { onValue, child, get, ref, set, getDatabase } from "firebase/database"
import apiServiceHandler from '../../service/apiService';


const ViewChats = ({ isOpen, onClose, chatValue, chatUsersUniqueurl }) => {
  const [userChats, setUserChats] = useState([])
  const [liveChatMsg, setLiveChatMsg] = useState("")
  const messagesRef = useRef(null);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [userChats]);
 

  const formatDateTime = (timestampInMilliseconds) => {
    const date = new Date(timestampInMilliseconds);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return formatter.format(date);
  }

  const sendMessage = async () => {
    let payload = {
      "receiverUniqueUrl": chatValue,
      "message": liveChatMsg
    }

    try {
      const response = await apiServiceHandler("POST", "api/chats/send_msg", payload)
      console.log(response)


    } catch (err) {
      // toastr.error(err?.response?.data?.message)
      console.log(err)
    }
    setLiveChatMsg("")

  }


  const getMessage = async () => {
    let receiverUniqueUrl = chatValue


    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    let senderUniqueUrl = userData?.unique_url

    let databasePath = senderUniqueUrl < receiverUniqueUrl ? senderUniqueUrl + "_" + receiverUniqueUrl : receiverUniqueUrl + "_" + senderUniqueUrl

    const starCountRef = ref(rtdb, databasePath);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let chats = [];

      if (data) {
        Object.keys(data).forEach((x) => {
          chats.push(data[x]);
        });
        chats.sort((a, b) => a.createdAt - b.createdAt);
        setUserChats(chats)
      } else {
        // Handle the case when data is null or undefined
        console.log([]); // Invoke the callback with an empty array
      }
    });
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  useEffect(() => {
    getMessage()
  }, [])

  return (
    <div>
      <div className="team-chat-box" style={{ display: isOpen ? "block" : "none" }}>
        <div className="chat-head">
          <button type="button" onClick={onClose} className="back-btn"><img src={BackIcon} /></button>
          <h2>{chatUsersUniqueurl[chatValue]}</h2>
        </div>
        <div ref={messagesRef} className="chat-conversation mb-3">
          {userChats.map((value) => {
            <div className="msg-date"><span>Thursday, Oct 2, 2023</span></div>

            if (value.senderUniqueUrl !== chatValue) return (<div className="msg sent">
              <div className="msg-txt">{value.message}</div>
              <div className="msg-time"> {formatDateTime(value.createdAt)}</div>
            </div>)
            else
              return (<div className="msg received">
                <div className="msg-txt">{value.message}</div>
                <div className="msg-time">{formatDateTime(value.createdAt)}</div>
              </div>)

          })}

        </div>
        <div className="team-write-chat">
          <div className="team-chat-inner">
            <input type="text" className="form-control" placeholder="Type Message..." name="liveChatMsg" value={liveChatMsg} onChange={(e) => setLiveChatMsg(e.target.value)} onKeyPress={handleEnterKeyPress} />
          </div>
          <button type="button" className="team-send-chat" onClick={sendMessage}><img src={SendIcon} /></button>
        </div>
      </div>
    </div>
  );
};

export default ViewChats;

