import React, { useRef, useState } from 'react'
import './main.css';
import TopBannerIcon from '../../assests/icons/top-banner.png';
import ViewsIcon from '../../assests/images/views.png';
import TaskstrackIcon from '../../assests/images/task-tracking.png';
import EasyNavigation from '../../assests/images/easy-navigation.png';
import viewtasks from '../../assests/images/view-tasks.png';
import FilesandFolders from '../../assests/images/AllTask.png';
import Invoicing from '../../assests/images/InvoiceProg.png';
import twoIcon from '../../assests/images/24icon.png';
import ProjectPricing from '../../assests/images/price2.png';
// import ProjectPricing from '../../assests/images/project-pricing.png';
import CCicon from '../../assests/images/cc-icon.png';
import PaysIcon from '../../assests/images/pay-icon.png';
import StorageIcon from '../../assests/images/storage-icon.png';
import PlanIcon from '../../assests/images/ccc-icon.png';
import UsersIcon from '../../assests/images/users-icon.png';
import ProjectIcon from '../../assests/images/project-icon.png';
import PtableIcon from '../../assests/images/ptable-icon.png';
import ChatSystem from '../../assests/images/chatsystem.png';
import InstantProjectStatus from '../../assests/images/ProjecvtProg.png';
import PayforNeed from '../../assests/images/subplan.png';
import TimeTrack from '../../assests/images/timet.png';
import CustomRoles from '../../assests/images/ManageRoles.png';
import SingleAppIcon from '../../assests/images/single-app.png';
import LiveChatIcon from '../../assests/images/live-chat.png';
import ManageProjectsIcon from '../../assests/images/manage-projects.png';
import CollaborateIcon from '../../assests/images/collaborate.png';
import RealtimeIcon from '../../assests/images/real-time.png';
import rightArrow from '../../assests/images/right-arrow.png';
import woodenIcon from '../../assests/images/wooden-hands.png';
import TeamImage from '../../assests/images/team.png';
import InstantStatus from '../../assests/images/instantstatus.png';
import ChatPnG from '../../assests/images/chatpn.png';
import PayIcon from '../../assests/images/Pay.svg';
import PlayIcon from '../../assests/images/play.png';
import PauseIcon from '../../assests/images/pausebtn.png';
import ProjectVideo from '../../assests/images/project.mp4';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';



const MainIndexPage = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const navigate = useNavigate()
    const videoRef = useRef(null);
    const [showControls, setShowControls] = useState(false);
    const [mouseOverButton, setMouseOverButton] = useState(false);

    const initialUserOptions = [10, 20, 30, 40, 50, 100, 250, 500, 1000];
    const initialProjectOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 50];
    const initialStorageOptions = [5, 10, 15, 20, 25, 50, 100];
    const [userQuantity, setUserQuantity] = useState(initialUserOptions[0]);
    const [projectQuantity, setProjectQuantity] = useState(initialProjectOptions[0]);
    const [storageQuantity, setStorageQuantity] = useState(initialStorageOptions[0]);
    const [hasChangedPlan, setHasChangedPlan] = useState(false);

    const totalPrice = userQuantity * process.env.REACT_APP_USER_PRICE + projectQuantity * process.env.REACT_APP_PROJECT_PRICE + storageQuantity * process.env.REACT_APP_STORAGE_PRICE

    const handleUserQuantityChange = (event) => {
        setUserQuantity(event.target.value);
        // setHasChangedPlan(true);
    };

    const handleProjectQuantityChange = (event) => {
        setProjectQuantity(event.target.value);
        // setHasChangedPlan(true);
    };

    const handleStorageQuantityChange = (event) => {
        setStorageQuantity(event.target.value);
        // setHasChangedPlan(true);
    };

    const isOnInitialPlan = userQuantity === initialUserOptions[0] &&
        projectQuantity === initialProjectOptions[0] &&
        storageQuantity === initialStorageOptions[0];

    const togglePlayback = () => {
        const video = videoRef.current;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleMouseMovement = () => {
        setShowControls(true);
        if (!isPlaying && !mouseOverButton) {
            setTimeout(() => {
                setShowControls(false);
            }, 1000);
        }
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0',
                    adaptiveHeight: true,
                }
            }
        ]
    };
    return (
        <div>

            <section className="main-video" onMouseMove={handleMouseMovement}>
                <video ref={videoRef}
                    onMouseEnter={() => setShowControls(true)}
                    onMouseLeave={() => setShowControls(false)}
                    onPlaying={() => setShowControls(false)}
                    onPause={() => setShowControls(true)}
                    className="video-item" width="100%" height="240"
                    playsInline loop={true} muted={false} controls={false} autoPlay={isPlaying}>
                    <source src={ProjectVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {(showControls || mouseOverButton) && (
                    <button className="play-btn" onClick={togglePlayback}
                        onMouseEnter={() => setMouseOverButton(true)}
                        onMouseLeave={() => setMouseOverButton(false)}
                    >
                        <img src={isPlaying ? PauseIcon : PlayIcon} alt={isPlaying ? "Pause" : "Play"} />
                    </button>
                )}
            </section>

            <section className="hero-banner bg-white pd-5">
                <div className="container">
                    <div className="banner-caption">
                        <h3>Real-Time Progress Tracking on one page!</h3>
                        <h2>Join Live Look: Where Everything's in One Place, Just for You!</h2>
                        <p>Never leave clients guessing again – keep them in the loop at all times. Deliver unmatched transparency and accountability to every client with just a single dashboard.</p>
                        <a href="/contact-us">Discover the Difference</a>
                    </div>
                </div>
            </section>

            <section className="project-platform text-center py-5">
                <div className="container">
                    <h2>The Most Versatile Project Management Platform</h2>
                    <p>Maximize your team’s performance, accelerate growth, and improve customer experience with unique and extensive customization features that makes work seamless for any team or department.</p>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md mb-4 mb-md-0">
                            <div className="project-imgg">
                                <img src={SingleAppIcon} />
                            </div>
                            <p className="mb-0">A single app for all<br />departments</p>

                        </div>
                        <div className="col-md mb-4 mb-md-0">
                            <div className="project-imgg">
                                <img src={LiveChatIcon} />
                            </div>
                            <p className="mb-0">Live chat with<br />collaborators</p>
                        </div>
                        <div className="col-md mb-4 mb-md-0">
                            <div className="project-imgg" >
                                <img src={ManageProjectsIcon} />
                            </div>
                            <p className="mb-0">Manage projects,<br />organize work</p>
                        </div>
                        <div className="col-md mb-4 mb-md-0">
                            <div className="project-imgg">
                                <img src={CollaborateIcon} />
                            </div>
                            <p className="mb-0">Collaborate and<br />drive efficiency</p>
                        </div>
                        <div className="col-md">
                            <div className="project-imgg">
                                <img src={RealtimeIcon} />
                            </div>
                            <p className="mb-0">24/7 real-time<br />support</p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section-tabs bg-white py-5 mt-5">
                <div className="container">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-views-tab" data-bs-toggle="pill" data-bs-target="#pills-views" type="button" role="tab" aria-controls="pills-views" aria-selected="true"><img src={ViewsIcon} />Invoice</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-task-tracking-tab" data-bs-toggle="pill" data-bs-target="#pills-task-tracking" type="button" role="tab" aria-controls="pills-task-tracking" aria-selected="false"><img src={TaskstrackIcon} />Documents</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-easy-navigation-tab" data-bs-toggle="pill" data-bs-target="#pills-easy-navigation" type="button" role="tab" aria-controls="pills-easy-navigation" aria-selected="false"><img src={EasyNavigation} />Time Tracker</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-view-tasks-tab" data-bs-toggle="pill" data-bs-target="#pills-view-tasks" type="button" role="tab" aria-controls="pills-view-tasks" aria-selected="false"><img src={viewtasks} />Custom Roles</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-request-tasks-tab" data-bs-toggle="pill" data-bs-target="#pills-request-tasks" type="button" role="tab" aria-controls="pills-request-tasks" aria-selected="false"><img src={PayIcon} />Payment System</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-chats-tasks-tab" data-bs-toggle="pill" data-bs-target="#pills-chats-tasks" type="button" role="tab" aria-controls="pills-chats-tasks" aria-selected="false"><img src={ChatPnG} />Chats Section</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-project-tasks-tab" data-bs-toggle="pill" data-bs-target="#pills-project-tasks" type="button" role="tab" aria-controls="pills-project-tasks" aria-selected="false"><img src={InstantStatus} />Project Status</button>
                        </li>

                    </ul>
                    <div className="tab-content mt-5" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-views" role="tabpanel" aria-labelledby="pills-views-tab" tabindex="0">
                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Invoicing</h3>
                                    <h2>Simplify Your Billing with Effortless Invoicing</h2>
                                    <p>No more delays in your billing process! With Join Live Look, generating invoices, getting them approved, and sharing them with the right people is as easy as a few clicks. From creating invoices to securing approvals and sharing them efficiently, our feature ensures your transactions run smoothly.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={Invoicing} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-task-tracking" role="tabpanel" aria-labelledby="pills-task-tracking-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Files and Folders</h3>
                                    <h2>Centralize Project Documents with the All-In-One File Dashboard</h2>
                                    <p>Keep your project files in check with Join Live Look! Our feature consolidates all project-related documents into one dashboard, enabling easy upload, management, and sharing. Elevate your project collaboration effortlessly.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={FilesandFolders} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-easy-navigation" role="tabpanel" aria-labelledby="pills-easy-navigation-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Time Tracker</h3>
                                    <h2>Enhance Client Collaboration with Seamless Time Tracking</h2>
                                    <p>Maximize project efficiency with Join Live Look's intuitive time tracking feature. This allows clients to monitor time logs and milestone progress seamlessly, fostering transparency and trust between teams and clients.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={TimeTrack} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-view-tasks" role="tabpanel" aria-labelledby="pills-view-tasks-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Custom Roles</h3>
                                    <h2>Tailor Who Sees What, Just the Way You Want!</h2>
                                    <p>Join Live Look empowers you to take your project to new heights with custom access controls. Whether it's granting specific team members access to crucial details or providing clients with a comprehensive view, Join Live Look makes it possible.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={CustomRoles} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-request-tasks" role="tabpanel" aria-labelledby="pills-request-tasks-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Only Pay for what you need</h3>
                                    <h2>Value for Money: Pay Only for the Features You Utilize!</h2>
                                    <p>With Join Live Look, managing your project's budget has never been easier. Enjoy the flexibility of paying only for the features your project requires, no more, no less. It's about maximizing your budget and ensuring you get precisely what you need without any unnecessary expenses.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={PayforNeed} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-chats-tasks" role="tabpanel" aria-labelledby="pills-chats-tasks-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>BuiltIn Communication</h3>
                                    <h2>Effortlessly Manage Projects and Stay Connected, All in One Place!</h2>
                                    <p>Take your project management to the next level with Join Live Look's integrated communication tools. By incorporating chats and support tickets into its dashboard, Join Live Look ensures that you can view your project progress while staying connected with your team and clients. It's about efficiency, convenience, and seamless collaboration, all within one platform.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={ChatSystem} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-project-tasks" role="tabpanel" aria-labelledby="pills-project-tasks-tab" tabindex="0">

                            <div className="row align-items-center">
                                <div className="col-md-6 mb-4 mb-md-0">
                                    <h3>Instantly know the status of your Project</h3>
                                    <h2>Keep Clients Informed and Projects on Track with Real-Time Access!</h2>
                                    <p>With Join Live Look, delays become a thing of the past. Your clients can stay engaged and connected by checking the status of their projects in real-time. It's about providing them with the visibility they need to stay informed, make decisions, and keep projects on track without any unnecessary delays.</p>
                                    <a href="/signup" className="filled-btn blue-filled">Try Join Livelook for Free</a>
                                </div>
                                <div className="col-md-6">
                                    <img src={InstantProjectStatus} />
                                    <div className="see-all">
                                        <a href="/signup">See all features<img src={rightArrow} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="trust-section bg-white">
                <div className="container">
                    <h2 className="text-center">Trusted by over 10,000 companies</h2>
                </div>
                <div style={{ margin: "0 auto", maxWidth: "100%" }}>
                    <Slider {...settings} className="trust-loop">
                        <div className="item box-slider">
                            <div className="trust-text bg-white">
                                <p>"Live look app platform is incredibly simple to use."</p>
                                <div className="trust-info d-flex align-items-center">
                                    <div className="trust-img me-3">
                                        <img src={ReviewIcon} />
                                    </div>
                                    <div className="trust-wala">
                                        <h4 className="mb-1">Christian Brown</h4>
                                        <h5 className="mb-0">Entrepreneur, LA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item box-slider">
                            <div className="trust-text bg-white">
                                <p>"Live look app platform is incredibly simple to use."</p>
                                <div className="trust-info d-flex align-items-center">
                                    <div className="trust-img me-3">
                                        <img src={ReviewIcon} />
                                    </div>
                                    <div className="trust-wala">
                                        <h4 className="mb-1">Christian Brown</h4>
                                        <h5 className="mb-0">Entrepreneur, LA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="trust-text bg-white">
                                <p>"Live look app platform is incredibly simple to use."</p>
                                <div className="trust-info d-flex align-items-center">
                                    <div className="trust-img me-3">
                                        <img src={ReviewIcon} />
                                    </div>
                                    <div className="trust-wala">
                                        <h4 className="mb-1">Christian Brown</h4>
                                        <h5 className="mb-0">Entrepreneur, LA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="trust-text bg-white">
                                <p>"Live look app platform is incredibly simple to use."</p>
                                <div className="trust-info d-flex align-items-center">
                                    <div className="trust-img me-3">
                                        <img src={ReviewIcon} />
                                    </div>
                                    <div className="trust-wala">
                                        <h4 className="mb-1">Christian Brown</h4>
                                        <h5 className="mb-0">Entrepreneur, LA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="trust-text bg-white">
                                <p>"Live look app platform is incredibly simple to use."</p>
                                <div className="trust-info d-flex align-items-center">
                                    <div className="trust-img me-3">
                                        <img src={ReviewIcon} />
                                    </div>
                                    <div className="trust-wala">
                                        <h4 className="mb-1">Christian Brown</h4>
                                        <h5 className="mb-0">Entrepreneur, LA</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </section>

            <section className="our-numbers text-center pt-5">
                <div className="container">
                    <h2>Our numbers</h2>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-3 mb-4 mb-md-0">
                            <div className="total-num">50+</div>
                            <p className="mb-0">Projects</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <div className="total-num">25+</div>
                            <p className="mb-0">Clients</p>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0">
                            <div className="total-num">100+</div>
                            <p className="mb-0">Features</p>
                        </div>
                        <div className="col-md-3">
                            <div className="total-num">45+</div>
                            <p className="mb-0">Team</p>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="subscription-section">
                <div className="container">
                    <div className="subscription-head">
                        <h2>Subscription Plans</h2>
                        {/* <p>Pay for your needs.</p> */}
                    </div>
                    <div className='row align-items-center'>
                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <div class="exploring-list">
                                <div class="exploring-block text-center text-lg-end">
                                    <img src={PlanIcon} />
                                    <h4>Fully customizable plans</h4>
                                    <p>You can customize the plan according <br /> to your needs</p>
                                </div>
                                <div class="exploring-block text-center text-lg-end">
                                    <img src={UsersIcon} />
                                    <h4>Manage users as needed</h4>
                                    <p>Add or customize users <br />as required</p>
                                </div>
                                <div class="exploring-block text-center text-lg-end">
                                    <img src={PaysIcon} />
                                    <h4>Pay for what you need</h4>
                                    <p>Pay according to your personal requirements!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4 mb-lg-0">
                            <div className="price-box">
                                <img src={PtableIcon} width="100" />
                                {/* <h3>Customized<br />Plan</h3> */}
                                <h3>Pay for what you need</h3>
                                <h4>$ {totalPrice}</h4>
                                {/* <sup>$</sup> */}
                                <div className="customize-opt">
                                    <ul className="list-unstyled m-0 p-0">
                                        <li>
                                            <div className="customize-label">Users</div>
                                            <div className="customize-num">
                                                <select name="users" value={userQuantity} onChange={handleUserQuantityChange}>
                                                    {/* <option value="1">1</option> */}
                                                    {initialUserOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="customize-label">Projects</div>
                                            <div className="customize-num">
                                                <select name="projects" value={projectQuantity} onChange={handleProjectQuantityChange}>
                                                    {/* <option value="1">1</option> */}
                                                    {initialProjectOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="customize-label">Storage</div>
                                            <div className="customize-num">
                                                <select name="storage" value={storageQuantity} onChange={handleStorageQuantityChange}>
                                                    {/* <option value="1 GB">10 GB</option> */}
                                                    {initialStorageOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="text-center mt-2">
                                    {totalPrice == 25 ? <button type="button" className="sff-btn" onClick={() => navigate("/signup")}>
                                        Try for Free*
                                    </button> : <button type="button" className="sff-btn" onClick={() => navigate("/signup")}>
                                        Start for ${totalPrice}
                                    </button>}
                                </div>

                                {/* <button className="sff-btn" onClick={() => navigate("/signup")}>Start for free</button> */}
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="exploring-list">
                                <div class="exploring-block text-center text-lg-start">
                                    <img src={ProjectIcon} />
                                    <h4>Adjust your projects</h4>
                                    <p>Projects that can be customized<br />to your preferences</p>
                                </div>
                                <div class="exploring-block text-center text-lg-start">
                                    <img src={StorageIcon} />
                                    <h4>Personalized Storage</h4>
                                    <p>Adaptable storage space that adjusts<br />to your needs</p>
                                </div>
                                <div class="exploring-block text-center text-lg-start">
                                    <img src={twoIcon} />
                                    <h4>24/7 real-time support</h4>
                                    <p>Get help whenever you need it with our real-time support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="support-section pt-5 bg-white">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-md-6 mb-4 mb-md-0">
                            <h3>What sets us apart</h3>
                            <p>At Join Live Look, we stand out from the competition by offering a comprehensive solution tailored specifically for you. With our single dashboard, you'll never have to juggle multiple tools again. Everything you need is right here, at your fingertips. From project management to live chat with collaborators, we've got you covered, making teamwork smoother and more efficient than ever before.</p>
                            <p className="mb-0">But what truly sets us apart is our unwavering commitment to transparency and accountability. With real-time updates and 24/7 support, you can trust that we'll always keep you in the loop and address any concerns promptly. Join us today and experience the difference – simplify your workflow, boost your productivity, and elevate your collaboration with Join Live Look.</p>
                        </div>

                        <div className="col-md-6">
                            <img src={woodenIcon} />
                        </div>

                    </div>
                </div>
            </section>

            <section className="book-a-demo py-5 bg-white">
                <div className="container">
                    <div className="book-inner shadow-sm">
                        <div className="row align-items-center">

                            <div className="col-md text-center order-md-1">
                                <h2>Get started with Join Live Look</h2>
                                <p>Access our guide and discover everything your company needs to become compliant.</p>
                                <a href="/contact-us" className="filled-btn blue-filled">Book a Demo</a>
                            </div>
                            <div className="col-md mt-4 mt-md-0">
                                <img src={TeamImage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainIndexPage
