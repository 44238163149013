import React, { useEffect, useState } from 'react'
import "./userManagement.css"
import ManageUser from './manageUsers';
import ManageUsersCopy from './manageUserscopy';
import Plans from './plans';
import SubscriptionBilling from './subscription-billing';
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshUserDatap } from '../../redux/userManagement';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import { updateupcomingHeadline, updateuserMgtPopup } from '../../redux/viewmore';


const UserManagement = ({ setUserManagementProps, flag }) => {

    const [activeTab, setActiveTab] = useState('manage-users');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddUser, setIsOpenAddUser] = useState(false);
    const [isRoleOpen, setIsRoleOpen] = useState(false);
    const [isUserRoleOpen, setIsUserRoleOpen] = useState(false);
    const [isOpenEditUser, setIsOpenEditUser] = useState(false);
    const [isOpenDeleteUserRole, setIsOpenDeleteUserRole] = useState(false);
    const [edituserData, setEdituserData] = useState({})
    const [refreshUserManagementData, setRefreshUserManagementData] = useState(false)
    const [refreshRoleList, setRefreshRoleList] = useState(false)
    const deleteOpenFlag = useSelector(state => state.allUserData.openDeletePopup)
    const activePopup = useSelector(state => state.viewmore.userMgtPopup)
    const usermgtActiveTab = useSelector(state => state.viewmore.usermgtActiveTab)

    const paymentDetails = useSelector(state => state.allUserData.paymentDetails.details)
    const dispatch = useDispatch()
    const handleClose = () => {
        setIsOpen(false);
        setIsOpenAddUser(false);
        setIsRoleOpen(false);
        setIsOpenEditUser(false);
        setIsOpenDeleteUserRole(false);
        setIsUserRoleOpen(false);
    };

    useEffect(() => {
        if (isOpenAddUser) {
            setUserManagementProps({
                ModalName: 'AddNewUser',
                setRefreshUserManagementData,
                isOpen: isOpenAddUser,
                onClose: handleClose,
                paymentDetails: paymentDetails
            })
        } else if (isOpenEditUser) {
            setUserManagementProps({
                ModalName: 'EditProfile',
                edituserData,
                isOpen: isOpenEditUser,
                onClose: handleClose
            })
        } else if (deleteOpenFlag) {
            setUserManagementProps({
                ModalName: 'DeleteUser',
                isOpen: deleteOpenFlag
            })
        } else if (isRoleOpen) {
            setUserManagementProps({
                ModalName: 'RoleModal',
                isOpen: isRoleOpen,
                onClose: handleClose,
                setRefreshRoleList
            })
        } else if (isOpenDeleteUserRole) {
            setUserManagementProps({
                ModalName: 'DeleteRole',
                isOpen: isOpenDeleteUserRole,
                onClose: handleClose,
                setRefreshRoleList
            })
        } else if (isUserRoleOpen) {
            setUserManagementProps({
                ModalName: 'AddNewUserRole',
                isOpen: isUserRoleOpen,
                onClose: handleClose,
                setRefreshRoleList
            })
        } else {
            setUserManagementProps(null)
        }

    }, [isOpenAddUser, isOpenEditUser, deleteOpenFlag, isRoleOpen, isOpenDeleteUserRole, isUserRoleOpen])


    useEffect(() => {
        if (activePopup == false) {
            setActiveTab('manage-users');
        }
    }, [activePopup]);

    return (
        <div>
            <section className="user-managemanet-section mb-4">
                <div
                // className="container-fluid"
                >
                    <div className={flag ? "bg-white shadow user-managemanet-inner-viewmore" : "bg-white shadow user-managemanet-inner"}>
                        <div className="user-tab d-flex align-items-center justify-content-between">
                            <ul className="nav" id="user-managemanet" role="tablist">
                                <li className={`nav-item ${activeTab === 'manage-users' ? 'active' : ''}`} role="presentation">
                                    <button className={`nav-link ${activeTab === 'manage-users' ? 'active' : ''}`} id="manage-users-tab" data-bs-toggle="pill" data-bs-target="#manage-users" type="button" role="tab" aria-controls="manage-users" aria-selected="true" onClick={() =>
                                        setActiveTab('manage-users')
                                    }>Manage Users</button>
                                </li>
                                <li className={`nav-item ${activeTab === 'manage-roles' ? 'active' : ''}`} role="presentation">
                                    <button className="nav-link" id="manage-roles-tab" data-bs-toggle="pill" data-bs-target="#manage-roles" type="button" role="tab" aria-controls="manage-roles" aria-selected="false" onClick={() => setActiveTab('manage-roles')}>Manage Roles</button>
                                </li>
                                {/* 
                                <li className={`nav-item ${activeTab === 'plans' ? 'active' : ''}`} role="presentation">
                                    <button className="nav-link" id="plans-tab" data-bs-toggle="pill" data-bs-target="#plans" type="button" role="tab" aria-controls="plans" aria-selected="false" onClick={() => setActiveTab('plans')}>Plans</button>
                                </li>
                                */}
                                <li className={`nav-item ${activeTab === 'subscription-billing' ? 'active' : ''}`} role="presentation">
                                    <button className="nav-link" id="subscription-tab" data-bs-toggle="pill" data-bs-target="#subscription-billing" type="button" role="tab" aria-controls="subscription-billing" aria-selected="false" onClick={() => setActiveTab('subscription-billing')}>Subscription & Billing</button>
                                </li>
                            </ul>
                            <div class="d-flex align-items-center chat-btn">
                                {!flag && <> {window.screen.width > 760 && <button onClick={() => {
                                    dispatch(updateuserMgtPopup(true));
                                    dispatch(updateupcomingHeadline("User Management"))
                                }} type="button" class="add-icon"><img src={ExpandIcon} /></button>}
                                    <button type="button" class="no-default drag-me"><img src={DragIcon} /></button></>}
                            </div>
                        </div>
                        <div className="tab-content" id="user-managemanetContent">
                            <div className={`tab-pane fade ${activeTab === 'manage-users' ? 'show active' : ''}`} id="manage-users" role="tabpanel" aria-labelledby="manage-users-tab" tabindex="0">
                                <ManageUser setRefreshUserManagementData={setRefreshUserManagementData} refreshUserManagementData={refreshUserManagementData} setEdituserData={setEdituserData} flag={flag} setIsOpenAddUser={(val) => {
                                    setIsOpenAddUser(val)
                                }} setIsOpenEditUser={(val) => setIsOpenEditUser(val)} />
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'manage-roles' ? 'show active' : ''}`} id="manage-roles" role="tabpanel" aria-labelledby="manage-roles-tab" tabindex="0">
                                <ManageUsersCopy refreshRoleList={refreshRoleList} setRefreshRoleList={setRefreshRoleList} setIsOpenAddUser={setIsOpenAddUser} setIsRoleOpen={setIsRoleOpen} flag={flag} setIsOpenDeleteUserRole={setIsOpenDeleteUserRole} setIsUserRoleOpen={setIsUserRoleOpen} />
                            </div>
                            {/* <div className={`tab-pane fade ${activeTab === 'plans' ? 'show active' : ''}`} id="plans" role="tabpanel" aria-labelledby="manage-roles-tab" tabindex="0">
                                <Plans />
                            </div> */}
                            <div className={`tab-pane fade ${activeTab === 'subscription-billing' ? 'show active' : ''}`} id="subscription-billing" role="tabpanel" aria-labelledby="manage-roles-tab" tabindex="0">
                                <SubscriptionBilling flag={flag} />
                            </div>
                        </div>
                    </div>
                    {window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                        dispatch(updateuserMgtPopup(true));
                        dispatch(updateupcomingHeadline("User Management"))
                    }} class="view-more">View More</button>}
                </div>
            </section>
        </div>
    )
}
export default UserManagement;
