import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import SearchIcon from '../../assests/images/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import { updateRefreshTaskList } from '../../redux/task';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const RescheduleAppointment = ({ isOpen, onClose, isRescheduleOpen }) => {

    // Google Calendar
    const gapi = window.gapi;
    const google = window.google;

    const CLIENT_ID = process.env.REACT_APP_G_CALENDAR_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_G_CALENDAR_API_KEY;
    const DISCOVERY_DOC = process.env.REACT_APP_G_CALENDAR_DISCOVERY_DOCS;
    const SCOPES = process.env.REACT_APP_G_CALENDAR_SCOPES;

    let accessToken = localStorage.getItem('access_token');
    let expiresIn = localStorage.getItem('expires_in');

    let gapiInited = false, gisInited = false, tokenClient;
    const currentDate = new Date();
    const localTimeZoneOffset = currentDate.getTimezoneOffset();

    useEffect(() => {
        gapiLoaded()
        gisLoaded()
    }, [gisLoaded])

    function gapiLoaded() {
        gapi.load('client', initializeGapiClient);
    }

    function gisLoaded() {
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            access_type: "offline",
            scope: SCOPES,
            callback: '',
        });
        gisInited = true;
    }

    async function initializeGapiClient() {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
        });
        gapiInited = true;
    }

    function updateTime(currDate, time) {
        const [hours, minutes] = time.split(':')
        const date = new Date(currDate.setHours(hours, minutes));
        let utcDateTime = localDateTimeToUTC(date, localTimeZoneOffset);
        return utcDateTime;
    }

    async function handleAuthClick() {
        tokenClient.callback = async (resp) => {
            if (resp.error) {
                throw resp;
            }

            const { access_token, expires_in } = gapi.client.getToken();

            // Store the access token and refresh token
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('expires_in', expires_in);

            setRecheduleData((prevData) => ({
                ...prevData,
            }));

            // Schedule the expiration check after one hour & Clear tokens from local storage
            setTimeout(() => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('expires_in');
            }, expires_in * 1000);

        };

        if (!(accessToken && expiresIn)) {
            // Select a Google Account and ask for consent
            tokenClient.requestAccessToken({ prompt: 'consent', access_type: 'offline' });
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({ prompt: '' });
        }
    }

    async function editEvent(eventId, updatedEvent) {
        try {
            const request = gapi.client.calendar.events.update({
                'calendarId': 'primary',
                'eventId': eventId,
                'resource': updatedEvent,
                'sendUpdates': 'all'
            });

            await request;
        } catch (error) {
            console.error('Error updating event:', error);
        }
    }

    async function handleEditEvent() {
        const eventId = reScheduleData?.google_event_id;

        let selectedUserEmail = selectedUsers && selectedUsers.map((user) => { return user.email })
        const formattedEmails = selectedUserEmail.map(email => {
            return { email: email, responseStatus: 'needsAction' };
        });

        let RRULE_FREQ = ''
        if (recurringDays.value === 'Do not Repeat') {
            RRULE_FREQ = 'RRULE:FREQ=DAILY;COUNT=1'
        } else if (recurringDays.value === 'Daily') {
            RRULE_FREQ = 'RRULE:FREQ=DAILY'
        } else if (recurringDays.value === 'Weekly') {
            let day = updateTime(selectedDate, appointmentFromTime).getDay()
            let daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
            let selectedDay = daysOfWeek[day];
            RRULE_FREQ = `RRULE:FREQ=WEEKLY;BYDAY=${selectedDay}`;
        } else if (recurringDays.value === 'Every Weekday') {
            RRULE_FREQ = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';;
        }
        const meetingLink = reScheduleData?.event_meeting_link ? reScheduleData?.event_meeting_link : ''
        var updatedEvent = {
            'kind': 'calendar#event',
            'summary': reScheduleData?.task_name,
            'description': reScheduleData?.event_description,
            'location': `Meeting URL: ${meetingLink} `,
            'start': {
                'dateTime': new Date(updateTime(selectedDate, appointmentFromTime)),
                'timeZone': 'UTC'
            },
            'end': {
                'dateTime': new Date(updateTime(selectedDate, appointmentToTime)),
                'timeZone': 'UTC'
            },
            'recurrence': [RRULE_FREQ],
            'attendees': formattedEmails,
            'reminders': {
                'useDefault': true,
            },
            "guestsCanSeeOtherGuests": true,
        }

        await editEvent(eventId, updatedEvent);
    }

    const dispatch = useDispatch()
    const [reScheduleData, setRecheduleData] = useState({})
    const myEventData = useSelector(state => state.task.editData)
    const usersList = useSelector(state => state.allUserData.users)
    let newFromDate = new Date(myEventData.event_from);
    newFromDate = localDateTimeToUTC(newFromDate, -(localTimeZoneOffset))
    let newToDate = new Date(myEventData.event_to);
    newToDate = localDateTimeToUTC(newToDate, -(localTimeZoneOffset))
    

    
    const formattedCurrentDate = newFromDate.toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
    });
    const [formattedDate, setFormattedDate] = useState(formattedCurrentDate);
    const [selectedDate, setSelectedDate] = useState(newFromDate);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsersList, setAllUsersList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [appointmentFromTime, setAppointmentFromTime] = useState(`${('0' + newFromDate.getHours()).substr(-2)}:${('0' + newFromDate.getMinutes()).substr(-2)}`)
    const [appointmentToTime, setAppointmentToTime] = useState(`${('0' + newToDate.getHours()).substr(-2)}:${('0' + newToDate.getMinutes()).substr(-2)}`)

    const recurringOptions = [
        { value: 'Do not Repeat', label: 'Do not Repeat' },
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Every Weekday', label: 'Every Weekday' },
    ]
    const [recurringDays, setRecurringDays] = useState(recurringOptions.find(option => option.value === myEventData?.meeting_days?.[0]))

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRecheduleData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    function localDateTimeToUTC(localDateTime, timezoneOffset) {
        // Convert local date time to UTC
        var utcDateTime = new Date(localDateTime.getTime() + timezoneOffset * 60000);
        return utcDateTime;
    }
    const meetingSchedule = (e) => {
        updateAppointmentDetails(e, "reschedule")
    }

    const meetingScheduleEvent = (e) => {
        declineAppointmentDetails(e, "decline")
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormattedDate(date.toLocaleDateString(undefined, {
            weekday: "long",
            month: "long",
            day: "numeric",
        }));
    };
    const handleFromTimeChange = (event) => {
        setAppointmentFromTime(event.target.value);
    };
    const handleToTimeChange = (event) => {
        setAppointmentToTime(event.target.value);
    };
    const handleRecurringDays = (option) => {
        setRecurringDays(option)
    }
    const handleUserSelection = (user) => {
        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleSearch = () => {
        if (allUsersList) {
            const filterData = allUsersList.filter(value => value?.first_name?.includes(searchValue) || value.first_name.includes(searchValue)
                || searchValue.trim() === "")
            setFilteredData(filterData)

        }
    }

    let debounceTimer;
    const debounceTimeout = 500;

    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch()
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, allUsersList]);

    const updateAppointmentDetails = async (e, value) => {
        e.preventDefault()
        let selectedUser = selectedUsers && selectedUsers.map((user) => { return user._id })
        let newObj = {
            id: myEventData._id,
            appointment_status: value,
            task_name: reScheduleData?.task_name,
            event_meeting_link: reScheduleData?.event_meeting_link,
            event_description: reScheduleData?.event_description,
            event_from: updateTime(selectedDate, appointmentFromTime),
            event_to: updateTime(selectedDate, appointmentToTime),
            meeting_days: [recurringDays.value],
            assigned_to: selectedUser,
        }

        try {
            await apiServiceHandler("POST", "api/task/update", newObj)
            dispatch(updateRefreshTaskList(true))
            await handleEditEvent();
            onClose()
            toastr.success('Appointment Edited Successfully');
        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }
    }

    const declineAppointmentDetails = async (e, value) => {
        e.preventDefault()
        let newObj = {
            id: myEventData._id,
            appointment_status: value,
        }

        try {
            await apiServiceHandler("POST", "api/task/update", newObj)
            dispatch(updateRefreshTaskList(true))
            await handleEditEvent();
            onClose()
            toastr.success('Appointment Decline Successfully');
        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }
    }

    useEffect(() => {
        if (myEventData) {
            const allData = { ...myEventData };
            setRecheduleData(allData);
        }
        let existingUser = myEventData?.assigned_to || []
        let existingData = []
        usersList && usersList.map(val => {
            if (existingUser.includes(val._id)) {
                existingData.push(val)
            }
        })
        setSelectedUsers(existingData)
    }, [myEventData]);

    useEffect(() => {
        setAllUsersList(usersList.filter(user => user.status === 1));
    }, [usersList]);

    return (
        <div
            className={`modal fade appointments-modal edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
            id="appointmentsModal" tabindex="-1" aria-labelledby="appointmentsModalLabel" aria-hidden="true"
            style={{ display: isOpen ? "block" : "none" }}>
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content border-0">
                    <div className="modal-header mobile-new-appt">
                        <h2 className="modal-title">{isRescheduleOpen ? 'Reschedule or Decline Meeting' : 'Meeting Details'}</h2>
                        <div className="event-btn d-flex align-items-center">
                            <div className="justify-content-center view-appointment">
                                {accessToken && expiresIn ? <button type="button" className="google-synced-btn me-3" onClick={handleAuthClick}>Google Calendar Synced</button> : <button type="button" className="google-sync-btn me-3" onClick={handleAuthClick}>Sync Google Calendar</button>}
                                {isRescheduleOpen && <button type="submit" className="reschedule-btn" onClick={meetingSchedule}>Reschedule</button>}
                                <button type="button" className="decline-btn" onClick={meetingScheduleEvent}>Decline</button>
                            </div>
                            <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={CloseIcon} />
                            </button>
                        </div>
                    </div>
                    <div className="modal-body bg-white">
                        <div className="event-form">
                            <div className="row">
                                <div className="col-md-9 mb-4 mb-md-0">
                                    <div className="mb-2">
                                        <input type="text" className="form-control" placeholder="New Event Name" name='task_name' value={reScheduleData?.task_name}
                                            onChange={(e) => handleChange(e)}
                                            readOnly={!isRescheduleOpen} />
                                    </div>
                                    <div className="d-flex align-items-center mb-2 justify-content-between mobile-align">
                                        <div className="when-event">
                                            <div className="event-time">
                                                <div className="event-start-time">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="MMMM d, yyyy"
                                                        className="date-picker"
                                                        value={formattedDate}
                                                        readOnly={!isRescheduleOpen}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className='mobile-newappoitment'> */}
                                            <div className="custom-time-input">
                                                <input type="time" className="time-input" value={appointmentFromTime}
                                                    onChange={handleFromTimeChange}
                                                    readOnly={!isRescheduleOpen} />
                                            </div>
                                            <span>-</span>
                                            <div className="custom-time-input">
                                                <input type="time" className="time-input" value={appointmentToTime} onChange={handleToTimeChange}
                                                    readOnly={!isRescheduleOpen} />
                                            </div>
                                            {/* </div> */}
                                        </div>
                                        <div className="event-days d-flex align-items-center">
                                            <div className="all-days me-2 form-check d-flex align-items-center">
                                                <label className="form-check-label mb-0" htmlFor="allDay">
                                                    Recurring Event
                                                </label>
                                            </div>
                                            <div className="dropdown recurring-event">
                                                {isRescheduleOpen ? (
                                                    <Select
                                                        selected={recurringDays}
                                                        options={recurringOptions}
                                                        onChange={(e) => handleRecurringDays(e)}
                                                        name='meeting_days'
                                                        value={recurringDays}
                                                    />
                                                ) : (
                                                    <input type="text" className="form-control" value={recurringDays.value} readOnly />
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <span className='time-zone-info'>**Meeting time is in your local time.</span>
                                    <div className="mb-2">
                                        <label>Meeting Link</label>
                                        <div className="meeting-link">
                                            <input type="text" placeholder="Meeting Link" className="form-control" name='event_meeting_link' value={reScheduleData?.event_meeting_link}
                                                onChange={(e) => handleChange(e)}
                                                readOnly={!isRescheduleOpen}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-0">
                                        <textarea className="form-control" placeholder="Add Description" rows="5" name='event_description' value={reScheduleData?.event_description}
                                            onChange={(e) => handleChange(e)}
                                            readOnly={!isRescheduleOpen}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3 all-user-list">
                                    <div className="event-assignee">
                                        <div className="event-users">
                                            <form>
                                                <input type="text" onChange={(e) => setSearchValue(e.target.value)} name="searchusers" placeholder="Find Task..." className="form-control"
                                                    readOnly={!isRescheduleOpen} />
                                                <button><img src={SearchIcon} /></button>
                                            </form>
                                        </div>
                                        <ul className="m-0 list-unstyled">
                                            {filteredData && filteredData.map((user) => (<li className={selectedUsers.includes(user) ? "active" : ""} key={user.id}><a className="dropdown-item" href="#" onClick={() => isRescheduleOpen && handleUserSelection(user)} readOnly={!isRescheduleOpen}><span className="short-event-an">{user.first_name[0]}</span>{user.first_name} {user.last_name}</a></li>
                                            ))}  </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RescheduleAppointment
