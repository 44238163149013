// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userlogs = createSlice({
  name: 'userlogs',
  initialState: {
    refreshUserLogs: false,
    userLogsList: [],
    usersCount: [],
    projectsCount: []
  },
  reducers: {
    updateRefreshUserLogs: (state, action) => {
      state.refreshUserLogs = action.payload; // Update the count using the payload
    },
    updateUserLogsList: (state, action) => {
      state.userLogsList = action.payload; // Update the count using the payload
    },
    updateUsersCount: (state, action) => {
      state.usersCount = action.payload; // Update the count using the payload
    },
    updateProjectsCount: (state, action) => {
      state.projectsCount = action.payload; // Update the count using the payload
    },
  },
});

export const { updateRefreshUserLogs, updateUserLogsList, updateUsersCount, updateProjectsCount } = userlogs.actions;
export default userlogs.reducer;
