import "./App.css";
import Header from "../src/module/pages/header/index";
import Login from "../src/module/pages/auth/login";
import Developer_dashboard from "./module/pages/Dashboard/developer_dashboard";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import SignUp from "./module/pages/signup/signupPage";
import OtpVerifyCode from "./module/pages/signup/otpverify";
import WelcomeCode from "./module/pages/signup/welcomeCode";
import SigninUrl from './module/pages/signup/signinUrl';
import PrivateRoute from "./module/route/protectedRoute";
import HomePage from "./module/pages/home";
import PlansScreen from "./module/pages/paypal/plansScreen";
import Payment from "./module/pages/paypal/payment";
import Success from "./module/pages/paypal/success";
import Cancel from "./module/pages/paypal/cancel";
import ClickupSignup from "./module/pages/signup/clickupToken";
import JiraSignup from "./module/pages/signup/jiraToken";
import MainIndexPage from "./module/pages/main-page";
import HomePageIndex from "./module/pages/main-page/home";
import FeaturesPage from "./module/pages/main-page/features";
import PricingPage from "./module/pages/main-page/pricing";
import ContactusPage from "./module/pages/main-page/contactus";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<HomePageIndex />} />
        <Route path="/signin" element={<HomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/contact-us" element={<ContactusPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/:id" element={<Login />} />
        <Route path="/choose-plans" element={<PrivateRoute element={<PlansScreen />} />} />
        <Route path="/payment" element={<PrivateRoute element={<Payment />} />} />
        <Route path="/payment-success" element={<PrivateRoute element={<Success />} />} />
        <Route path="/payment-cancel" element={<PrivateRoute element={<Cancel />} />} />
        {/* <Route path="/Dashboard/:id" element={<Developer_dashboard />} /> */}
        <Route path="/Dashboard/:id" element={<PrivateRoute element={<Developer_dashboard />} />} />
        <Route component={<Login />} />
        <Route path='/otp-verify' element={<OtpVerifyCode />} />
        <Route path='/welcome-code' element={<WelcomeCode />} />
        <Route path='/signin-url' element={<SigninUrl />} />
        <Route path='/clickup-signup' element={<ClickupSignup />} />
        <Route path='/jira-signup' element={<JiraSignup />} />
        {/* <Route path="/otp-verify" element={<OtpVerifyCode />} /> */}
        {/* <Route path="/welcome-code" element={<WelcomeCode />} /> */}
      </Routes>
    </div>
  );
}

export default App;
