import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshUserDatap, updateRoleList } from '../../redux/userManagement';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateRefreshUserLogs, updateUsersCount } from '../../redux/userlogs';
import { countries } from 'countries-list';

const AddNewUser = ({ setRefreshUserManagementData, isOpen, onClose, paymentDetails }) => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const [uniqueUrlStatusMessage, setUniqueUrlStatusMessage] = useState("")
    const [rolesList, setRolesList] = useState([])
    const [selectRole, setSelectRole] = useState("")
    const roleList = useSelector(state => state.allUserData.roleList)
    const userLists = useSelector(state => state.userlogs.usersCount)
    const projectList = useSelector(state => state.userlogs.projectsCount)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedCountry, setSelectedCountry] = useState({ countryCode: '', countryName: '', countryPhoneCode: '' });

    const handleCountryChange = (e) => {
        setSelectedCountry({
            countryCode: e.target.value,
            countryName: countries[e.target.value].name,
            countryPhoneCode: countries[e.target.value].phone[0],
        });
    };

    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        unique_url: '',
        company_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: {},
        zipcode: '',
        login_code: '',
        created_by: '',
        owned_by: '',
        // subscription_fee: ''
    })

    const [errorMessage, setErrorMessage] = useState('');

    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}

    // const handleSubscriptionFee = () => setSubscriptionFee(!subscriptionFee);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let existingcompanyName = usermData?.company_name

        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (value.length == 0) {
            setErrorMessage("")
        }
        else if (name == 'company_name' && value !== existingcompanyName) {
            setErrorMessage(`External to ${existingcompanyName}`);
        } else {
            setErrorMessage('');
        }
    };

    const handleAdduser = async () => {
        if (selectRole === "Select role" || selectRole === "") {
            toastr.error("Please select a role")
            return
        }
        if (!uniqueUrlStatusMessage) {
            toastr.error("Please enter a available unique Url")
            return
        }
        if (userData.login_code.length < 4) {
            toastr.error("Please enter a valid Login Code")
            return
        }
        let payload = userData;
        payload.country = selectedCountry;
        payload.role = selectRole
        payload.owned_by = user.owned_by ? user.owned_by : user.unique_url;
        payload.parent_company_id = user.owned_by ? user.parent_company_id : user.company_id;
        payload.parent_company_name = user.owned_by ? user.parent_company_name : user.company_name;
        // payload.subscription_fee = subscriptionFee? userData.subscription_fee : 0;
        try {
            const response = await apiServiceHandler("POST", "api/auth/signup_team_client", payload)
            if (response.status) {
                let paymentPayload = {
                    unique_url: user?.owned_by ? user.owned_by : user.unique_url,
                    data: {
                        uniqueUrl: userData.unique_url,
                        email: userData.email,
                        role: userData.role,
                        // subscription_fee: userData.subscription_fee,
                        created_by: user.unique_url
                    }
                }
                await apiServiceHandler("PUT", "api/payment/paypal/update/payment-details", paymentPayload)
                const userCount = await apiServiceHandler(
                    "POST",
                    "api/auth/user/users/count",
                    { unique_url: user.owned_by ? user.owned_by : user.unique_url }
                );
                dispatch(updateUsersCount(userCount.data));
                setRefreshUserManagementData(true)
                setSelectedCountry({ countryCode: '', countryName: '', countryPhoneCode: '' })
                toastr.success(response?.message)
                onClose()
            }
        } catch (error) {
            toastr.error(error?.response?.data?.message)
            console.log(error)
        }
    }

    const debounceTimeout = 500; // 500ms delay 

    let debounceTimer;

    useEffect(() => {
        if (userData.unique_url === "") {
            setUniqueUrlStatusMessage("")
            return
        }
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            // console.log('Performing action with:', userData.unique_url);
            handleVerifyUrl()
        }, debounceTimeout);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [userData.unique_url]);

    const handleVerifyUrl = async (e) => {
        const payload = {
            uniqueUrl: userData.unique_url
        }
        try {
            const response = await apiServiceHandler("POST", "api/auth/unique/url", payload);
            setUniqueUrlStatusMessage(true)
            toastr.success(response?.message)
        } catch (e) {
            toastr.warning(e?.response?.data?.message)
            console.log(e)
            setUniqueUrlStatusMessage(false)
        }
    }

    const fetchRoleList = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/get_roles_list", {})
            setRolesList(response?.role_list)
            dispatch(updateRoleList(response?.role_list))
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        fetchRoleList()
    }, [])



    const handleAddUserQuantity = async (e) => {
        e.preventDefault();
        navigate(`/choose-plans`, { state: { firstName: user.first_name, unique_url: user.owned_by ? user.owned_by : user.unique_url, email: user.email, paymentDetails, currentUsers: userLists, currentProject: projectList, newUser: false } })
    }

    if (paymentDetails?.user_quantity > userLists) {
        return (
            <div>
                <div className={`modal fade add-user ${isOpen ? "show backdrop" : " "}`}
                    id="addUserModal"
                    tabindex={-1}
                    aria-labelledby="addUserModalLabel"
                    aria-hidden={true}
                    style={{ display: isOpen ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-0">
                            <div className="modal-header">
                                <h2 className="modal-title">Add New User</h2>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>

                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <label>First name</label>
                                            <input type="text" className="form-control" name='first_name' value={userData?.first_name} onChange={(e) => handleChange(e)} placeholder="First name*" required />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>Last name</label>
                                            <input type="text" className="form-control" placeholder="Last name*" name='last_name' value={userData?.last_name} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Email</label>
                                            <input type="email" className="form-control" placeholder="Email*" name='email' value={userData?.email} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>Contact Number <span>(optional)</span></label>
                                            <input type="text" className="form-control" placeholder="Contact Number" name='phone' value={userData?.phone} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>Login PIN<span></span></label>
                                            <input type="text" className="form-control"
                                                title="Please assign a PIN, the user can use this PIN when logging in" maxLength="4" placeholder="Set Login PIN*" name='login_code' value={userData?.login_code} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-6 mb-2">
                                            <label>URL</label>
                                            <input type="text" className="form-control" title="Please assign a URL, the user can use this URL when logging in" placeholder="livelook.com*" name='unique_url' value={userData?.unique_url} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-6 mb-2">
                                            <label>Role</label>
                                            <select onChange={(e) => setSelectRole(e.target.value)} value={selectRole} placeholder='select role' className="form-select" aria-label="status">
                                                <option>Select role</option>
                                                {roleList && roleList.map(role => <option value={role}>{role}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label>Company Name</label>
                                            <input type="text" className="form-control" placeholder="Company Name*" name='company_name' value={userData?.company_name} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        {errorMessage && <p style={{ color: 'red', textAlign: 'left', margin: '2px', fontSize: '15px' }}>{errorMessage}</p>}
                                        <div className="col-md-6 mb-2">
                                            <label>Address Line 1</label>
                                            <input type="text" className="form-control" placeholder="Address Line 1*" name='address_line_1' value={userData?.address_line_1} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>Address Line 2</label>
                                            <input type="text" className="form-control" placeholder="Address Line 2*" name='address_line_2' value={userData?.address_line_2} onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>City</label>
                                            <input type="text" className="form-control" placeholder="City*" name='city' value={userData?.city} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label>State</label>
                                            <input type="text" className="form-control" placeholder="State*" name='state' value={userData?.state} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="col-md-6 mb-2 mb-md-0">
                                            <label>Country</label>
                                            <select
                                                value={selectedCountry.countryCode}
                                                onChange={handleCountryChange}
                                                className="form-control"
                                                required
                                            >
                                                <option value="">Select a country*</option>
                                                {Object.keys(countries).map((countryCode) => (
                                                    <option key={countryCode} value={countryCode}>
                                                        {countries[countryCode].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Postal Code</label>
                                            <input type="text" className="form-control" placeholder="Postal Code*" name='zipcode' value={userData?.zipcode} onChange={(e) => handleChange(e)} required />
                                        </div>
                                        {/*
                                        <div>
                                            <h6 className='subscription'>Subscription Fees:</h6>
                                            <div className='subscription-div'>Charge a subscription fees to this user for using this platform.</div>
                                            <div className='subscription-input'><input type="checkbox" id="subscription-fee" name="subscription-fee" value={subscriptionFee} onChange={() => handleSubscriptionFee()} /> Click here if you want to charge subscription fees
                                            </div>
                                            {subscriptionFee && <div className="col-md-8" style={{ paddingTop: '8px' }}><input type='text' className='form-control' placeholder='Enter monthly Subscription fee amount*' name='subscription_fee' value={userData?.subscription_fee} onChange={(e) => handleChange(e)} /></div>}
                                        </div>
                                    */}
                                    </div>
                                </form>
                            </div>

                            <div className="modal-footer justify-content-center">
                                <button type="submit" onClick={() => handleAdduser()} className="bg-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div>
                <div className={`modal fade add-user ${isOpen ? "show backdrop" : " "}`}
                    id="addUserModal"
                    tabindex={-1}
                    aria-labelledby="addUserModalLabel"
                    aria-hidden={true}
                    style={{ display: isOpen ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-0">
                            <div className="modal-header">
                                <h2 className="modal-title">Update Subscription</h2>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>

                            <div className="modal-body">
                                <form onSubmit={handleAddUserQuantity}>
                                    <div className="user-update">
                                        <div className='add-text'>Your Current Plan is for {paymentDetails?.user_quantity} users</div>
                                        <div className='add-text-2'>Please upgrade your plan to add more users</div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="bg-btn">
                                            Procced to Upgrade
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default AddNewUser