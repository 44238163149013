import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { onValue, child, get, ref } from "firebase/database"

const firebaseConfig = {
    apiKey: "AIzaSyBL1E6SkbOrEbJ76wOYKpexpSek8q72AnA",
    authDomain: "joinlivelook.firebaseapp.com",
    projectId: "joinlivelook",
    storageBucket: "joinlivelook.appspot.com",
    messagingSenderId: "780086134929",
    appId: "1:780086134929:web:cd5962462b5257fa861730",
    measurementId: "G-8EH32WW1MN"
};

const app = initializeApp(firebaseConfig);
export const rtdb = getDatabase(app);