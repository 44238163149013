import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import PencilIcon from '../../assests/images/pencil.svg';
import SaveIcon from '../../assests/images/save.svg';
import CloseBIcon from '../../assests/images/close-black.svg';
import CopyIcon from '../../assests/images/copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import { updateRefreshTaskList } from '../../redux/task';
import { updateRefreshProjectList } from '../../redux/project';
import ProgressProjectList from './progressProjectList';


const ViewProgressModal = ({ isOpen, onClose }) => {
    const [isOpenAcc, setIsOpenAcc] = useState(false);
    const dispatch = useDispatch()
    const projects = useSelector(state => state.project)
    const [editDetails, setEditDetails] = useState({})
    const [selectedProject, setSelectedProject] = useState(null); // State to store the selected project
    const [projectDetails, setProjectDetails] = useState([])   
    const [newProgressData, setNewProgressData] = useState([])
    
   
    useEffect(() => {

        setNewProgressData(projects.showProjectList)

    }, [projects])


    return (
        <div className={`modal fade  ${isOpen ? "show backdrop" : " "}`} style={{ display: isOpen ? "block" : "none" }} id="viewProgressModal" tabindex="-1" aria-labelledby="viewProgressModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="modal-title">View Progress</h2>
                        <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src={CloseIcon} />
                        </button>
                    </div>
                    <div className="modal-body px-0">
                        <div className="accordion accordion-flush" id="viewProgress">
                            {newProgressData && newProgressData.map((value) => (
                                <ProgressProjectList value={value} />
                            ))}
                           
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ViewProgressModal
