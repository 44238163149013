import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import apiServiceHandler from '../../service/apiService';
import { updatePaymentDetails, updateRefreshUserDatap } from '../../redux/userManagement';
import { useDispatch } from 'react-redux';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const SuspendConfirmationModal = ({ show, handleClose, paymentDetails, uniqueUrl, updatePaymentDetailsDB, fetchPaymentDetails }) => {
  const dispatch = useDispatch()

  const handleActivateSubscription = async (event) => {
    event.currentTarget.disabled = true;
    try {
      const payload = {
        subscriptionID: paymentDetails.payment_details.id
      }
      const payment = await apiServiceHandler('POST', 'api/payment/paypal/subscription/activate', payload)
      const details = {
        unique_url: uniqueUrl,
        data: payment
      }
      const res = await updatePaymentDetailsDB(details);
      fetchPaymentDetails()
      dispatch(updateRefreshUserDatap(true))
      handleClose()
      toastr.success('Your Subscription Activated Successfully.')
    } catch (error) {
      console.log(error, 'Error is here in handleActivateSubscription')
      toastr.error('Something went wrong! Try Again Later')
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activate Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to activate your subscription?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleActivateSubscription}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SuspendConfirmationModal
