import React, { useEffect, useRef, useState } from "react";
import "./upcoming.css";
import Calendar from "react-calendar";
import PlusIcon from "../../assests/icons/plusg.svg";
import ExpandIcon from "../../assests/images/expand.svg";
import DragIcon from "../../assests/images/drag.svg";
import TickIcon from "../../assests/images/tick-white.svg";
import CloseWIcon from "../../assests/images/close-white.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewAppointments,
  updateEditTaskData,
  updateRefreshTaskList,
} from "../../redux/task";
import apiServiceHandler from "../../service/apiService";
import {
  updateUpcomingAppointmentPopup,
  updateupcomingHeadline,
} from "../../redux/viewmore";
import { updateloaderStatus } from "../../redux/loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const UpcomingAppointments = ({ setUpcomingAppointmentsProps, flag }) => {
  const myAppointmentList = useSelector(
    (state) => state.task.TasksList?.appointment
  );
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [attendedMeetings, setAttendedMeetings] = useState([]);
  const [meetingDates, setMeetingDates] = useState([]);
  const upcomingPopup = useSelector((state) => state.viewmore);
  const user = JSON.parse(localStorage.getItem('userData'))

  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
  const projects = useSelector(state => state.project?.showProjectList) || []
  const dispatch = useDispatch()
  const currentDate1 = new Date();
  const localTimeZoneOffset = currentDate1.getTimezoneOffset();

  function localDateTimeToUTC(localDateTime, timezoneOffset) {
    // Convert local date time to UTC
    var utcDateTime = new Date(localDateTime.getTime() + timezoneOffset * 60000);
    return utcDateTime;
  }

  const getFormattedDate = (event_from) => {
    let fromDate = new Date(event_from);
    fromDate = localDateTimeToUTC(fromDate, -(localTimeZoneOffset))
    const options = { year: "2-digit", month: "short", day: "numeric" };
    return fromDate.toLocaleDateString("en-US", options);
  };
  const handleClose = () => {
    setNewAppointmentModal(false);
    setOpenRescheduleModal(false);
  };
  useEffect(() => {
    if (newAppointmentModal) {
      setUpcomingAppointmentsProps({
        ModalName: "NewAppointment",
        isOpen: newAppointmentModal,
        onClose: handleClose,
      });
    } else if (openRescheduleModal) {
      setUpcomingAppointmentsProps({
        ModalName: "OpenReschedule",
        isOpen: openRescheduleModal,
        onClose: handleClose,
        isRescheduleOpen
      });
    } else {
      setUpcomingAppointmentsProps(null);
    }
  }, [newAppointmentModal, openRescheduleModal]);

  const updateAppointment = async (id, value) => {
    dispatch(updateloaderStatus(true));
    // e.preventDefault()
    let newObj = {
      id,
      appointment_status: value,
    };
    try {
      const response = await apiServiceHandler(
        "POST",
        "api/task/update",
        newObj
      );
      // console.log(response);
      dispatch(updateRefreshTaskList(true));
      dispatch(updateloaderStatus(false));
    } catch (err) {
      // toastr.error(err?.response?.data?.message)
      dispatch(updateloaderStatus(false));
      console.log(err);
    }
  };

  const getTileClass = ({ date, view }) => {
    //     if (view === 'month' && meetingDates.some(meetingDate => new Date(meetingDate.date).toDateString() === new Date(date).toDateString() && meetingDate.date >= new Date().toISOString().split('T')[0]))
    //         return 'meeting-date-highlight';
    // }
    if (
      view === "month" &&
      meetingDates.some((meetingDate) => {
        const isSameDate =
          new Date(meetingDate.date).toDateString() ==
          new Date(date).toDateString();
        const isUpcoming =
          meetingDate.date >= new Date().toISOString().split("T")[0];
        const meetDays = meetingDate.meetingDays;
        let weeklyDate = "";
        if (meetingDate.meetingDays?.[0] === "Weekly") {
          weeklyDate = meetingDate.date;
        }

        if (isSameDate && isUpcoming) {
          return true;
        } else if (meetDays?.[0] === "Daily") {
          return true;
        } else if (meetDays?.[0] === "Every Weekday") {
          const dayOfWeek = new Date(date).getDay();
          return dayOfWeek !== 6 && dayOfWeek !== 0;
        } else if (meetDays?.[0] === "Weekly") {
          const dayOfWeek = new Date(date).getDay();
          const dayOfWeekly = new Date(weeklyDate).getDay();
          return dayOfWeek == dayOfWeekly;
        }
        return false;
      })
    ) {
      return "meeting-date-highlight";
    }
  };

  useEffect(() => {
    if (Array.isArray(myAppointmentList)) {
      const meetingDatesArray = myAppointmentList
        .filter((val) => {
          // Check if the appointment_user_status array contains the current user ID
          const appointmentStatus = val.appointment_user_status.find(statusObj => statusObj.id === user.user_id);

          // If the appointmentStatus object exists, check its status
          if (appointmentStatus) {
            const status = appointmentStatus.status;
            return status === "accept" || (status === "reschedule" && val.event_from);
          }
          return false;
        })
        .map((val) => {
          // let date = val.event_from.split("T")[0];
          let newToDate = new Date(val.event_from);
          newToDate = localDateTimeToUTC(newToDate, -(localTimeZoneOffset))
          const meetingDays = val?.meeting_days || [];
          const year = newToDate.getFullYear();
          const month = String(newToDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
          const day = String(newToDate.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          let date = formattedDate
          return { date, meetingDays };
        });

      setMeetingDates(meetingDatesArray);
    }
  }, [myAppointmentList]);

  const reScheduleEvent = (value) => {
    if (user.user_id === value.created_by) setIsRescheduleOpen(true);
    setOpenRescheduleModal(true);
    dispatch(updateEditTaskData(value));
  };

  const handleAppointment = (val) => {
    updateAppointment(val._id, "accept");
    toastr.success('Appointment Accepted Successfully');
  };

  const handleNewAppointment = () => {
    if (projects.length > 0) {
      setNewAppointmentModal(true)
    } else {
      toastr.error('No Project Found');
    }
  }

  // const handleDeclineAppointment = (val) => {
  //   updateAppointment(val._id, "decline");
  //   toastr.success('Appointment Decline Successfully');
  // };

  return (
    // <div className="col-md-6 mb-4">
    <div className="upcoming-appointments">
      <div
        className={
          flag
            ? "appointments-heading-viewmore-css d-flex align-items-center justify-content-between"
            : "appointments-heading d-flex align-items-center justify-content-between"
        }
      >
        {!flag && <h2 className="mb-0">Calendar</h2>}
        <div class="d-flex align-items-center">
          {!flag && (
            <>
              {" "}
              <button
                type="button"
                className="add-icon"
                onClick={() => handleNewAppointment()}
              >
                <img src={PlusIcon} />
              </button>
              {window.screen.width > 760 && <button
                type="button"
                onClick={() => {
                  dispatch(updateUpcomingAppointmentPopup(true));
                  dispatch(updateupcomingHeadline("Calendar"));
                }}
                className="add-icon"
              >
                <img src={ExpandIcon} />
              </button>}
              <button type="button" className="drag-me">
                <img src={DragIcon} />
              </button>{" "}
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 apt-col">
          <ul
            className={
              flag
                ? "appointments-list viewmore-popup m-0 list-unstyled"
                : "appointments-list viewmore m-0 list-unstyled"
            }
          >
            {myAppointmentList &&
              myAppointmentList
                .slice()
                .sort((a, b) => new Date(a.event_from) - new Date(b.event_from))
                .map((val, index) => {
                  const date = new Date(val.event_from)
                    .toISOString()
                    .split("T")[0];
                  const currentDate = new Date().toISOString().split("T")[0];
                  let eventFromDate = new Date(val.event_from);
                  eventFromDate = localDateTimeToUTC(eventFromDate, -(localTimeZoneOffset))
                  const eventFromTime = eventFromDate.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  if (
                    val.appointment_user_status.find((e) => e.id === user.user_id && e.status === "accept") && (val.meeting_days?.[0] === "Daily" ||
                      val.meeting_days?.[0] === "Every Weekday" ||
                      val.meeting_days?.[0] === "Weekly" ||
                      (date >= currentDate && val.meeting_days?.[0] === "Do not Repeat"))
                  ) {
                    return (
                      <li
                        key={val.id}
                        className={
                          val.appointment_user_status.find((e) => e.id === user.user_id && e.status === "accept") ? "accepted" : ""
                        }
                      >
                        <div className="apt-info">
                          <h3>{val?.task_name}</h3>
                          <span>
                            {val?.event_from >= currentDate
                              ? getFormattedDate(val?.event_from)
                              : getFormattedDate(currentDate)}{" "}
                            | {eventFromTime} | {val.meeting_days?.[0]}
                          </span>
                        </div>
                        <div className="apt-confirm">
                          <a href="#!" onClick={() => reScheduleEvent(val)}>
                            <img src={CloseWIcon} />
                            View
                          </a>
                        </div>
                      </li>
                    );
                  } else if (val.appointment_user_status.find((e) => e.id === user.user_id && e.status === "decline")) {
                    return null;
                  } else if (
                    val.appointment_user_status.find((e) => e.id === user.user_id && e.status === "reschedule") && (val.meeting_days?.[0] === "Daily" ||
                      val.meeting_days?.[0] === "Every Weekday" ||
                      val.meeting_days?.[0] === "Weekly" ||
                      (date >= currentDate && val.meeting_days?.[0] === "Do not Repeat"))
                  ) {
                    return (
                      <li
                        key={val.id}
                        className={
                          val.appointment_user_status.find((e) => e.id === user.user_id && e.status === "reschedule")
                            ? "reschedule"
                            : ""
                        }
                      >
                        <div className="apt-info">
                          <h3>{val?.task_name}</h3>
                          <span>
                            {val?.event_from >= currentDate
                              ? getFormattedDate(val?.event_from)
                              : getFormattedDate(currentDate)}{" "}
                            | {eventFromTime} | {val.meeting_days?.[0]}
                          </span>
                        </div>
                        <div className="apt-confirm">
                          <a href="#!" onClick={() => reScheduleEvent(val)}>
                            <img src={CloseWIcon} />
                            View
                          </a>
                        </div>
                      </li>
                    );
                  } else {
                    return date >= currentDate || (val.meeting_days?.[0] === "Daily" ||
                      val.meeting_days?.[0] === "Every Weekday" ||
                      val.meeting_days?.[0] === "Weekly" || val.meeting_days?.[0] === "Do not Repeat") ? (
                      <li key={val.id}>
                        <div className="apt-info">
                          <h3>{val?.task_name}</h3>
                          <span>
                            {getFormattedDate(val?.event_from)} |{" "}
                            {eventFromTime} | {val.meeting_days?.[0]}
                          </span>
                        </div>
                        <div className="apt-confirm">
                          <button
                            type="button"
                            className="apt-yes"
                            onClick={() => {
                              handleAppointment(val);
                              setAttendedMeetings((prevMeetings) => [
                                ...prevMeetings,
                                val,
                              ]);
                            }}
                          >
                            <img src={TickIcon} />
                          </button>
                          <button
                            type="button"
                            className="apt-no"
                            onClick={() => reScheduleEvent(val)}
                          >
                            <img src={CloseWIcon} />
                          </button>
                        </div>
                      </li>
                    ) : null;
                  }
                })}
          </ul>
          {window.screen.width > 760 && !upcomingPopup.upcomingAppointmentPopup && (
            <button
              type="button"
              onClick={() => {
                dispatch(updateUpcomingAppointmentPopup(true));
                dispatch(updateupcomingHeadline("Calendar"));
              }}
              class="view-more button-upcoming"
            >
              View More
            </button>
          )}
        </div>

        {window.screen.width > 760 ? (
          <div className="col-md-6 calendar">
            <div>
              <Calendar tileClassName={getTileClass} />
            </div>
          </div>
        ) : upcomingPopup.upcomingAppointmentPopup ? (
          <div className="col-md-6 calendar">
            <div>
              <Calendar tileClassName={getTileClass} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UpcomingAppointments;
