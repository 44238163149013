import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import SearchIcon from '../../assests/images/search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshTaskList } from '../../redux/task';
import apiServiceHandler from '../../service/apiService';
import Select from 'react-select';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

function localDateTimeToUTC(localDateTime, timezoneOffset) {
   // Convert local date time to UTC
   var utcDateTime = new Date(localDateTime.getTime() + timezoneOffset * 60000);
   return utcDateTime;
}
function updateTime(currDate, time) {
   const [hours, minutes] = time.split(':')
   const date = new Date(currDate.setHours(hours, minutes));
   var timezoneOffset = date.getTimezoneOffset();
   var utcDateTime = localDateTimeToUTC(date, timezoneOffset);
   return utcDateTime;
}

const NewAppointmentModal = ({ isOpen, onClose }) => {

   // Google Calendar
   const gapi = window.gapi;
   const google = window.google;

   const CLIENT_ID = process.env.REACT_APP_G_CALENDAR_CLIENT_ID;
   const API_KEY = process.env.REACT_APP_G_CALENDAR_API_KEY;
   const DISCOVERY_DOC = process.env.REACT_APP_G_CALENDAR_DISCOVERY_DOCS;
   const SCOPES = process.env.REACT_APP_G_CALENDAR_SCOPES;

   let accessToken = localStorage.getItem('access_token');
   let expiresIn = localStorage.getItem('expires_in');

   let gapiInited = false, gisInited = false, tokenClient;

   useEffect(() => {
      gapiLoaded()
      gisLoaded()
   }, [gisLoaded])

   function gapiLoaded() {
      gapi.load('client', initializeGapiClient);
   }

   function gisLoaded() {
      tokenClient = google.accounts.oauth2.initTokenClient({
         client_id: CLIENT_ID,
         access_type: "offline",
         scope: SCOPES,
         callback: '',
      });
      gisInited = true;
   }

   async function initializeGapiClient() {
      await gapi.client.init({
         apiKey: API_KEY,
         discoveryDocs: [DISCOVERY_DOC],
      });
      gapiInited = true;
   }

   async function handleAuthClick() {
      tokenClient.callback = async (resp) => {
         if (resp.error) {
            throw resp;
         }

         const { access_token, expires_in } = gapi.client.getToken();

         // Store the access token and refresh token
         localStorage.setItem('access_token', access_token);
         localStorage.setItem('expires_in', expires_in);

         setAppointmentsData((prevData) => ({
            ...prevData,
         }));

         // Schedule the expiration check after one hour & Clear tokens from local storage
         setTimeout(() => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires_in');
         }, expires_in * 1000);

      };

      if (!(accessToken && expiresIn)) {
         // Select a Google Account and ask for consent
         tokenClient.requestAccessToken({ prompt: 'consent', access_type: 'offline' });
      } else {
         // Skip display of account chooser and consent dialog for an existing session.
         tokenClient.requestAccessToken({ prompt: '' });
      }
   }

   async function addNewAppointment() {
      let selectedUserEmail = selectedUsers && selectedUsers.map((user) => { return user.email })
      const formattedEmails = selectedUserEmail.map(email => {
         return { email: email, responseStatus: 'needsAction' };
      });
      let RRULE_FREQ = ''
      if (recurringDays.value === 'Do not Repeat') {
         RRULE_FREQ = 'RRULE:FREQ=DAILY;COUNT=1'
      } else if (recurringDays.value === 'Daily') {
         RRULE_FREQ = 'RRULE:FREQ=DAILY'
      } else if (recurringDays.value === 'Weekly') {
         let day = updateTime(selectedDate, appointmentFromTime).getDay()
         let daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
         let selectedDay = daysOfWeek[day];
         RRULE_FREQ = `RRULE:FREQ=WEEKLY;BYDAY=${selectedDay}`;
      } else if (recurringDays.value === 'Every Weekday') {
         RRULE_FREQ = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';;
      }
      const meetingLink = appointmentsData?.event_meeting_link ? appointmentsData?.event_meeting_link : ''

      var event = {
         'kind': 'calendar#event',
         'summary': appointmentsData?.task_name,
         'description': appointmentsData?.event_description,
         'location': `Meeting URL: ${meetingLink} `,
         'start': {
            'dateTime': new Date(updateTime(selectedDate, appointmentFromTime)),
            'timeZone': 'UTC'
         },
         'end': {
            'dateTime': new Date(updateTime(selectedDate, appointmentToTime)),
            'timeZone': 'UTC'
         },
         'recurrence': [RRULE_FREQ],
         'attendees': formattedEmails,
         'reminders': {
            'useDefault': true,
         },
         "guestsCanSeeOtherGuests": true,
      }

      var request = gapi.client.calendar.events.insert({ 'calendarId': 'primary', 'resource': event, 'sendUpdates': 'all' });

      request.execute(async (event) => {

         let selectedUser = selectedUsers && selectedUsers.map((user) => { return user._id })
         let newObj = {
            task_name: appointmentsData?.task_name,
            event_from: updateTime(selectedDate, appointmentFromTime),
            event_to: updateTime(selectedDate, appointmentToTime),
            event_meeting_link: appointmentsData?.event_meeting_link,
            event_description: appointmentsData?.event_description,
            project_id: projects?.[0]?._id,
            task_status: ['appointment'],
            assigned_to: selectedUser,
            meeting_days: [recurringDays.value],
            google_event_id: event?.result?.id
         }

         try {
            await apiServiceHandler("POST", "api/task/appointment", newObj)
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success('New Appointment Added Successfully');
         } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
         }
      }, (error) => {
         console.error(error);
      });
   }

   const currentDate = new Date();
   const formattedCurrentDate = currentDate.toLocaleDateString(undefined, {
      weekday: "short",
      month: "short",
      day: "numeric",
   });

   const dispatch = useDispatch()
   const projects = useSelector(state => state.project?.showProjectList) || []
   const usersList = useSelector(state => state.allUserData.users)
   const [selectedDate, setSelectedDate] = useState(currentDate);
   const [formattedDate, setFormattedDate] = useState(formattedCurrentDate);
   const [appointmentsData, setAppointmentsData] = useState({})
   const [selectedUsers, setSelectedUsers] = useState([]);
   const [allUsersList, setAllUsersList] = useState([])
   const [recurringDays, setRecurringDays] = useState([])
   const [searchValue, setSearchValue] = useState("")
   const [filteredData, setFilteredData] = useState([])
   const [appointmentFromTime, setAppointmentFromTime] = useState(`${('0' + new Date().getHours()).substr(-2)}:${('0' + new Date().getMinutes()).substr(-2)}`)
   const [appointmentToTime, setAppointmentToTime] = useState(`${('0' + (new Date().getHours() + 1)).substr(-2)}:${('0' + new Date().getMinutes()).substr(-2)}`)

   const recurringOptions = [
      { value: 'Do not Repeat', label: 'Do not Repeat' },
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Every Weekday', label: 'Every Weekday' },
   ]

   useEffect(() => {
      setAllUsersList(usersList.filter(user => user.status === 1));
   }, [usersList]);

   const handleRecurringDays = (option) => {
      setRecurringDays(option)
   }

   const handleUserSelection = (user) => {
      if (selectedUsers.includes(user)) {
         setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser !== user));
      } else {
         setSelectedUsers([...selectedUsers, user]);
      }
   };

   const handleChange = (event) => {
      const { name, value } = event.target;
      setAppointmentsData((prevData) => ({
         ...prevData,
         [name]: value,
      }));
   };

   const handleDateChange = (date) => {
      setSelectedDate(date);
      setFormattedDate(date.toLocaleDateString(undefined, {
         weekday: "short",
         month: "short",
         day: "numeric",
      }));
   };  
   const handleFromTimeChange = (event) => {
      setAppointmentFromTime(event.target.value);
   };
   const handleToTimeChange = (event) => {
      setAppointmentToTime(event.target.value);
   };

   const handleSearch = () => {
      if (allUsersList) {
         const filterData = allUsersList.filter(value => value?.first_name?.includes(searchValue) || value.first_name.includes(searchValue)
            || searchValue.trim() === "")
         setFilteredData(filterData)

      }
   }

   let debounceTimer;
   const debounceTimeout = 500;

   useEffect(() => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
         handleSearch()
      }, debounceTimeout);
      return () => {
         clearTimeout(debounceTimer);
      };
   }, [searchValue, allUsersList]);

   return (
      <div
         className={`modal fade appointments-modal ${isOpen ? "show backdrop" : " "}`}
         id="appointmentsModal" tabindex="-1" aria-labelledby="appointmentsModalLabel" aria-hidden="true"
         style={{ display: isOpen ? "block" : "none" }}>
         <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content border-0">
               <div className="modal-header mobile-new-appt">
                  <h2 className="modal-title">Event Name</h2>
                  <div className="event-btn d-flex align-items-center">
                     {/* <button type="button" className="no-default me-3"><img src={EditIcon} /></button>
                     <button type="button" className="no-default me-3"><img src={DleteBIcon} /></button> */}
                     {accessToken && expiresIn ? <button type="button" className="save-me me-3" style={{ backgroundColor: 'green' }} onClick={handleAuthClick}>Google Calendar Synced</button> : <button type="button" className="save-me me-3" style={{ backgroundColor: 'red' }} onClick={handleAuthClick}>Sync Google Calendar</button>}
                     <button type="button" className="save-me me-3" onClick={addNewAppointment}>Save</button>
                     <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <img src={CloseIcon} />
                     </button>
                  </div>
               </div>
               <div className="modal-body bg-white">
                  <div className="event-form">
                     <div className="row">
                        <div className="col-md-9 mb-4 mb-md-0">
                           <div className="mb-2">
                              <input type="text" className="form-control" placeholder="New Event Name" name='task_name' value={appointmentsData?.task_name} onChange={(e) => handleChange(e)} required />
                           </div>
                           <div className="d-flex align-items-center mb-2 justify-content-between mobile-align">
                              <div className="when-event">
                                 <div className="event-time">
                                    <div className="event-start-time">
                                       <DatePicker
                                          selected={selectedDate}
                                          onChange={handleDateChange}
                                          dateFormat="MMMM d, yyyy"
                                          className="date-picker"
                                          value={formattedDate}
                                       />
                                    </div>
                                 </div>
                                 <div className='mobile-newappoitment'>
                                    <div className="custom-time-input">
                                    <input type="time" className="time-input" value={appointmentFromTime}
                                       onChange={handleFromTimeChange} />
                                 </div>
                                 <span className='span-mv'>-</span>
                                 <div className="custom-time-input">
                                    <input type="time" className="time-input" value={appointmentToTime} onChange={handleToTimeChange} />
                                 </div>
                                 </div>
                                 {/* <span>UTC Time</span> */}
                                 {/* <div className="time-zone">(GMT +5:30) IST</div> */}
                              </div>

                              <div className="event-days d-flex align-items-center">
                                 <div className="all-days me-2 form-check d-flex align-items-center">
                                    <label className="form-check-label mb-0" for="allDay">
                                       Recurring Event
                                    </label>
                                 </div>
                                 <div className="dropdown recurring-event">
                                    <Select
                                       options={recurringOptions}
                                       onChange={(e) => handleRecurringDays(e)}
                                       name='meeting_days'
                                       value={recurringDays}
                                       required
                                    />
                                 </div>
                              </div>
                           </div>
                           <span className='time-zone-info'>**Meeting time is in your local time.</span>
                           <div className="mb-2">
                              <label>Meeting Link</label>
                              <div className="meeting-link">
                                 <input type="text" placeholder="Meeting Link" className="form-control" name='event_meeting_link' value={appointmentsData?.event_meeting_link} onChange={(e) => handleChange(e)} />
                                 {/* 
                                 <button type="button" className="no-default"><img src={CopyIcon} /></button>
                                 */}
                              </div>
                           </div>
                           <div className="mb-0">
                              <textarea className="form-control" placeholder="Add Description" rows="5" name='event_description' value={appointmentsData?.event_description} onChange={(e) => handleChange(e)}></textarea>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="event-assignee">
                              <div className="event-users">
                                 <form>
                                    <input type="text" onChange={(e) => setSearchValue(e.target.value)} name="searchusers" placeholder="Find Users..." className="form-control" />
                                    <button type="submit"><img src={SearchIcon} /></button>
                                 </form>
                              </div>
                              <ul className="m-0 list-unstyled">
                                 {filteredData && filteredData.map((user) => (<li className={selectedUsers.includes(user) ? "active" : ""} key={user.id}><a className="dropdown-item" href="#" onClick={() => handleUserSelection(user)}><span className="short-event-an">{user.first_name[0]}</span>{user.first_name} {user.last_name}</a></li>
                                 ))}  </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default NewAppointmentModal
