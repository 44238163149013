import React from 'react'
import MainIndexPage from './index';
import './main.css';
import { useNavigate } from 'react-router-dom';
import HeaderPage from './header';
import FooterPage from './footer';



const HomePageIndex = () => {
    const navigate = useNavigate()

    return (
        <div>
            <HeaderPage />
            <MainIndexPage />
            <FooterPage />
        </div>
    )
}

export default HomePageIndex;
