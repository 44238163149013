import React, { useState } from 'react'
import "./analytics.css"
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import AnalyticsIcon from '../../assests/images/analytics.svg';
import ReportsIcon from '../../assests/images/reports.svg';
import GraphAnalytics from './graphAnalytics';
import ReportsPage from './reports';
import { updateAnalyticGraphPopup, updateupcomingHeadline } from '../../redux/viewmore';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../common/helper';
import PlusIcon from '../../assests/icons/plusg.svg';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateOpenInvoiceFromTimeLogpopup, updatopenPayPalsetup } from '../../redux/invoice';

const AnalyticsPage = ({flag, setGraphReportsProps}) => {
    const [activeTab, setActiveTab] = useState('analytics');
    const dispatch = useDispatch()
    const paypalSetupData = useSelector((state) => state.invoice.paypalSetupData);

    const handleOpenInvoice = () => {
        if (isEmpty(paypalSetupData)) {
            toastr.info('Please do paypal credentials setup')
            dispatch(updatopenPayPalsetup(true))
            return
        }
        dispatch(updateOpenInvoiceFromTimeLogpopup(true))
    }

    return (
        <div style={{width: "-webkit-fill-available"}}>
            <section class="ana-rep mb-4">
                <div 
                // class="container-fluid" updateOpenInvoiceFromTimeLogpopup
                >
                    <div class={flag ? "bg-white shadow reports-inner-viewmore" : "bg-white shadow reports-inner"}>
                        <div class="analytics-info d-flex align-items-center justify-content-between">
                            <ul class="nav" id="pills-tab" role="tablist">
                                <li onClick={() => setActiveTab('analytics')} class={`nav-item ${activeTab === 'analytics' ? 'active' : ''}`} role="presentation">
                                    <button class={`nav-link ${activeTab === 'analytics' ? 'active' : ''}`} id="pills-analytics-tab" data-bs-toggle="pill" data-bs-target="#pills-analytics" type="button" role="tab" aria-controls="pills-analytics" aria-selected="true"><img src={AnalyticsIcon} class="me-2"
                                     />Graph</button>
                                </li>
                                <li class={`nav-item ${activeTab === 'reports' ? 'active' : ''}`} role="presentation">
                                    <button onClick={() => setActiveTab('reports')}  class="nav-link"  data-bs-toggle="pill" data-bs-target="#pills-reports" type="button" role="tab" aria-controls="pills-reports" aria-selected="false"><img src={ReportsIcon} class="me-2" />Table</button>
                                </li>
                            </ul>
                            <div class="d-flex align-items-center chat-btn">
                            <button onClick={() => handleOpenInvoice()} type="button" class="no-default" data-bs-target="#newInvoiceModal"><img src={PlusIcon} /></button>
                                {!flag && 
                                <> {window.screen.width > 760 && <button 
                                onClick={() => {
                                    dispatch(updateAnalyticGraphPopup(true));
                                    dispatch(updateupcomingHeadline("Reports"))}} 
                                type="button" class="add-icon  expand-button"><img src={ExpandIcon} /></button>}
                                    <button type="button" class="no-default drag-me"><img src={DragIcon} /></button></>
                                     } 
                            </div>
                        </div>
                        <div class="tab-content" id="pills-tabContent">
                            <div class={`tab-pane fade ${activeTab === 'analytics' ? 'show active' : ''}`} id="pills-analytics" role="tabpanel" aria-labelledby="pills-analytics-tab" tabindex="0">
                               <GraphAnalytics flag={flag}/>
                            </div>
                            <div class={`tab-pane fade ${activeTab === 'reports' ? 'show active' : ''}`} id="pills-reports" role="tabpanel" aria-labelledby="pills-reports-tab" tabindex="0">
                              <ReportsPage flag={flag} activeTab={activeTab} />
                            </div>
                        </div>
                    </div>
                    {window.screen.width > 760 && !flag && <button onClick={() => {
                    dispatch(updateAnalyticGraphPopup(true));
                    dispatch(updateupcomingHeadline("Reports"));
                }} type="button" class="view-more">View More</button>}
                </div>
            </section>
        </div>
    )
}

export default AnalyticsPage
