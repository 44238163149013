import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assests/images/close.svg";
import CloseGreyIcon from "../../assests/images/close-grey.svg";
import TickWIcon from "../../assests/images/tick-white.svg";
import UserIcon from "../../assests/images/user.svg";
import SearchIcon from "../../assests/images/search.svg";
import PlusIcon from "../../assests/images/plus-icon.svg";
import Select from "react-select";
import task, {
  getTaskStatusData,
  updateRefreshTaskList,
  updateShowTasksList,
  updateTasksList,
} from "../../redux/task";
import { useDispatch, useSelector } from "react-redux";
import apiServiceHandler from "../../service/apiService";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshProjectList } from "../../redux/project";

const AddNewTask = ({ isOpen, onClose, selectedProject, clientName, waitingForApproval }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openUsersDropdown, setOpenUsersDropdown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenPriority, setIsDropdownOpenPriority] = useState(false);
  const usersList = useSelector((state) => state.allUserData.users);
  const [searchText, setSearchText] = useState("");
  const taskRef = useRef(null);
  const [selectedAssignedUsers, setSelectedAssignedUsers] = useState([]);
  const [selectedVisibleUsers, setSelectedVisibleUsers] = useState([]);
  const [allAssignedUsersList, setAllAssignedUsersList] = useState([]);
  const [allVisibleUsersList, setAllVisibleUsersList] = useState([]);
  const [priorityTask, setPriorityTask] = useState("Low");
  const [taskBelongsTo, SetTaskBelongsTo] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentStatus, setCurrentStatus] = useState([]);
  const projects = useSelector((state) => state.project?.showProjectList) || [];
  const projectsData =
    useSelector((state) => state.project?.projectsData) || [];
  const [taskData, setTaskData] = useState({});
  const dispatch = useDispatch();
  let userData = localStorage.getItem("userData");
  userData = userData ? JSON.parse(userData) : {};

  const FolderSpaces = useSelector(state => state.clickup?.clickupJLLProjectsList) || []
  const activeProject = useSelector((state) => state.project?.projectsData[0]) || ""

  const isActiveProjectInClickup = Object.keys(FolderSpaces).some(projectName =>
    projectName === activeProject?.project_name
  );

  const clickupUsersList = JSON.parse(localStorage.getItem("Clickup_Users"));

  const [chatMessages, setChatMessages] = useState([]);
  const [commentText, setCommentText] = useState("");

  let UniqueUrl = userData?.unique_url

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
    { value: "Under Review", label: "Under Review" },
    { value: "Approved", label: "Approved" },
    { value: "Delayed By Client", label: "Delayed By Client" },
  ];
  const clickupOptions = [
    { value: "to do", label: "To Do" },
    { value: "complete", label: "Complete" },
    // { value: "in progress", label: "In Progress" },
  ];
  const clientOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
  ];

  const priorityOptions = [
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
  ];

  useEffect(() => {
    // Set initial selected users from Redux when component mounts
    setAllAssignedUsersList(usersList.filter((user) => user.status === 1));
    setAllVisibleUsersList(usersList.filter((user) => user.status === 1));
  }, [usersList]);

  const toggleDropdown = (e, dropdownNumber) => {
    // setOpenUsersDropdown(!openUsersDropdown);
    e.preventDefault();
    switch (dropdownNumber) {
      case 1:
        setOpenUsersDropdown(!openUsersDropdown);
        break;
      case 2:
        setIsDropdownOpenPriority(!isDropdownOpenPriority);
        break;
      default:
        break;
    }
  };

  const projectData = useSelector((state) => state.project.projectsData[0]);

  const updateProject = async () => {
    const updatedMilestones = JSON.parse(projectData?.milestone)
    updatedMilestones.map((data) => {
      if (data.milestone_name == selectedMilestone.value) {
        return {
          ...data,
          status: currentStatus.value,
          selected_milestone: selectedMilestone.value,
        };
      }

      return data
    });

    let payload = new FormData();

    projectData?.client_id &&
      projectData?.client_id.map((val) => {
        payload.append("client_id[]", val);
      });

    projectData?.project_user &&
      projectData?.project_user.map((val) => {
        payload.append("project_user[]", val);
      });

    payload.append("project_name", projectData?.project_name);
    payload.append("start_date", projectData?.start_date);
    // payload.append("end_date", projectData?.end_date);
    payload.append("project_url", projectData?.project_url);
    payload.append("milestone", JSON.stringify(updatedMilestones));
    // payload.append("logo", projectData?.logo ? projectData?.logo : "empty");
    payload.append("status", projectData?.status);
    payload.append("extended_time", projectData?.extended_time);
    payload.append("id", projectData._id);
    payload.append("progress", projectData?.progress);

    try {
      const response = await apiServiceHandler(
        "POST",
        "api/project/update",
        payload
      );
      // toastr.success(response?.message);
      dispatch(updateRefreshProjectList(true));
      onClose();
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  const addNewTask = async () => {
    if (selectedAssignedUsers.length === 0) {
      toastr.error("Please assign the task to a user.");
      return;
    }
    let payload = new FormData();
    payload.append("task_name", taskData?.task_name);
    payload.append("task_details", taskData?.task_details);
    payload.append("priority", priorityTask);
    payload.append("due_date", taskData?.due_date);
    payload.append("dependent_task", taskData?.dependent_task);
    payload.append("status", currentStatus.value);
    payload.append("file", selectedImage ? selectedImage : "empty");
    payload.append("project_id", projects[0]?._id);
    payload.append("selected_milestone", selectedMilestone?.value)

    selectedAssignedUsers &&
      selectedAssignedUsers.map((user) => {
        payload.append("assigned_to[]", user._id);
      });

    selectedVisibleUsers &&
      selectedVisibleUsers.map((user) => {
        payload.append("visible_to[]", user._id);
      });

    if (waitingForApproval) {
      payload.append("task_status[]", "waiting_for_approval")
    }
    try {
      const response = await apiServiceHandler("POST", "api/task", payload);
      updateProject();
      dispatch(updateRefreshTaskList(true));
      onClose();
      toastr.success("Created the Task");
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  const [statusOption, setStatusOption] = useState([]);
  const [milestones, setMilestones] = useState();

  useEffect(() => {
    const milestoneData = JSON.parse(projectsData[0].milestone);

    const taskMilestones = [];

    milestoneData.map((milestone) =>
      taskMilestones.push({
        label: milestone?.milestone_name,
        value: milestone?.milestone_name,
      })
    );
    setMilestones(taskMilestones);
  }, [isOpen]);



  const getTaskStatus = async () => {
    try {
      const response = await apiServiceHandler(
        "GET",
        "api/task/status/list",
        {}
      );
      let data = response?.data || [];
      let list = [];
      Object.values(data).map((value, i) =>
        list.push({
          value: value,
          label: value
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        })
      );
      setStatusOption(list);
      dispatch(getTaskStatusData(list));
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };
  const handleChangeStatus = (selectedValues) => {
    SetTaskBelongsTo(selectedValues);
  };
  const handleSelectedUsers = (selectedValues) => {
    setSelectedAssignedUsers(selectedValues);
  };
  useEffect(() => {
    getTaskStatus();
  }, []);

  const [boolDelayed, setBoolDelayed] = useState(false)
  const statusTaskList = useSelector((state) => state.task.statusTaskList)

  useEffect(() => {
    if (boolDelayed) {
      setStatusOption(statusOption.filter((item) => item.value !== "not_visible_to_client"))
    } else {
      setStatusOption(statusTaskList)
    }
  }, [boolDelayed])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const removeAssignedUser = (user) => {
    let data = selectedAssignedUsers.filter((val) => val._id !== user._id);
    setSelectedAssignedUsers(data);
    let newArray = [...allAssignedUsersList];
    newArray.push(user);
    setAllAssignedUsersList(newArray);
  };
  const removeVisibleUser = (user) => {
    let data = selectedVisibleUsers.filter((val) => val._id !== user._id);
    setSelectedVisibleUsers(data);
    let newArray = [...allVisibleUsersList];
    newArray.push(user);
    setAllVisibleUsersList(newArray);
  };

  const handleAssignedUserSelection = (e, user) => {
    e.preventDefault();
    const newArray = [...selectedAssignedUsers, user];
    setSelectedAssignedUsers(newArray);
    let data = allAssignedUsersList.filter((val) => val._id !== user._id);
    setAllAssignedUsersList(data);
    // document.addEventListener('mousedown', handleOutsideClick);
  };

  const handleVisibleUserSelection = (e, user) => {
    e.preventDefault();
    const newArray = [...selectedVisibleUsers, user];
    setSelectedVisibleUsers(newArray);
    let data = allVisibleUsersList.filter((val) => val._id !== user._id);
    setAllVisibleUsersList(data);
    // document.addEventListener('mousedown', handleOutsideClick);
  };

  const [clickupSelectedUsers, setClickupSelectedUsers] = useState([]);
  const [clickupAllUsersList, setClickupAllUsersList] = useState([]);

  useEffect(() => {
    if (clickupAllUsersList.length == 0) {
      setClickupAllUsersList(clickupUsersList)
    }
  }, [clickupUsersList])


  const handleClickupUserSelection = (e, user) => {
    e.preventDefault();
    const newArray = [...clickupSelectedUsers, user];
    setClickupSelectedUsers(newArray);
    let data = clickupAllUsersList.filter((val) => val.id !== user.id);
    setClickupAllUsersList(data);
    // document.addEventListener('mousedown', handleOutsideClick);
  };

  const removeClickupUser = (user) => {
    let data = clickupSelectedUsers.filter((val) => val.id !== user.id);
    setClickupSelectedUsers(data);
    let newArray = [...clickupAllUsersList];
    newArray.push(user);
    setClickupAllUsersList(newArray);
  };

  const filteredUsers = usersList.filter((user) =>
    user.first_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file

    setSelectedImage(file);
  };

  const handleOutsideClick = (event) => {
    if (taskRef.current && !taskRef.current.contains(event.target)) {
      setIsDropdownOpenPriority(false);
      // setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handlePrioritySelection = (e, priority) => {
    e.preventDefault();
    setPriorityTask(priority);

    // setIsDropdownOpenPriority(false); // Close the dropdown after selection
  };
  const handleStatus = (status) => {
    setCurrentStatus(status);
    status.value == "Delayed By Client" ? setBoolDelayed(true) : setBoolDelayed(false)
    // setOpenDropdown(false)
  };

  // const handleAddTask = () => {
  //     addNewTask()

  // }

  const [selectedMilestone, setSelectedMilestone] = useState([]);

  const handleSelectMilestone = (milestone) => {
    // console.log(milestone.value, "milestone");
    setSelectedMilestone(milestone);
  };
  const [showMilestoneSelect, setShowMilestoneSelect] = useState(true); // Initial state

  const handleCheckboxChange = (event) => {
    try {
      // Update state only if event target is a checkbox
      if (event.target.type === 'checkbox') {
        setShowMilestoneSelect(!event.target.checked);
      } else {
        console.error('Unexpected event type for checkbox:', event.target.type);
      }
    } catch (error) {
      console.error('Error handling checkbox change:', error);
    }
  };

  const addClickupNewTask = async () => {
    if (clickupSelectedUsers.length === 0) {
      toastr.error("Please assign the task to a user.");
      return;
    }
    let payload = {};
    let assignees = []
    clickupSelectedUsers &&
      clickupSelectedUsers.map((user) => {
        assignees.push(user.id);
      });
    let priority = 4
    if (priorityTask == "Low") priority = 4
    if (priorityTask == "Medium") priority = 3
    if (priorityTask == "High") priority = 2
    let data =
    {
      name: taskData?.task_name,
      description: taskData?.task_details,
      assignees: assignees,
      due_date: new Date(taskData?.due_date).getTime(),
      due_date_time: false,
      start_date_time: false,
      notify_all: true,
      parent: null,
      links_to: null,
      check_required_custom_fields: true,
      status: currentStatus.value,
      priority: priority,

      dependent_task: taskData?.dependent_task

    }

    // payload.append("task_name", taskData?.task_name);
    // payload.append("task_details", taskData?.task_details);
    // payload.append("priority", priorityTask.value);
    // payload.append("due_date", taskData?.due_date);
    // payload.append("dependent_task", taskData?.dependent_task);
    // payload.append("status", currentStatus.value);
    // payload.append("file", selectedImage ? selectedImage : "empty");
    // payload.append("project_id", projects[0]?._id);
    payload["data"] = data;
    payload["folderId"] = FolderSpaces[projectData?.project_name];
    payload["uniqueUrl"] = UniqueUrl;

    let typeArr = []
    if (waitingForApproval) {
      typeArr.push("waiting_for_approval")
    }
    else {
      typeArr.push("all_task_of_project")
    }

    payload["type"] = typeArr
    try {
      const response = await apiServiceHandler("POST", "api/tasks/create_task_clickup", payload);
      dispatch(updateRefreshTaskList(true));
      onClose();
      toastr.success("Created the Task");
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  return (
    <div
      className={`modal fade grey-popup add-task-viewmore ${isOpen ? "show backdrop" : " "
        }`}
      id="newtaskModal"
      tabindex="-1"
      aria-labelledby="taskModalLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 new-task-content">
          <div className="modal-header new-task-header">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="#">{clientName}</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">{selectedProject}</a>
                </li>
                {/* <li className="breadcrumb-item active" aria-current="page">Jurni App</li> */}
              </ol>
            </nav>
            <div className="mark-done d-flex align-items-center mark-mobile">
              {/* <button type="button" className="mark-tick me-3"><img src={TickWIcon} />Mark Complete</button> */}
              {isActiveProjectInClickup && (<label htmlFor="showMilestone" className="me-3 d-flex">
                <input
                  type="checkbox"
                  id="showMilestone"
                  className="me-2"
                  checked={!showMilestoneSelect}
                  onChange={handleCheckboxChange}
                />
                Clickup
              </label>)}
              {isActiveProjectInClickup && (<label htmlFor="showMilestone" className="me-2 d-flex">
                <input
                  type="checkbox"
                  id="showMilestone"
                  className="me-2"
                  checked={!showMilestoneSelect}
                  onChange={handleCheckboxChange}
                />
                Jira
              </label>)}
              {!showMilestoneSelect ? (<button
                type="button"
                className="mark-tick me-3"
                onClick={addClickupNewTask}
              >
                Create
              </button>) :
                (<button
                  type="button"
                  className="mark-tick me-3"
                  onClick={addNewTask}
                >
                  Create
                </button>)}
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img className="img-new" src={CloseIcon} />
              </button>
            </div>
          </div>
          <div className="modal-body pt-0">
            <div className="task-head d-flex align-items-center justify-content-between">
              <div className="task-head-left d-flex align-items-center">
                {/* <button type="button" className="pending-tsk me-3">Pending</button> */}
                {window.screen.width > 760 && <><div className="task-status-label">Status:</div>
                <span>&nbsp;</span></>}
                <div className="new-task-mobile"
                //  style={window.screen.width > 760 ? { width: "150px" } : {}}
                 >
                  <Select
                    className={window.screen.width > 760 ? "me-3" : ""}
                    options={!showMilestoneSelect ? clickupOptions : userData.role === "client" ? clientOptions : options}
                    name="status"
                    value={currentStatus}
                    onChange={(e) => handleStatus(e)}
                    placeholder="Status..."
                  />
                </div>
                {/* {window.screen.width > 760 && <><div className="task-status-label">Milestone:</div><span>&nbsp;</span></>} */}
                <div className="new-task-mobile"
                  style={window.screen.width > 760 ? { width: "200px" } : {}}
                >
                  {showMilestoneSelect && (
                    <Select
                      className="btn-color mx-md-3 mt-2 mt-md-0"
                      options={milestones ? milestones : []}
                      name="selected_milestone"
                      value={selectedMilestone}
                      onChange={(e) => handleSelectMilestone(e)}
                      placeholder="Milestone..."
                    />)}
                </div>
              </div>
              <div className="task-head-right d-flex align-items-center">
                <div className="task-assignto-label">Assigned To:</div>
                {showMilestoneSelect ? (<>
                  <div className="assignee-icon dropdown me-md-4">
                    <div
                      className="dropdown-toggle"
                      onClick={(e) => toggleDropdown(e, 1)}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="border-assignee">
                        <img src={UserIcon} />
                        <button type="submit" style={{ display: "none" }}>
                          <div className="user-icon">
                            <img src={CloseGreyIcon} />
                          </div>
                        </button>
                      </div>
                      {selectedAssignedUsers.map((user) => (
                        <div
                          className="border-assignee"
                          style={{ background: "#57A5FB", color: "#FFF" }}
                          key={user._id}
                        >
                          {user.first_name[0] ? (
                            user.first_name[0]
                          ) : (
                            <img src={UserIcon} />
                          )}
                          <button type="submit">
                            <div className="user-remove">
                              <img
                                onClick={() => removeAssignedUser(user)}
                                src={CloseGreyIcon}
                                alt={`Remove ${user.first_name}`}
                              />
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                    <ul
                      className="dropdown-menu"
                    >
                      {allAssignedUsersList &&
                        allAssignedUsersList.map((user) => (
                          <li key={user.id}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => handleAssignedUserSelection(e, user)}
                            >
                              <span className="short-an">
                                {user.first_name[0]}
                              </span>{" "}
                              {user.first_name} {user.last_name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div></>)
                  :
                  (<><div className="assignee-icon dropdown me-4">
                    <div
                      className="dropdown-toggle"
                      onClick={(e) => toggleDropdown(e, 1)}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="border-assignee">
                        <img src={UserIcon} />
                        <button type="submit" style={{ display: "none" }}>
                          <div className="user-icon">
                            <img src={CloseGreyIcon} />
                          </div>
                        </button>
                      </div>
                      {clickupSelectedUsers.map((user) => (
                        <div
                          className="border-assignee"
                          style={{ background: "#57A5FB", color: "#FFF" }}
                          key={user.id}
                        >
                          {user.username[0] ? (
                            user.initials
                          ) : (
                            <img src={UserIcon} />
                          )}
                          <button type="submit">
                            <div className="user-remove">
                              <img
                                onClick={() => removeClickupUser(user)}
                                src={CloseGreyIcon}
                                alt={`Remove ${user.username}`}
                              />
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                    <ul
                      className="dropdown-menu clickup-users"
                    >
                      {clickupAllUsersList &&
                        clickupAllUsersList.map((user) => (
                          <li key={user.id}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => handleClickupUserSelection(e, user)}
                            >
                              <span className="short-an">
                                {user.initials}
                              </span>{" "}
                              {user.username}
                              {/* {user.first_name user.last_name[0][1]}  */}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  </>)}
              </div>
              {showMilestoneSelect && (<><div className="task-head-right d-flex align-items-center">

                <div className="task-assignto-label">Visible To:</div>

                <div className="assignee-icon dropdown me-md-4">
                  <div
                    className="dropdown-toggle"
                    onClick={(e) => toggleDropdown(e, 1)}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div class="border-assignee">
                      <img src={UserIcon} />
                      <button type="submit" style={{ display: "none" }}>
                        <div className="user-icon">
                          <img src={CloseGreyIcon} />
                        </div>
                      </button>
                    </div>
                    {selectedVisibleUsers.map((user) => (
                      <div
                        className="border-assignee"
                        style={{ background: "#57A5FB", color: "#FFF" }}
                        key={user._id}
                      >
                        {user.first_name[0] ? (
                          user.first_name[0]
                        ) : (
                          <img src={UserIcon} />
                        )}
                        <button type="submit">
                          <div className="user-remove">
                            <img
                              onClick={() => removeVisibleUser(user)}
                              src={CloseGreyIcon}
                              alt={`Remove ${user.first_name}`}
                            />
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                  <ul
                    //ref={taskRef}
                    className="dropdown-menu">

                    {allVisibleUsersList &&
                      allVisibleUsersList.map((user) => (
                        <li key={user._id}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => handleVisibleUserSelection(e, user)}
                          >
                            <span className="short-an">
                              {user.first_name[0]}
                            </span>{" "}
                            {user.first_name} {user.last_name}
                            {/* {user.first_name user.last_name[0][1]}  */}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>


              </div></>)}
            </div>
            <div className="task-body bg-white mb-0">
              <div className="mb-2">
                <div className="col-md-12 mb-2">
                  <input
                    type="text"
                    name="task_name"
                    placeholder="task name"
                    className="form-control"
                    value={taskData?.task_name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <textarea
                  className="form-control"
                  placeholder="Task Details"
                  rows="5"
                  name="task_details"
                  value={taskData?.task_details}
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
              <div className="mb-4">
                <ul className="list-unstyled task-ul m-0">
                  <li>
                    <div className="task-label">Due Date:</div>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="due date"
                      name="due_date"
                      value={taskData?.due_date}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </li>

                  <li>
                    <div className="task-label">Priority:</div>
                    <div className="task-info task-pt">
                      <div
                        // ref={taskRef}
                        className="btn-group"
                      >
                        {/* <Select
                                                    className=""
                                                    options={priorityOptions}
                                                    name='priority_task'
                                                    
                                                    value={priorityTask}
                                                    onChange={(e) => handlePrioritySelection(e)}
                                                /> */}
                        <button
                          type="button"
                          className="btn btn-sm btn-color"
                          style={{ background: "#3EBB11" }}
                        >
                          {priorityTask}
                        </button>
                        <button
                          type="button"
                          onClick={(e) => toggleDropdown(e, 2)}
                          className="btn dropdown-toggle dropdown-toggle-split btn-sm"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu"
                        // className={`dropdown-menu ${isDropdownOpenPriority ? "show" : ""}`}
                        // style={{ display: isDropdownOpenPriority ? "block" : "none" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={(e) => handlePrioritySelection(e, "Low")}
                              href="#"
                            >
                              Low
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={(e) => handlePrioritySelection(e, "Medium")}
                              href="#"
                            >
                              Medium
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={(e) => handlePrioritySelection(e, "High")}
                              href="#"
                            >
                              High
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                <label>Sub Tasks</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Sub Task Name"
                  name="dependent_task"
                  value={taskData?.dependent_task}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              {/* <div className="mb-4">
                                <label>Sub Tasks</label>
                                <input type="text" className="form-control" placeholder="Enter Your Task Name" required />
                            </div> */}
              {/* <div className="upload-file-project text-center">
                                        <div>
                                        <input type='file'  onChange={(e) => handleImageChange(e)} />
                                            
                                            <div className="upload-txt-project">Drag file here or <label for="projectFile">upload from your computer</label></div>
                                            <p className="mb-0">JPG, PNG, PDF up to 5MB</p>
                                        </div>
                                    </div> */}
              {showMilestoneSelect && <div class="mb-3">
              <label>Upload Image:</label>
              <div className="mb-4 attachments-area">
                {selectedImage && (
                  <div className="attachment-task">
                    <div className="attachment-image">
                      <img src={URL.createObjectURL(selectedImage)} />
                    </div>
                  </div>
                )}
                {/* <button type="button" className="no-default add-more-attach"> */}
                  <div className="upload-file-project image-dash text-center">
                    {/* <label htmlFor="taskFile" className="upload-txt-project">
                      <img className="m-3" src={PlusIcon} alt="Upload" />
                    </label> */}
                    <input
                      onChange={handleImageChange}
                      className="box-file-hidded"
                      type="file"
                      id="taskFile"
                    // accept="image/*" // Specify that only image files are allowed
                    />
                    <div className="upload-txt-project">
                        <label htmlFor="taskFile">
                        <img className="m-3" src={PlusIcon} alt="Upload" />
                        </label>
                      </div>
                  </div>
                {/* </button> */}
                </div>
               
              </div>}
              {/* <div className="task-created mb-4 chat-bg">
                                <ul className="m-0 list-unstyled">
                                    <li className="task-created-inner">
                                        <span className="short-label" style={{ background: '#F13A3A' }}>JR</span>
                                        <p className="full-label mb-0">Created this task</p>
                                        <div className="dropdown edit-chat">
                                            <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ChatsdotsIcon} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Reply</a></li>
                                                <li><a className="dropdown-item" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="task-created-inner">
                                        <span className="short-label" style={{ background: '#F13A3A' }}>DT</span>
                                        <p className="full-label mb-0">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                                        <div className="dropdown edit-chat">
                                            <button type="button" className="no-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ChatsdotsIcon} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Reply</a></li>
                                                <li><a className="dropdown-item" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="task-created-inner">
                                        <span className="short-label" style={{ background: '#34A853' }}>RK</span>
                                        <p className="full-label mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                                        <div className="dropdown edit-chat">
                                            <button type="button" className="no-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ChatsdotsIcon} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Reply</a></li>
                                                <li><a className="dropdown-item" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="task-created-inner">
                                        <span className="short-label" style={{ background: '#F13A3A' }}>DT</span>
                                        <p className="full-label mb-0">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                                        <div className="dropdown edit-chat">
                                            <button type="button" className="no-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ChatsdotsIcon} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Reply</a></li>
                                                <li><a className="dropdown-item" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <div className="write-task-chat reply-to">
                                        <div className="task-chat-inner">
                                            <input type="text" className="form-control" placeholder="Type Comment..." />
                                            <button type="button" className="task-chat-attachment"><img src={AttachGreyIcon} /></button>
                                        </div>
                                        <button type="button" className="send-chat"><img src={SendIcon} /></button>
                                    </div>
                                    <li className="task-created-inner">
                                        <span className="short-label" style={{ background: '#34A853' }}>RK</span>
                                        <p className="full-label mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                                        <div className="dropdown edit-chat">
                                            <button type="button" className="no-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={ChatsdotsIcon} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Edit</a></li>
                                                <li><a className="dropdown-item" href="#">Reply</a></li>
                                                <li><a className="dropdown-item" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
              {/* <div className="chat-area">
                                <div className="write-chat">
                                    <div className="chat-inner">
                                        <input type="text" className="form-control" placeholder="Type Comment..." value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                                        <button type="button" className="chat-attachment"><img src={AttachGreyIcon} /></button>
                                    </div>
                                    <button type="button" className="send-chat" onClick={addComment} ><img src={SendIcon} /></button>
                                </div>
                            </div> */}
            </div>
          </div>
          {/* <!-- <div className="modal-footer justify-content-center">
            <button type="submit" className="bg-btn">View Progress</button>
            </div> --> */}
        </div>
      </div>
    </div>
  );
};

export default AddNewTask;
