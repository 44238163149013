import React, { useEffect, useState } from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { updatePrivacyPolicyFlag } from '../../redux/invoice';
import { useDispatch, useSelector } from 'react-redux';

const PrivacyPolicy = () => {
    const privacyPolicyFlag = useSelector((state) => state.invoice.privacyPolicyFlag);
    const dispatch = useDispatch()

    return (
        <div>
            <div
                className={`modal fade edit-profile edit-task-viewmore ${privacyPolicyFlag ? "show backdrop" : " "}`}
                id="editProfileModal"
                tabIndex="-1"
                aria-labelledby="editProfileModalLabel"
                aria-hidden="true"
                style={{ display: privacyPolicyFlag ? "block" : "none" }}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content border-0 ">
                        <div className="modal-header">
                            <h2 className="modal-title">Privacy Policy</h2>
                            <div style={{ display: "flex" }}>

                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(updatePrivacyPolicyFlag(false))}>
                                    <img src={CloseIcon} />
                                </button>
                            </div>

                        </div>

                        <div className="modal-body">
                            <p>At JoinLiveLook, we are committed to protecting your privacy. This privacy policy explains our information collection, use, and disclosure practices for our website and any services we provide.
                            </p>
                                <h3>Information Collection:</h3>

                                <p>When you visit our website, we may collect information about your device and browsing actions. This information may include your IP address, browser type, device type, location, and the pages you visit on our website. We may also collect information that you voluntarily provide, such as your name, email address, phone number, and shipping and billing addresses.
                                </p>
                                <h3>Use of Information:</h3>

                                <p>We may use the information we collect to personalize your experience on our website, improve our website, and provide you with information or services that you request. We may also use this information to communicate with you about our products and services, and to send you marketing materials.
                                </p>
                                <h3>Disclosure of Information:</h3>

                                <p>We may disclose your information to third-party service providers who assist us in operating our website, conducting our business, or providing you with products or services. These third parties are contractually obligated to keep your information confidential and use it only for the purposes for which it was disclosed.

                                We may also disclose your information as required by law, such as to comply with a subpoena or other legal process, or to protect the safety, rights, or property of JoinLiveLook, our customers, or others.
                                </p>
                                <h3>Security:</h3>

                                <p>We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of personal information.
                                </p>
                                <h3>Changes to Privacy Policy:</h3>

                                <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.
                                </p>
                                <h3>Contact Us:</h3>

                                <p>If you have any questions about this privacy policy, please contact us at info@joinlivelook.com

                                This privacy policy is effective as of 3rd April, 2024.</p>

                        </div>

                        {/* <div className="modal-footer justify-content-center">
                            <button type="submit" className="bg-btn" onClick={handleSave}>Save</button>
                        </div> */}

                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy;
