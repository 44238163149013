import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshReportsList } from '../../redux/reportsandAnalytics';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { eachDayOfInterval, format, subDays, subMonths } from 'date-fns';
import apiServiceHandler from '../../service/apiService';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Select from 'react-select'
import { updateSelectedDateRange, updateTotalTimeLogData } from '../../redux/invoice';

// Register the required components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  
const GraphAnalytics = ({flag}) => {
    
    const [graphData,setGraphData] = useState({})

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);


    const dispatch = useDispatch()
    const usersList = useSelector(state => state.allUserData.users)
    const projects = useSelector(state => state.project?.showProjectList) || []

    const [flagUsers, setFlagUsers] = useState(false)

    const userIds = usersList.map((item) => {
        return item._id

    })

    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState([currentDate]);

    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate);
    const formattedCurrentDate = currentDate.toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
    });
  
    const handleDateChange = async (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
  
      // Generate the array of dates
      const dateArray = eachDayOfInterval({ start, end });
      setFlagUsers(true)
      setSelectedDate(dateArray);
    };

    const transformData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.first_name} ${item.last_name}`
        }));
    }
    const userOptions = transformData(usersList) || [];

    const transformProjectData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.project_name}`
        }));
    }
    const projectOptions = transformProjectData(projects) || [];

    const handleFilterUsers = (selectedValue) => {

        setSelectedUsers(selectedValue);
        setFlagUsers(true)
    }
    const handleFilterProjects = (selectedValue) => {
        setSelectedProjects(selectedValue);
        setFlagUsers(true)
    }


    useEffect(() => {
        if (flagUsers) {
            handleReportsData()
            setFlagUsers(false)
        }
    }, [flagUsers])


    const fetchUserName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        const user = usersList.find((user) => user._id === userId);
        if (user) {
            const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
            const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
            return `${firstName} ${lastName}`;
        }


        return 'Unknown';
    };


    const handleReportsData = async () => {
        let payload = {
            filterDate: selectedDate.map((date) => {return format(date, 'yyyy-MM-dd')})
            ,
            projectArray: [],
            users: [],
        };

        let usersIdSelect = []
        selectedUsers.map((val) => usersIdSelect.push(val._id))

        payload.users = usersIdSelect.length > 0 ? usersIdSelect : userIds

        let projectusersList = []
        selectedProjects.map((val) => projectusersList.push(val))
        payload.projectArray = projectusersList.length > 0 ? projectusersList : projects
        try {
            const response = await apiServiceHandler("POST", "api/project/get_time_tracking_chart", payload)
            setGraphData(response)
            dispatch(updateRefreshReportsList())
            dispatch(updateTotalTimeLogData(response))
        } catch (error) {
            // toastr.error(error?.response?.data?.message)
            console.log(error)
        }
    }

    useEffect(() => {
        handleReportsData()
    }, [usersList, selectedDate])

    
    const labels = Object.keys(graphData);
    let datasets = projects.map((p) => {
        return {
            label: p.project_name,
            data: labels.map(label => {
                const timeString = graphData && graphData[label] && graphData[label][p._id] ? graphData[label][p._id]["totalTime"]: "0,0";
                const [hours, minutes] = timeString.split(',').map(Number);
                return (hours + minutes/60)
            }),
            stack: 'Stack 0',
            backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16)
        }
    })
    const data = {
      labels,
      datasets: datasets,
    };

    const options = {
        plugins: {
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    const value = tooltipItem.formattedValue; // Get the tooltip value
                    const hours = Math.floor(value); // Extract hours
                    const minutes = Math.round((value % 1) * 60); // Extract remaining minutes
                    return `${hours}h ${minutes}m`;
                },
            },
        },
          title: {
            display: true,
          },
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
    };  

    const handleWeeklyClick = () => {
        const newStartDate = subDays(new Date(), 6);
        const newEndDate = new Date();
        const dateArray = eachDayOfInterval({ start: newStartDate, end: newEndDate });
    
       
        setSelectedDate(dateArray);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      };

      useEffect(() => {
        let selectedDate1 = selectedDate[0] || ''
        // console.log('selectedDate', selectedDate[0]);
        dispatch(updateSelectedDateRange("between " + selectedDate1  + " and " + endDate))
      }, [endDate, startDate])
      
      
      const handleMonthlyClick = () => {
        const newStartDate = subMonths(new Date(), 1);
        const newEndDate = new Date();
        const dateArray = eachDayOfInterval({ start: newStartDate, end: newEndDate });
    
        
    
        setSelectedDate(dateArray);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      };

    return (

            <div class="reports-data-tab">
            <div class="filter-data d-md-flex align-items-center justify-content-between">
                <div class="date-select me-2 mb-3 col-md-8">
                    <label className='add-project-label d-block mb-1'>Select Date</label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="MMMM d, yyyy"
                        className="date-picker"
                        isClearable
                    />
                    <button class= "custom-button custom-mobile-view" onClick={handleWeeklyClick}>Last 7 days</button>
                    <button class= "custom-button" onClick={handleMonthlyClick}>Last Month</button>
                </div>
                <div class="d-flex align-items-center column-fd col-md-4 justify-content-end mobile-filter">
                    <div class="users-select me-2">
                        <label className='add-project-label mb-1'>Filter Users</label>
                        <Select
                            className="btn-color"
                            options={userOptions}
                            name='users'
                            isMulti
                            value={selectedUsers}
                            onChange={handleFilterUsers}
                        />

                    </div>
                    <div class="project-select me-2">
                        <label className='add-project-label mb-1'>Filter Projects</label>
                        <Select
                            className="btn-color"
                            options={projectOptions}
                            name='projectArray'
                            isMulti
                            value={selectedProjects}
                            onChange={handleFilterProjects}
                        />

                    </div>
                </div>
            </div>
                <div class={!flag ? "chat-data" : "chat-data-viewmore"}>   
                    <Bar options={options} data={data} />
                </div>
            </div>
    )
}

export default GraphAnalytics
