import React, { useEffect, useState } from 'react'
import './approval.css'
import PlusIcon from '../../assests/icons/plusg.svg';
import EyeIcon from '../../assests/images/eye-white.svg';
import ExpandIcon from '../../assests/images/expand.svg';
import DragIcon from '../../assests/images/drag.svg';
import TickIcon from '../../assests/images/tick-black.svg';
// import DragIcon from '../../assests/images/drag.svg';
import NoImage from '../../assests/images/NoImage.jpg';
import { useDispatch, useSelector } from 'react-redux';
import ApprovalTaskDetails from './viewDetails';
import { updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import { updatetaskApprovalPopup, updateupcomingHeadline } from '../../redux/viewmore';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const ApprovalWaiting = ({ setTasksListProps, flag }) => {
    const [openViewDetails, setOpenViewDetails] = useState(false)
    const dispatch = useDispatch()
    const usersTaskList = useSelector(state => state.task.TasksList?.waitingForApproval)
    const projects = useSelector(state => state.project?.showProjectList) || []
    const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
    const upcomingPopup = useSelector(state => state.viewmore);
    const [approveStatus, setApproveStatus] = useState([])
    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    const [openEditTaskDetails, setOpenEditTaskDetails] = useState(false)

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);

        // Get day, month, and year components
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        // Format the date as "dd month name, year"
        return `${day} ${month} ${year}`;
    };
    const [openWaitingModal, setOpenWaitingModal] = useState(false);
    const handleNewTaskButton = () => {

        if (projects.length > 0) {
            setOpenNewTaskModal(true)
        } else {
            toastr.error("No Project Found")
        }

    }
    useEffect(() => {
        if (openNewTaskModal) {
            setTasksListProps({
                ModalName: 'AddNewTask',
                isOpen: openNewTaskModal,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0],
                waitingForApproval: true
            })
        } else if (openViewDetails) {
            setTasksListProps({
                ModalName: 'ViewDetails',
                isOpen: openViewDetails,
                onClose: handleClose,
                flag: flag,
                onEditTask: handleEditTaskPage
            })

        } else {
            setTasksListProps(null)
        }
    }, [openNewTaskModal, openViewDetails, projects])

    const handleEditView = (value) => {
        setOpenViewDetails(true)
        dispatch(updateEditTaskData(value))
    }

    const handleEditTaskPage = () => {
        setOpenEditTaskDetails(true)
        // dispatch(updateEditTaskData(value))
    }

    useEffect(() => {
        if (openEditTaskDetails) {
            setTasksListProps({
                ModalName: 'EditTask',
                isOpen: openEditTaskDetails,
                onClose: handleClose,
                selectedProject: projects[0]?.project_name,
                clientName: projects[0]?.client_names[0]
            })
        } 
        else {
            setTasksListProps(null)
        }
    }, [ openEditTaskDetails, projects[0]])

    const handleClose = () => {
        setOpenViewDetails(false)
        setOpenNewTaskModal(false)
        setOpenEditTaskDetails(false)
    }
    const getStatusColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Completed':
                return 'green';
            case 'Rejected':
                return 'red';
            default:
                return '#F13A3A'; // Default color
        }
    };
    const updateTaskDetails = async (e, value, status) => {
        e.preventDefault()
        let taskstatus = value.status
        let payload = new FormData();
        payload.append('id', value?._id);
        payload.append('status', taskstatus !== undefined ? status : approveStatus.value);
        try {
            const response = await apiServiceHandler("POST", "api/task/update", payload)
            dispatch(updateRefreshTaskList(true))

        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }
    const statusCompletion = (e, value) => {
        let status = "Approved"
        setApproveStatus({ value: 'Approved', label: 'Approved' })
        updateTaskDetails(e, value, status)
    }
    const handleReject = (e, value) => {
        let status = "Rejected"
        setApproveStatus({ value: 'Rejected', label: 'Rejected' })
        updateTaskDetails(e, value, status)
    }


    return (
        // <div className="col-md-6 mb-4">
        <div className={window.screen.width < 1280 ? "waiting-approval-mobile" : ""}>
            <div className={flag ? "waiting-approval-viewmore" : "waiting-approval"}>
                <div className={flag ? "approval-heading d-flex align-items-center justify-content-between" : "approval-heading d-flex align-items-center justify-content-between viewmore-line"}>
                    {!flag && <h2 className="mb-0">Waiting for your Approval ({userData.first_name})</h2>}
                    <div class="d-flex align-items-center">
                        {!upcomingPopup.taskApprovalPopup && <> <button type="button" className="add-icon" onClick={() => handleNewTaskButton()}><img src={PlusIcon} /></button>
                            {window.screen.width > 760 && <button type="button" onClick={() => {
                                dispatch(updatetaskApprovalPopup(true));
                                dispatch(updateupcomingHeadline(`Waiting for your Approval (${userData.first_name})`))
                            }} className="add-icon"><img src={ExpandIcon} /></button>}
                            <button type="button" className="drag-me"><img src={DragIcon} /></button></>}
                    </div>
                </div>
                <div className={flag ? "approval-info-col-viewmore" : "approval-info-col"}>
                    <div class="row">
                        {usersTaskList && usersTaskList.map((value) => (<div class="col-md-3">
                            <div class="info-data mb-4">
                                <img src={value?.supported_docs.length > 0 ? value?.supported_docs : NoImage} class="project-img" />
                                <div class="approval-info mt-2">
                                    <ul class="list-unstyled m-0">
                                        <li><label>Task:</label> {value?.task_name.length > 40 ? `${value?.task_name.substr(0, 40)}...` : value?.task_name}</li>
                                        <li><label >Project:</label> {value?.project_name.length > 20 ? `${value?.project_name.substr(0, 20)}...` : value?.project_name}</li>
                                        <li class="approval-status">
                                            <div><label>Due:</label> {getFormattedDate(value?.due_date)}</div>
                                            <div className="status-label"><label>Status:</label> <span style={{ color: getStatusColor(value?.status) }}>{value?.status?.replace(/\b\w/g, char => char.toUpperCase())}</span></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="approval-btn mt-2">
                                    {value?.status === 'Approved' ? (
                                        <><button type="button" data-bs-target="#approvalModal"
                                            onClick={() => {
                                                handleEditView(value);
                                            }}
                                            class="approval-view"><img src={EyeIcon} />Expand</button>
                                            <button
                                                type="button"
                                                class="approval-reject"
                                                onClick={(e) => handleReject(e, value)}
                                            >
                                                Reject
                                            </button>

                                        </>

                                    ) : value?.status === 'Rejected' ? (
                                        <>
                                            <button type="button" data-bs-target="#approvalModal"
                                                onClick={() =>
                                                    handleEditView(value)}
                                                class="approval-view"><img src={EyeIcon} />Expand</button>
                                            <button
                                                type="button"
                                                class="approval-approved"
                                                onClick={(e) => statusCompletion(e, value)}
                                            >
                                                Approve
                                            </button>
                                            {/* <button type="button" class="approval-done" onClick={(e) => statusCompletion(e, value)}><img src={TickIcon} />Mark Done</button> */}
                                        </>
                                    ) : (
                                        <>
                                            <button type="button" data-bs-target="#approvalModal"
                                                onClick={() =>
                                                    handleEditView(value)}
                                                class="approval-view"><img src={EyeIcon} />Expand</button>
                                            <button type="button" class="approval-done" onClick={(e) => statusCompletion(e, value)}><img src={TickIcon} />Mark Done</button>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        ))}

                    </div>
                </div>

            </div>
            {window.screen.width > 760 && !flag && <button type="button" onClick={() => {
                dispatch(updatetaskApprovalPopup(true));
                dispatch(updateupcomingHeadline(`Waiting for your Approval (${userData.first_name})`))
            }} class="view-more">View More</button>}
        </div>
    )
}

export default ApprovalWaiting;
