import React from 'react'
import Logo from '../../assests/images/LogoLivelook.svg'
import Error from '../../assests/images/error-svg.svg'
import { useNavigate, useLocation } from 'react-router'
import './payment.css'

const Cancel = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const signupData = JSON.parse(localStorage.getItem('signupData'));

  const navigate = useNavigate();
  const { state } = useLocation();
  const searchParams = new URLSearchParams(document.location.search)

  const handleCancelPayment = () => {
    console.log('Payment Cancel...')
    navigate(`/${userDetails ? userDetails.unique_url : signupData.unique_url}`)
  }

  if (state) {
    return (
      <div className="bg-image">
        <div className="bg-box">
          <div className="logo text-center mb-4">
            <img src={Logo} />
          </div>
          <div className="subscription-area p-3">
            <h2 className="mb-3 text-center">{userDetails ? userDetails?.first_name : signupData?.first_name}, your payment cancelled</h2>

            <div className='error-icon'><img src={Error} /></div>

            <div className="plan-desc mb-3">
              <ul>
                <li>Your payment reference number is {state?.orderId.orderID}</li>
                <li>Your payment request has been cancelled and you will not be able to access our portal.</li>
              </ul>
            </div>
            <div className="text-center mt-5">
              <button type="button" className="plan-btn" onClick={handleCancelPayment}>
                Back to log in
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (searchParams.get('subscription_id')) {
    return (
      <div className="bg-image">
        <div className="bg-box">
          <div className="logo text-center mb-4">
            <img src={Logo} />
          </div>
          <div className="subscription-area p-3">
            <h2 className="mb-3 text-center">{userDetails?.first_name}, your plan upgrade cancelled!</h2>

            <div className='error-icon'><img src={Error} /></div>

            <div className="plan-desc mb-3">
              <ul>
                <li>Your previous Subscription Id is {searchParams.get('subscription_id')}</li>
                <li>Your plan upgrade request has been cancelled, you will be able to access our portal with previous plan specification.</li>
              </ul>
            </div>
            <div className="text-center mt-5">
              <button type="button" className="plan-btn" onClick={handleCancelPayment}>
                Back to log in
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cancel
