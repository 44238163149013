import React, { useState } from 'react'
import './otp.css';
import Logo from "../../assests/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom'
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useSelector } from 'react-redux';

const ClickupSignup = () => {
    const navigate = useNavigate()
    const [clickupToken, setClickupToken] = useState("")
    
    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    let UniqueUrl = userData?.unique_url

    let projectNamesArray = localStorage.getItem("JLL_ProjectList")
    projectNamesArray = projectNamesArray ? JSON.parse(projectNamesArray) : {}

    const handleUniqueUrlCheck = async () => {
       
        const payload = {
            uniqueUrl: UniqueUrl,
            projectName: projectNamesArray,
            clickup_token: clickupToken
        }
        try {
            const response = await apiServiceHandler("POST", "api/auth/clickUpSignup", payload);
            console.log(response)
            toastr.success(response.message)
            navigate(`/${UniqueUrl}`)
        } catch (e) {
            
            console.error(e)
            // toastr.success("Login via code here")
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleUniqueUrlCheck();
        }
    };
    return (
        <div className="bg-image">
            <div className="bg-box">
                <div className="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="enterOTP" className="enter-url shadow">
                    <p className="mb-3">Welcome to <strong>Live Look</strong></p>
                    <h1 className="mb-4">Enter your Clickup Token</h1>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <input name="clickup_token" onChange={(e) => setClickupToken(e.target.value)} onKeyPress={handleKeyPress} className="form-control" id="exampleFormControlInput1" placeholder="Clickup Token"
                                //  value={uniqueUrlvalue} onChange={(e) => setUniqueUrlvalue(e.target.value)}
                                />
                                <p className="helper-text">Want to generate a Clickup Token?{' '}<a href="https://clickup.com/api/developer-portal/authentication/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >click here</a></p>
                            </div>
                            <div className="btn-group-signin">
                                {/* <button onClick={() => navigate("/Dashboard/UniqueUrl")} type="button" className="prev-btn">Back</button> */}
                                <button onClick={handleUniqueUrlCheck} type="button" className="next-btn">Next</button>
                            </div>

                        </div>


                    </form>
                </div>
            </div>
        </div>
    )
}

export default ClickupSignup
