import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assests/images/LogoLivelook.svg'
import Successfull from '../../assests/images/successful-svg.svg'
import Error from '../../assests/images/error-svg.svg'
import { useNavigate, useLocation } from 'react-router'
import './payment.css'
import apiServiceHandler from '../../service/apiService'
import { updatePaymentDetails, updateRefreshUserDatap } from '../../redux/userManagement'
import { useDispatch } from 'react-redux'
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import Spinner from 'react-bootstrap/Spinner';

const Success = () => {

  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const searchParams = new URLSearchParams(document.location.search)
  const hasFetchedSubscriptionDetails = useRef(false);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const user = JSON.parse(localStorage.getItem('userData'));
  const signupData = JSON.parse(localStorage.getItem('signupData'));
  const [subscriptionFetch, setSubscriptionFetch] = useState(false);

  const updatePaymentDetailsDB = async (payload) => {
    try {
      const updatedDetails = await apiServiceHandler('POST', 'api/payment/paypal/update/payment-details', payload)

      return updatedDetails;
    } catch (error) {
      console.log(error, 'Error is here in success.js updatePaymentDetailsDB')
    }
  }

  const fetchSubscriptionDetails = async (subscriptionID) => {
    try {
      const payload = {
        subscriptionID
      }
      const payment = await apiServiceHandler('POST', 'api/payment/paypal/subscription/retrive', payload)

      const lists = await apiServiceHandler('POST', 'api/payment/paypal/subscription/list-transactions', payload)

      const newUserPayload = {
        unique_url: user ? (user.owned_by ? user.owned_by : user.unique_url) : userDetails?.unique_url,
        subscriptionID,
        newTotalPrice: payment.planDetails.totalPrice,
        userId: user.user_id
      }

      await apiServiceHandler('POST', 'api/payment/paypal/capture/new-user-details', newUserPayload);

      const details = {
        unique_url: user ? (user.owned_by ? user.owned_by : user.unique_url) : userDetails?.unique_url,
        data: payment,
        userQuantity: payment.planDetails.userQuantity,
        projectQuantity: payment.planDetails.projectQuantity,
        storageQuantity: payment.planDetails.storageQuantity,
        totalPrice: payment.planDetails.totalPrice,
      }
      const res = await updatePaymentDetailsDB(details);
      const data = {
        details: res?.data,
        transactions: lists?.data.transactions
      }
      dispatch(updatePaymentDetails(data));
      setSubscriptionFetch(true);
    } catch (error) {
      toastr.error("Something went wrong!")
      console.log(error, 'Error is here in success.js fetchSubscriptionDetails')
    }
  }
  const handleFreeTrial = (unique_url) => {
    dispatch(updateRefreshUserDatap(true));
    navigate(`/${unique_url}`)
  }

  const handleSuccessPayment = (unique_url) => {
    dispatch(updateRefreshUserDatap(true));
    navigate(`/${unique_url}`)
  }

  const handleUpgradePayment = async (event, unique_url) => {
    event.currentTarget.disabled = true;
    if (!subscriptionFetch) await fetchSubscriptionDetails(searchParams.get('subscription_id'))
    dispatch(updateRefreshUserDatap(true));
    navigate(`/${unique_url}`)
  }

  useEffect(() => {
    if (searchParams.get('subscription_id') && !hasFetchedSubscriptionDetails.current) {
      fetchSubscriptionDetails(searchParams.get('subscription_id'));
      hasFetchedSubscriptionDetails.current = true;
    }
  }, [searchParams]);

  if (state?.plan === 'Free') {
    return (
      <div className="bg-image">
        <div className="bg-box">
          <div className="logo text-center mb-4">
            <img src={Logo} />
          </div>
          <div className="subscription-area p-3">
            <h2 className="mb-3 text-center">Hello {state?.firstName}, welcome to Free Trial of JoinLiveLook!</h2>

            <div className='success-icon'><img src={Successfull} /></div>

            <div className="plan-desc mb-3">
              <ul>
                <li>Your Free Subscription plan will expire on {new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toDateString()}</li>
                <li>Now you can enjoy your JoinLiveLook with {state?.userQuantity} users, {state?.projectQuantity} projects and {state?.storageQuantity}GB storage space</li>
              </ul>
            </div>
            <div className="text-center mt-5">
              <button type="button" className="plan-btn" onClick={() => handleFreeTrial(state?.unique_url)}>
                Login to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (searchParams.get('subscription_id')) {
    if (!subscriptionFetch) {
      return (
        <div className="bg-image">
          <div className="bg-box">
            <div className="logo text-center mb-4">
              <img src={Logo} />
            </div>
            <div className="subscription-area p-3">
              <div className='error-icon'><Spinner animation="border" /></div>
              <h2 className="mb-3 text-center">waiting for confirmation!</h2>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="bg-image">
          <div className="bg-box">
            <div className="logo text-center mb-4">
              <img src={Logo} />
            </div>
            <div className="subscription-area p-3">
              <h2 className="mb-3 text-center">{userDetails.first_name}, your plan has been upgraded successfully!</h2>

              <div className='success-icon'><img src={Successfull} /></div>

              <div className="plan-desc mb-3">
                <ul>
                  <li>Your payment Subscription Id is {searchParams.get('subscription_id')}</li>
                  {/* <li>Your next billing date is </li> */}
                </ul>
              </div>
              <div className="text-center mt-5">
                <button type="button" className="plan-btn" onClick={(event) => handleUpgradePayment(event, userDetails.unique_url)}>
                  Back to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  if (state?.subscriptionID) {
    return (
      <div className="bg-image">
        <div className="bg-box">
          <div className="logo text-center mb-4">
            <img src={Logo} />
          </div>
          <div className="subscription-area p-3">
            <h2 className="mb-3 text-center">{userDetails ? userDetails?.first_name : signupData?.first_name}, your payment has been successful!</h2>

            <div className='success-icon'><img src={Successfull} /></div>

            <div className="plan-desc mb-3">
              <ul>
                <li>Your payment Subscription Id is {state?.subscriptionID}</li>
                <li>Your next billing date is {new Date(state?.billing_date).toLocaleDateString()}</li>
                <li>Now you can enjoy your JoinLiveLook with {state?.userQuantity} users, {state?.projectQuantity} projects and {state?.storageQuantity}GB storage space</li>
              </ul>
            </div>
            <div className="text-center mt-5">
              <button type="button" className="plan-btn" onClick={() => handleSuccessPayment(userDetails ? userDetails?.unique_url : signupData?.unique_url)}>
                Login to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Success
