import React, { useEffect, useState } from 'react';
import Avatar from '../../assests/images/avatar.svg';
import Filter from '../../assests/images/filter-grey.svg';
import PlusIcon from '../../assests/images/plus.svg';
import ProfilePicture from '../../assests/images/profile-pic.svg';
import SearchIcon from '../../assests/images/search.svg';
import apiServiceHandler from "../../service/apiService";
import { useNavigate, useParams } from 'react-router-dom'
import Actions from './actionOptions';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { useDispatch, useSelector } from 'react-redux';
import { updateRefreshUserDatap, updateRoleList, updateUsers } from '../../redux/userManagement';

const ManageUser = ({ setRefreshUserManagementData, flag, refreshUserManagementData, setEdituserData, setIsOpenAddUser, setIsOpenEditUser, setIsOpenDeleteUser }) => {
    const [userDataList, setUserDataList] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchValue, setSetsearchValue] = useState("")
    const refreshData = useSelector(state => state.allUserData.refreshUserData)
    const roleList = useSelector(state => state.allUserData.roleList)
    const upcomingPopup = useSelector(state => state.viewmore)
    let userData = localStorage.getItem('userData');
    userData = userData ? JSON.parse(userData) : ""
    const { id } = useParams()
    const dispatch = useDispatch()
    let debounceTimer;
    const debounceTimeout = 500; // 500ms delay
    const projectList = useSelector(state => state.project.projectList) || []

    const fetchuseData = async () => {
        try {
            const response = await apiServiceHandler("GET", `api/auth/all_users_list`, {});
            const res = await apiServiceHandler("GET", "api/auth/accounts_created_by_user", {});
            const accessToAllProject = userData.access_to_apis?.access_to_all_project ? (userData.company_id ? userData.company_id : userData.parent_company_id) : ''
            const projectRes = await apiServiceHandler("POST", "api/project/client/list", { company_id: accessToAllProject });

            const resUsers = response.allUsersList
            const accUsers = res.message

            let result = resUsers.concat(accUsers).filter((item, index, arr) => {
                return arr.findIndex((t) => t.unique_url === item.unique_url) === index;
            });

            const projectUsers = []

            Object.keys(projectRes.data).forEach(element => {
                projectUsers.push(projectRes.data[element].client_data)
            });

            result = result.concat(projectUsers).filter((item, index, arr) => {
                return arr.findIndex((t) => t.unique_url === item.unique_url) === index;
            });

            setUserDataList(result)
            // setFilteredData(response?.all_users_list)
            dispatch(updateUsers(result))
        } catch (err) {
            // toastr.error(err?.response?.data?.message)
            console.log(err)
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        const filterData = userDataList.filter(value => value?.first_name?.includes(searchValue) || value?.last_name?.includes(searchValue) ||
            value?.email?.includes(searchValue) || searchValue.trim() === "")
        setFilteredData(filterData)
    }

    useEffect(() => {
        fetchuseData()
        return () => {
            //   second
        }
    }, [projectList])

    useEffect(() => {
        if (refreshUserManagementData || refreshData) {
            fetchuseData()
            setRefreshUserManagementData(false)
            dispatch(updateRefreshUserDatap(false))
        }


    }, [refreshUserManagementData, refreshData])


    useEffect(() => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleSearch({ preventDefault: () => { } })
        }, debounceTimeout);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue, userDataList]);

    const fetchRoleList = async () => {
        try {
            const response = await apiServiceHandler("GET", "api/auth/get_roles_list", {})
            // setRolesList(response?.role_list)
            dispatch(updateRoleList(response?.role_list))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchRoleList()
    }, [])

    return (
        <div>
            <div className={flag ? "manage-users-tab-search d-flex align-items-center" : "manage-users-tab-search d-flex align-items-center view-line"}>
                <form onSubmit={handleSearch}>
                    <input onChange={(e) => setSetsearchValue(e.target.value)} type="text" name="searchusers" placeholder="Search..." className="form-control" />
                    <button type="submit"><img src={SearchIcon} /></button>
                </form>
                <div class="users-filter dropdown">
                    <button type="button" class="filter-icon-two dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={Filter} /></button>
                    <div class="dropdown-menu">
                        <div class="row">
                            <div class="col-md-7">
                                <h3>Role</h3>
                                <ul class="m-0 list-unstyled">
                                    {roleList && roleList.map((role) => (
                                        <li key={role.id}>
                                            <button type="button" class="no-default">
                                                {role}
                                            </button>
                                        </li>
                                    ))}
                                    {/* <li><button type="button" class="no-default">Front End Developer</button></li>
                                    <li><button type="button" class="no-default">Project Manager</button></li>
                                    <li><button type="button" class="no-default">UI Design</button></li> */}
                                </ul>
                            </div>
                            <div class="col-md-5">
                                <h3>Status</h3>
                                <ul class="m-0 list-unstyled">
                                    <li><button type="button" class="no-default">Active</button></li>
                                    <li><button type="button" class="no-default">Inactive</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button type="button" className="filter-icon-two dropdown-toggle"><img src={Filter} /></button> */}
                {!upcomingPopup.userMgtPopup && <button data-placement="top" title="Add new user" type="button" className="add-icon-two" onClick={() => setIsOpenAddUser(true)}><img src={PlusIcon} /></button>}
            </div>
            <div className="manage-users-tab">
                <div className="manage-users-tab-label d-none d-lg-block mb-3">
                    <div className="row g-0">
                        <div className="col-lg-1">
                            <h3>Avatar</h3>
                        </div>
                        <div className="col-lg-2 text-lg-center">
                            <h3>Name</h3>
                        </div>
                        <div className="col-lg-3 text-lg-center">
                            <h3>Email ID</h3>
                        </div>
                        <div className="col-lg-2 text-lg-center">
                            <h3>Role</h3>
                        </div>
                        <div className="col-lg-2 text-lg-center">
                            <h3>Company</h3>
                        </div>
                        <div className="col-lg-1 text-lg-center">
                            <h3>Status</h3>
                        </div>
                        <div className="col-lg-1 text-lg-center">
                            <h3>Action</h3>
                        </div>
                    </div>
                </div>
                <div className={flag ? "manage-users-tab-data-viewmore" : "manage-users-tab-data"}>
                    {filteredData && filteredData.map((userData, index) => (
                        <div key={index} className="row g-0 align-items-center user-data-row">
                            <div className="col-lg-1 user-avatar">
                                <img src={userData?.logo ? userData?.logo : Avatar} />
                            </div>
                            <div className="col-lg-2 text-lg-center">
                                <p className="mb-0">{userData?.first_name} {userData?.last_name}</p>
                            </div>
                            <div className="col-lg-3 text-lg-center">
                                <p className="mb-0">{userData?.email}</p>
                            </div>
                            <div className="col-lg-2 text-lg-center">
                                <p className="mb-0">{userData?.role.charAt(0).toUpperCase() + userData?.role.slice(1).toLowerCase()}</p>
                            </div>
                            <div className="col-lg-2 text-lg-center">
                                <p className="mb-0">{userData?.company_name}</p>
                            </div>
                            {userData?.status ?
                                <div className="col-lg-1 text-lg-center">
                                    <p className="mb-0" style={{ color: "#3EBB11" }}>Active</p>
                                </div> :

                                <div className="col-lg-1 text-lg-center">
                                    <p className="mb-0" style={{ color: "#E71313" }}>Inactive</p>
                                </div>}
                            <Actions userData={userData} setIsOpenDeleteUser={setIsOpenDeleteUser} setEdituserData={setEdituserData} setIsOpenEditUser={setIsOpenEditUser} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ManageUser