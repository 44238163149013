import React, { useEffect, useState } from 'react';
import EditIcon from '../../assests/images/edit.svg';
import SubscriptionActions from './subscriptionAction';
import apiServiceHandler from '../../service/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentDetails } from '../../redux/userManagement';
import { updateProjectsCount, updateUsersCount } from '../../redux/userlogs';

const SubscriptionBilling = ({ flag }) => {
   const userData = localStorage.getItem('userData')
   const allData = JSON.parse(userData)
   const dispatch = useDispatch()
   const paymentDetails = useSelector(state => state.allUserData.paymentDetails.details)
   const listTransactions = useSelector(state => state.allUserData.paymentDetails.transactions)
   const userLists = useSelector(state => state.allUserData.users)
   const userListLength = useSelector(state => state.userlogs.usersCount);
   const projectListLength = useSelector(state => state.userlogs.projectsCount);
   const upcomingPopup = useSelector(state => state.viewmore)
   const [consentData, setConsentData] = useState([])

   const fetchPaymentDetails = async () => {
      try {
         const payload = {
            uniqueUrl: allData?.owned_by ? allData.owned_by : allData.unique_url
         }
         const payment_details = await apiServiceHandler('POST', 'api/payment/paypal/payment-details', payload)

         const projectCount = await apiServiceHandler(
            "POST",
            "api/auth/user/project/count",
            { company_id: allData?.parent_company_id ? allData.parent_company_id : allData.company_id }
         );
         dispatch(updateProjectsCount(projectCount.data));
         const userCount = await apiServiceHandler(
            "POST",
            "api/auth/user/users/count",
            { unique_url: allData?.owned_by ? allData.owned_by : allData.unique_url }
         );
         dispatch(updateUsersCount(userCount.data));

         const consentRecord = await apiServiceHandler(
            "POST",
            "api/invoice/consent/records",
            { unique_url: allData.owned_by ? allData.owned_by : allData.unique_url }
         );
         setConsentData(consentRecord.data)

         let transactionsDetails = ''
         if (payment_details?.data.payment_details.id) {
            transactionsDetails = await listTransactionsDetails(payment_details?.data.payment_details.id)
         }
         const data = {
            details: payment_details?.data,
            transactions: transactionsDetails ? transactionsDetails : ''
         }
         dispatch(updatePaymentDetails(data));
      } catch (error) {
         console.log(error, 'Error is here in fetchPaymentDetails');
      }
   }

   const listTransactionsDetails = async (subscriptionID) => {
      const payload = {
         subscriptionID: subscriptionID
      }
      const lists = await apiServiceHandler('POST', 'api/payment/paypal/subscription/list-transactions', payload)

      return lists?.data.transactions;
   }

   useEffect(() => {
      fetchPaymentDetails()
   }, [])

   return (
      <div className="subscription-billing-tab">
         <div className="subscription-billing-tab-label d-none d-lg-block mb-3">
            <div className='title-billing'><h4 style={{ color: '#362460' }}>Active Plans</h4></div>
            <div className="row g-0">
               <div className="col-lg-1">
                  <h3>Date</h3>
               </div>
               <div className="col-lg-2 text-lg-center">
                  <h3>Status</h3>
               </div>
               <div className="col-lg-2 text-lg-center">
                  <h3>Users</h3>
               </div>
               <div className="col-lg-2 text-lg-center">
                  <h3>Projects</h3>
               </div>
               <div className="col-lg-2 text-lg-center">
                  <h3>Storage</h3>
               </div>
               <div className="col-lg-2 text-lg-center">
                  <h3>Monthly Fees</h3>
               </div>
               <div className="col-lg-1 text-lg-center">
                  <h3>Manage</h3>
               </div>
            </div>
         </div>
         <div className={flag ? "subscription-billing-tab-data-viewmore" : "subscription-billing-tab-data"}>
         <div className='title-billing d-block d-md-none border-0'><h5 style={{ color: '#362460' }}>Active Plans</h5></div>
            {paymentDetails && <div className="row align-items-center g-0 subscription-data-row">
               
               <div className="col-lg-1">
                  {/* <label className="mb-0">Name</label> */}
                  <p className="mb-0">{new Date(paymentDetails?.payment_details?.start_time).toLocaleDateString()}</p>
               </div>
               <div className="col-lg-2 text-lg-center">
                  {/* <label className="mb-0">Starting Date</label> */}
                  <p className="mb-0">{paymentDetails?.status}</p>
               </div>
               <div className="col-lg-2 text-lg-center">
                  {/* <label className="mb-0">Starting Date</label> */}
                  <p className="mb-0">{userListLength}/{paymentDetails?.user_quantity}</p>
               </div>
               <div className="col-lg-2 text-lg-center">
                  {/* <label className="mb-0">Status</label> */}
                  <p className="mb-0">{projectListLength}/{paymentDetails?.project_quantity}</p>
               </div>
               <div className="col-lg-2 text-lg-center">
                  {/* <label className="mb-0">Licenses</label> */}
                  <p className="mb-0">{paymentDetails?.storage_quantity} GB</p>
               </div>
               <div className="col-lg-2 text-lg-center">
                  {/* <label className="mb-0">Monthly Fees</label> */}
                  <p className="mb-0">${paymentDetails?.total_price ? paymentDetails?.total_price : paymentDetails?.payment_details?.billing_info.last_payment.amount.value}</p>
               </div>
               <SubscriptionActions paymentDetails={paymentDetails} uniqueUrl={allData?.owned_by ? allData.owned_by : allData.unique_url} fetchPaymentDetails={fetchPaymentDetails} />
            </div>}

            <hr />

            {/* Billing History */}
            <div className="subscription-billing-tab-label d-none d-lg-block mb-3">
               <div><h4 style={{ color: '#362460' }}>Billing History & Invoices</h4></div>
               <div className="row">
                  <div className="col-lg-1">
                     <h3>Date</h3>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <h3>Description</h3>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <h3>Amount</h3>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <h3>Payment Status</h3>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                  </div>
                  <div className="col-lg-2 text-lg-center">
                  </div>
                  {/*
                  <div className="col-lg-1 text-lg-center">
                     <h3>Manage</h3>
                  </div>
                  */}
               </div>
            </div>
            <div className='title-billing d-block d-md-none border-0'><h5 style={{ color: '#362460' }}>Billing History & Invoices</h5></div>
            {consentData && consentData.map((data, i) => (
               <div key={i} className="row align-items-center g-0 subscription-data-row-second">
                  <div className="col-lg-1">
                     <p className="mb-0">{new Date(data.next_billing_date).toLocaleDateString()}</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <p className="mb-0">Invoice Payment</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <p className="mb-0">${data.invoice_amount}.00</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <button className="mb-0 billing-status" onClick={() => { window.open(data.invoice_link, '_blank') }}>Pay Now</button>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     {/* Uncomment and modify this section if needed */}
                     {/* <p className="mb-0">${transaction.monthlyFee}</p> */}
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     {/* Uncomment and modify this section if needed */}
                     {/* <button type="button" className="payment-due">View Invoices</button> */}
                  </div>
                  <div className="col-lg-1 text-lg-center">
                     {/* 
                     <button type="button" className="user-action"><img src={EditIcon} alt={`Edit Icon for item ${i}`} /></button>
                     */}
                  </div>
               </div>
            ))}
            {listTransactions && listTransactions.map((transaction, index) => (
               <div key={index} className="row align-items-center g-0 subscription-data-row-second">
                  <div className="col-lg-1">
                     <p className="mb-0">{new Date(transaction.time).toLocaleDateString()}</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <p className="mb-0">Subscription Fee</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <p className="mb-0">${transaction.amount_with_breakdown.gross_amount
                        .value}</p>
                  </div>
                  <div className="col-lg-2 text-lg-center">
                     <div className="mb-0 billing-status-mobile">{transaction.status}</div>
                  </div>
                  {/* <div className="col-lg-2 text-lg-center"> */}
                     {/* Uncomment and modify this section if needed */}
                     {/* <p className="mb-0">${transaction.monthlyFee}</p> */}
                  {/* </div> */}
                  {/* <div className="col-lg-2 text-lg-center"> */}
                     {/* Uncomment and modify this section if needed */}
                     {/* <button type="button" className="payment-due">View Invoices</button> */}
                  {/* </div> */}
                  {/* <div className="col-lg-1 text-lg-center"> */}
                     {/* 
                     <button type="button" className="user-action"><img src={EditIcon} alt={`Edit Icon for item ${index}`} /></button>
                     */}
                  {/* </div> */}
               </div>
            ))}
         </div>
      </div>
   )
}

export default SubscriptionBilling
