import React from 'react'
import Header from '../header/index'
import Index from "../index";

function Developer_dashboard() {
  return (
    <div>
        <Header />
        <Index />
    </div>
  )
}

export default Developer_dashboard