import React from 'react'
import CloseIcon from '../../assests/images/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateOpenDeletePopup, updateRefreshUserDatap } from '../../redux/userManagement';
import apiServiceHandler from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateUsersCount } from '../../redux/userlogs';

const DeleteUser = ({ isOpen, onClose }) => {
    const dispatch = useDispatch()
    const deleteData = useSelector(state => state.allUserData)
    let userData = deleteData.editAndDeleteData;
    const user = JSON.parse(localStorage.getItem('userData'));
    const paymentDetails = useSelector(state => state.allUserData.paymentDetails.details)
    const userLists = useSelector(state => state.allUserData.users)
    const ownedUser = userLists.filter(acc => (acc.owned_by === user.owned_by ? user.owned_by : user.unique_url) && acc.status === 1);

    const handleDelete = async () => {
        const payload = {
            "user": {
                "uniqueUrl": userData.unique_url,
                "firstName": userData.first_name,
                "lastName": userData.last_name,
                "role": userData.role,
                "email": userData.email,
                "status": 0,
                "addressLine1": userData.address_line_1,
                "addressLine2": userData.address_line_2,
                "city": userData.city,
                "zipCode": userData.zipcode,
                "country": userData.country,
                "phone": userData.phone,
            }
        }
        try {
            const response = await apiServiceHandler("PUT", "api/auth/manage_user_data", payload);
            const userCount = await apiServiceHandler(
                "POST",
                "api/auth/user/users/count",
                { unique_url: userData.owned_by ? userData.owned_by : userData.unique_url }
            );
            dispatch(updateUsersCount(userCount.data));
            toastr.success(response?.message)
            dispatch(updateRefreshUserDatap(true))
            handleClose()
            // if (paymentDetails?.payment_details?.id) {
            //     const revisePayload = {
            //         subscriptionID: paymentDetails.payment_details.id,
            //         planId: paymentDetails.payment_details.plan_id,
            //         quantity: (user.owned_by ? ownedUser.length - 1 : ownedUser.length)
            //     }
            //     const updatedDetails = await apiServiceHandler('POST', 'api/payment/paypal/subscription/upgrade', revisePayload)
            //     toastr.success(response?.message)
            //     dispatch(updateRefreshUserDatap(true))
            //     window.location.replace(updatedDetails?.data.links[0].href)
            //     handleClose()
            // } else {
            //     toastr.success(response?.message)
            //     dispatch(updateRefreshUserDatap(true))
            //     handleClose()
            // }
        } catch (err) {
            console.log("error", err)
        }
    }
    const handleClose = () => {
        dispatch(updateOpenDeletePopup(false))
    }
    return (
        <div>
            <div
                className={`modal fade delete-user edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
                id="deleteUserModal"
                tabindex="-1"
                aria-labelledby="deleteUserModalLabel"
                aria-hidden="true"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">Delete User</h2>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <img src={CloseIcon} />
                            </button>
                        </div>

                        <div className="modal-body text-center">
                            <p className="mb-0"><h3>Are you sure</h3> you want to delete <b>{userData.first_name} {userData.last_name}</b>? you will not be able to recover it anymore</p>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="button" data-bs-dismiss="modal" className="border-btn" onClick={handleClose}>Cancel</button>
                            <button onClick={handleDelete} type="submit" className="bg-btn">Delete</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteUser
