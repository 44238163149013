import React from 'react'
import CtaIcon from '../../assests/icons/cta.png';
import ContactIcon from '../../assests/icons/contact.jpg';
import FooterPage from './footer';
import HeaderPage from './header';

const ContactusPage = () => {
    return (
        <div>

            <HeaderPage />

            <section class="inner-title py-4 text-center">
                <div class="container">
                    <h1 class="m-0">Contact Us</h1>
                </div>
            </section>

            <section class="contact-page pt-5 bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="c-image">
                                <img src={ContactIcon} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h2 class="mb-3">We Want To Hear from You</h2>
                            <div class="git">
                                <ul class="list-unstyled m-0 p-0">
                                    <li><i class="fa fa-map-marker"></i><a href="#!">Gilbert, Arizona, 85233, United States</a></li>
                                    {/* <li><i class="fa fa-phone"></i><a href="tel:+123456789">(123) 456-789</a></li> */}
                                    <li><i class="fa fa-envelope"></i><a href="mailto:team@contact.com">info@joinlivelook.com</a></li>
                                </ul>
                            </div>
                            <div class="footer-social">
                                <ul class="list-unstyled m-0 p-0 d-flex">
                                    <li><a href=""><i class="fa fa-instagram"></i></a></li>
                                    {/* <li><a href=""><i class="fa fa-twitter"></i></a></li> */}
                                    {/* <li><a href=""><i class="fa fa-linkedin-square"></i></a></li> */}
                                    <li><a href=""><i class="fa fa-facebook"></i></a></li>
                                    {/* <li><a href=""><i class="fa fa-youtube-play"></i></a></li> */}
                                </ul>
                            </div>
                            <div class="c-form">
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaf0X7bRhs3SOxSKQMB4I_jZGlZQ74sovgO8cZ8qEUxx-J-A/viewform?embedded=true" width="100%" height="530" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>                            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaf0X7bRhs3SOxSKQMB4I_jZGlZQ74sovgO8cZ8qEUxx-J-A/viewform?embedded=true" width="640" height="400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaf0X7bRhs3SOxSKQMB4I_jZGlZQ74sovgO8cZ8qEUxx-J-A/viewform?embedded=true" width="640" height="401" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                                {/* <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <input class="form-control" placeholder="First*" type="text" required />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <input class="form-control" placeholder="Email*" type="email" required />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <input class="form-control" placeholder="Subject*" type="text" required />
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <textarea cols="40" rows="3" class="form-control" placeholder="Message*" required></textarea>
                                    </div>
                                    <div class="col-md-12 position-relative">
                                        <input class="btn-send" type="submit" value="Send" />
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="home-faq pt-5 bg-white">
                <div class="container">
                    <h2>Frequently Asked Questions</h2>
                    <div class="row">
                        <div class="col-md-6 mb-3 pb-3">
                            <ul class="faq-list list-unstyled m-0 p-0">
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="faq-list list-unstyled m-0 p-0">
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}

            <section class="book-a-demo py-5 bg-white">
                <div class="container">
                    <div class="book-inner shadow-sm">
                        <div class="row align-items-center">
                            <div class="col-md">
                                <img src={CtaIcon} />
                            </div>
                            <div class="col-md text-center">
                                <h2>Get started with Join Live Look</h2>
                                <p>Access our guide and discover everything your company needs to become compliant.</p>
                                <a href="/contact-us" class="filled-btn blue-filled">Book a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterPage />

        </div>
    )
}

export default ContactusPage
