import React, { useEffect, useState } from 'react';
import CloseIcon from '../../assests/images/close.svg';
import ArrowDWIcon from '../../assests/images/arrow-down-white.svg';
// import ArrowWRIcon from '../../assests/images/arrow-right-white.svg';
// import ArrowRIcon from '../../assests/images/arrow-right.svg';
import AttachGreyIcon from '../../assests/images/attachment-grey.svg';
import AttachIcon from '../../assests/images/attachment.svg';
import AttachImg from '../../assests/images/attachment.png';
import ChatsdotsIcon from '../../assests/images/chat-dots.svg';
import PauseIcon from '../../assests/images/pause.svg';
import playIcon from '../../assests/images/play.svg';
import SendIcon from '../../assests/images/send.svg';
import StartIcon from '../../assests/images/start.svg';
import TickGreyIcon from '../../assests/images/tick-grey.svg';
import CloseGreyIcon from '../../assests/images/close-grey.svg';
import TickWIcon from '../../assests/images/tick-white.svg';
import UserIcon from '../../assests/images/user.svg';
import SearchIcon from '../../assests/images/search.svg';
import PlusIcon from '../../assests/images/plus-icon.svg';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import apiServiceHandler from '../../service/apiService';
import { getcommentList, updateEditTaskData, updateRefreshTaskList } from '../../redux/task';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { convertArrayToMinutes } from './timeTracking'
import { updateRefreshUserLogs, updateUserLogsList } from '../../redux/userlogs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateRefreshProjectList } from '../../redux/project';

const EditTask = ({ isOpen, onClose, selectedProject, clientName, waitingForApproval }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpenPriority, setIsDropdownOpenPriority] = useState(false);
    const [priorityTask, setPriorityTask] = useState([])
    const [selectedImage, setSelectedImage] = useState(null);
    const myTaskData = useSelector(state => state.task.editData)
    const taskStatusData = useSelector(state => state.task.statusTaskList)
    const commentsData = useSelector(state => state.task.commentList)
    const usersList = useSelector(state => state.allUserData.users)
    const usersLogsList = useSelector(state => state.userlogs?.userLogsList)
    const refreshUserLogs = useSelector(state => state.userlogs?.refreshUserLogs)
    const dispatch = useDispatch();
    const [updateMyTaskData, setUpdateMyTaskData] = useState({})
    const [currentStatus, setCurrentStatus] = useState([])
    const [taskBelongstoStatus, setTaskBelongstoStatus] = useState([])
    const [selectedAssignedUsers, setSelectedAssignedUsers] = useState([]);
    const [selectedVisibleUsers, setSelectedVisibleUsers] = useState([]);
    const [allAssignedUsersList, setAllAssignedUsersList] = useState([]);
    const [allVisibleUsersList, setAllVisibleUserList] = useState([]);
    const [activeTab, setActiveTab] = useState('comments');
    let filteredUsersList = usersList.filter(user => user.status === 1);
    const clickupUsersList = JSON.parse(localStorage.getItem("Clickup_Users"));
    const [selectedMilestone, setSelectedMilestone] = useState([]);

    // let mergedUsersList = filteredUsersList.concat(clickupUsersList);

    // // Remove duplicates based on user id
    // mergedUsersList = Array.from(new Set(mergedUsersList.map(user => user.id)))
    //     .map(id => mergedUsersList.find(user => user.id === id));
    // const options = [
    //     { value: 'Appointment with Client', label: 'Appointment with Client' },
    //     { value: 'Assign to Client', label: 'Assign to Client' },
    //     { value: 'Waiting for Approval', label: 'Waiting for Approval' },
    //     { value: 'Visible to Client', label: 'Visible to Client' },
    //     { value: 'Not visible to Client', label: 'Not visible to Client' }
    // ]

    const [chatMessages, setChatMessages] = useState([])
    const [commentText, setCommentText] = useState('');
    const [projectUserIds, setProjectUserIds] = useState([]);

    const options = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Under Review', label: 'Under Review' }
    ]
    const clientOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' }
    ]
    const clickupOptions = [
        { value: "to do", label: "To Do" },
        { value: "complete", label: "Complete" },
        // { value: "in progress", label: "In Progress" },
    ];
    const priorityOptions = [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' },
    ]

    const createdDate = myTaskData?.createdAt ? new Date(myTaskData?.createdAt) : null;

    const formattedDate = createdDate instanceof Date && !isNaN(createdDate)
        ? createdDate.toISOString().split('T')[0]
        : '';

    const toggleDropdown = (e, dropdownNumber) => {
        e.preventDefault();
        switch (dropdownNumber) {
            case 1:
                setOpenDropdown(!openDropdown);
                break;
            case 2:
                setIsDropdownOpen(!isDropdownOpen);
                break;
            case 3:
                setIsDropdownOpenPriority(!isDropdownOpenPriority);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (myTaskData) {
            const allData = { ...myTaskData };
            let formattedDate1 = ""
            if (myTaskData.due_date) {
                const due_date = new Date(myTaskData.due_date);
                formattedDate1 = due_date ? due_date?.toISOString().split('T')[0] : ""
            }

            allData.due_date = formattedDate1;
            // Set the modified copy in your state
            setUpdateMyTaskData(allData);
        }

    }, [myTaskData]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUpdateMyTaskData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (myTaskData?.category == "clickup") {

            createAttachmentClickup(file)
        }
        setSelectedImage(file)
    };
    const handleStatus = (status) => {

        setCurrentStatus(status)
    }
    const handleTaskBelongstoStatus = (status) => {

        setTaskBelongstoStatus(status)
    }

    const [statusOption, setStatusOption] = useState([])
    const getTaskStatus = async () => {

        try {
            const response = await apiServiceHandler("GET", "api/task/status/list", {})
            // console.log(response)
            let data = response?.data || []
            let list = []
            Object.values(data).map((value, i) =>
                list.push({
                    value: value, label: value.split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                }))
            setStatusOption(list)

        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    useEffect(() => {
        getTaskStatus()

    }, [])

    useEffect(() => {
        if (myTaskData.status) {
            setCurrentStatus({
                label: myTaskData?.status,
                value: myTaskData?.status
            })

        }
        if (myTaskData.task_status) {
            let data = []
            Object.values(myTaskData.task_status).map((val) => {
                data.push({
                    label: val.split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                    value: val
                })
            })
            setTaskBelongstoStatus(data)


        }
        if (myTaskData?.priority) {
            let data = myTaskData?.priority
            setPriorityTask(data)
        }
        if (myTaskData?.selected_milestone) {
            let data = myTaskData?.selected_milestone
            setSelectedMilestone({
                label: myTaskData?.selected_milestone,
                value: myTaskData?.selected_milestone
            })
        }
    }, [myTaskData])

    const [clickupSelectedUsers, setClickupSelectedUsers] = useState([]);
    const [clickupVisibleToUsers, setClickupVisibleToUsers] = useState([]);
    const [clickupAllUsersList, setClickupAllUsersList] = useState([]);

    useEffect(() => {
        if (clickupAllUsersList.length == 0) {
            setClickupAllUsersList(clickupUsersList)
        }
    }, [clickupUsersList])



    useEffect(() => {
        let filteredUsersList = usersList.filter(user => user.status === 1);
        let existingAssignedUser = myTaskData?.assigned_to || []
        let existingAssignedData = []
        let existingVisibleUser = myTaskData?.visible_to || []
        let existingVisibleData = []

        // clickupUsersList && clickupUsersList.map(val => {
        filteredUsersList && filteredUsersList.map(val => {
            if (existingAssignedUser?.includes(val._id)) {
                existingAssignedData.push(val)
            }
            if (myTaskData?.category != "clickup") {
                if (existingVisibleUser?.includes(val._id)) {
                    existingVisibleData.push(val)
                }
            }

        })
        setSelectedAssignedUsers(existingAssignedData)
        setSelectedVisibleUsers(existingVisibleData)

        let uniqueAssignedUsersList = filteredUsersList.filter(val => {
            return !existingAssignedData.find(existingAssignedUser => existingAssignedUser._id === val._id);
        });
        let uniqueVisibleUsersList = filteredUsersList.filter(val => {
            return !existingVisibleData.find(existingVisibleUser => existingVisibleUser._id === val._id);
        });
        setAllAssignedUsersList(uniqueAssignedUsersList);
        setAllVisibleUserList(uniqueVisibleUsersList);
        // setClickupSelectedUsers(existingAssignedData)
    }, [myTaskData])

    useEffect(() => {
        if (myTaskData?.category == "clickup") {

            // let filteredUsersList = usersList.filter(user => user.status === 1);
            let existingAssignedUser = myTaskData?.assigned_to
            let existingAssignedData = []
            let existingVisibleUser = myTaskData?.visible_to
            let existingVisibleData = []

            clickupUsersList && clickupUsersList.map(val => {
                if (existingAssignedUser.includes(val.id)) {
                    existingAssignedData.push(val)
                }
                if (existingVisibleUser?.includes(val.id)) {
                    existingVisibleData.push(val)
                }


            })

            let uniqueAssignedUsersList = clickupUsersList.filter(val => {
                return !existingAssignedData.find(existingAssignedUser => existingAssignedUser.id === val.id);
            });

            setClickupAllUsersList(uniqueAssignedUsersList);
            setClickupSelectedUsers(existingAssignedData);
            setClickupVisibleToUsers(existingVisibleData);
        }

    }, [myTaskData])

    const removeAssignedUser = (user) => {
        let data = selectedAssignedUsers.filter(val => val._id !== user._id)
        setSelectedAssignedUsers(data)
        let uniqueAssignedUsersList = filteredUsersList.filter(val => {
            return !data.find(existingAssignedUser => existingAssignedUser._id === val._id);
        });
        setAllAssignedUsersList(uniqueAssignedUsersList);
    }
    const removeVisibleUser = (user) => {
        let data = selectedVisibleUsers.filter(val => val._id !== user._id)
        setSelectedVisibleUsers(data)
        let uniqueVisibleUsersList = filteredUsersList.filter(val => {
            return !data.find(existingVisibleUser => existingVisibleUser._id === val._id);
        });
        setAllVisibleUserList(uniqueVisibleUsersList)
    }

    const handleAssignedUserSelection = (e, user) => {
        e.preventDefault();
        const newArray = [...selectedAssignedUsers, user];
        setSelectedAssignedUsers(newArray);
        let data = allAssignedUsersList.filter((val) => val._id !== user._id);
        setAllAssignedUsersList(data);
        // document.addEventListener('mousedown', handleOutsideClick);
    };



    const handleClickupUserSelection = (e, user) => {
        e.preventDefault();
        const newArray = [...clickupSelectedUsers, user];
        setClickupSelectedUsers(newArray);
        let data = clickupAllUsersList.filter((val) => val.id !== user.id);
        setClickupAllUsersList(data);
        // document.addEventListener('mousedown', handleOutsideClick);
    };

    const removeClickupUser = (user) => {
        let data = clickupSelectedUsers.filter((val) => val.id !== user.id);
        setClickupSelectedUsers(data);
        let newArray = [...clickupAllUsersList];
        newArray.push(user);
        setClickupAllUsersList(newArray);
    };

    const handleVisibleUserSelection = (e, user) => {
        e.preventDefault();
        const newArray = [...selectedVisibleUsers, user];
        setSelectedVisibleUsers(newArray);
        let data = allVisibleUsersList.filter((val) => val._id !== user._id);
        setAllVisibleUserList(data);
        // document.addEventListener('mousedown', handleOutsideClick);
    };

    const handlePrioritySelection = (e, priority) => {
        e.preventDefault();
        setPriorityTask(priority);
        // setIsDropdownOpenPriority(false); // Close the dropdown after selection
    };
    const updateTaskDetails = async (e, value) => {
        e.preventDefault()
        let payload = new FormData();
        payload.append('id', myTaskData?._id);
        payload.append('task_name', updateMyTaskData?.task_name);
        payload.append('task_details', updateMyTaskData?.task_details);
        payload.append('due_date', updateMyTaskData?.due_date);
        payload.append('file', selectedImage);
        payload.append('priority', priorityTask);
        payload.append('dependent_task', updateMyTaskData?.dependent_task ? updateMyTaskData?.dependent_task : '');
        payload.append('status', value !== undefined ? value : currentStatus?.value);
        payload.append("selected_milestone", selectedMilestone?.value)
        // taskBelongstoStatus && taskBelongstoStatus.map((val) => {
        //     payload.append('task_status', val?.value);
        // })
        if (waitingForApproval) {
            payload.append("task_status[]", "waiting_for_approval")
        }
        selectedAssignedUsers && selectedAssignedUsers.map((user) => {
            payload.append("assigned_to[]", user._id);
        })

        selectedVisibleUsers &&
            selectedVisibleUsers.map((user) => {
                payload.append("visible_to[]", user._id);
            });
        try {
            const response = await apiServiceHandler("POST", "api/task/update", payload)
            // updateProject();
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Updated the Task")
            AddUserLogs(myTaskData?._id, "Updated this task", updateMyTaskData);
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }
    const updateClickupTaskDetails = async (e, value) => {
        e.preventDefault()
        let userData = localStorage.getItem("userData");
        userData = userData ? JSON.parse(userData) : {};
        let payload = {};
        let assignees = []
        clickupSelectedUsers &&
            clickupSelectedUsers.map((user) => {
                assignees.push(user.id);
            });
        const x2 = myTaskData?.assigned_to

        const rem = x2.filter(num => !assignees.includes(num));
        const add = assignees.filter(num => !x2.includes(num));

        const result = { add, rem };
        let priority = updateMyTaskData?.priority
        if (priorityTask == "Low") priority = 4
        if (priorityTask == "Medium") priority = 3
        if (priorityTask == "High") priority = 2



        let data =
        {
            name: updateMyTaskData?.task_name,
            description: updateMyTaskData?.task_details,
            assignees: result,
            due_date: new Date(updateMyTaskData?.due_date).getTime(),
            due_date_time: false,
            start_date_time: false,
            notify_all: true,
            parent: null,
            links_to: null,
            check_required_custom_fields: true,
            status: value !== undefined ? value : currentStatus?.value,
            priority: priority,

            dependent_task: updateMyTaskData?.dependent_task ? updateMyTaskData?.dependent_task : ''

        }
        payload["data"] = data;
        payload["uniqueUrl"] = userData?.unique_url;
        payload["id"] = myTaskData?._id;


        try {
            const response = await apiServiceHandler("POST", "api/task/update_task_list_clickup", payload)
            console.log(response)
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Updated the Task")
            AddUserLogs(myTaskData?._id, "Updated this task", updateMyTaskData);
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    const createAttachmentClickup = async (selectedFile) => {
        try {
            let userData = localStorage.getItem("userData");
            if (!userData) {
                throw new Error("User data not found in localStorage");
            }
            userData = JSON.parse(userData);


            if (!selectedFile instanceof File) {
                throw new Error("Selected file is invalid");
            }


            const payload = new FormData();
            payload.append('file', selectedFile);
            payload.append('uniqueUrl', userData.unique_url || '');
            payload.append('id', myTaskData?._id || '');

            const response = await apiServiceHandler("POST", "api/task/create_task_attachment", payload);
            console.log(response);
        } catch (error) {
            console.error("Error in createAttachmentClickup:", error);
        }
    };

    const statusCompletion = (e) => {
        if (myTaskData?.category != "clickup") {
            setCurrentStatus({ value: 'completed', label: 'completed' })
            updateTaskDetails(e, "completed")
        }
        else {
            setCurrentStatus({ value: 'complete', label: 'complete' })
            updateClickupTaskDetails(e, "complete")
        }

    }

    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}

    const handleTimeTracking = (value) => {
        // setOpenTimeTrackingModal(true)
        // dispatch(updateEditTaskData(value))
    }


    let senderUniqueUrl = userData?.unique_url

    const currentUserData = usersList.find(user => user.unique_url === senderUniqueUrl);

    const [commentsList, setCommentsList] = useState([])



    const fetchUserName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';

        if (myTaskData?.category != "clickup") {
            const user = usersList.find((user) => user._id === userId);

            if (user) {
                const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
                const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
                return `${firstName[0]}${lastName[0]}`;
            }
        }
        else {
            const user = clickupUsersList.find((user) => user.id === userId);
            if (user) {
                const name = user.initials
                return `${name}`;
            }
        }
        return 'Unknown';
    };
    const fetchUserFullName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';

        if (myTaskData?.category != "clickup") {
            const user = usersList.find((user) => user._id === userId);

            if (user) {
                const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
                const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
                return `${firstName[0]}${lastName[0]}`;
            }
        }
        else {
            const user = clickupUsersList.find((user) => user.id === userId);
            if (user) {
                const name = user.initials
                return `${name}`;
            }
        }
        return 'Unknown';
    };

    const fetchUserLogsName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        const user = usersList.find((user) => user._id === userId);

        if (user) {
            const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
            const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
            return `${firstName[0]}${lastName[0]}`;
        }

        return 'Unknown';
    };

    const fetchUserLogsFullName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        const user = usersList.find((user) => user._id === userId);
        if (user) {
            const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
            const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
            return `${firstName[0]}${lastName[0]}`;
        }

        return 'Unknown';
    };

    useEffect(() => {
        if (myTaskData?.category != "clickup") {
            const items = myTaskData?.comment.map((comment) => {
                const userName = fetchUserName(comment.userId);
                return userName.userName, comment.comment;
            });
            dispatch(getcommentList(items))
        }



    }, [myTaskData]);

    const addComment = async () => {


        if (commentText.trim() === '') {

            return;
        }
        const timestampDate = new Date();
        var timestampString = timestampString || timestampDate.getTime();

        const newComment = {
            text: commentText,
        };

        if (myTaskData?.category != "clickup") {
            let payload = {
                "userId": currentUserData?._id,
                "taskId": myTaskData?._id,
                "comment": newComment,
                "createdAt": timestampString
            }

            try {
                const response = await apiServiceHandler('POST', 'api/tasks/update/comment', payload);

                dispatch(updateRefreshTaskList(true))

                getCommentTask(myTaskData?._id)
                setCommentText('');
                AddUserLogs(myTaskData?._id, "added a comment on this task", newComment)
            } catch (err) {
                console.error(err);
            }
        }
        else {
            let payload = {
                "uniqueUrl": userData?.unique_url,
                "id": myTaskData?._id,
                "comment": newComment
            }

            try {
                const response = await apiServiceHandler('POST', 'api/tasks/add_task_comment', payload);

                dispatch(updateRefreshTaskList(true))

                getClickupComment(myTaskData?._id)
                setCommentText('');
                AddUserLogs(myTaskData?._id, "added a comment on this task", newComment)
            } catch (err) {
                console.error(err);
            }

        }

    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent default form submission
            addComment();
        }
    };

    const [editCommentIndex, setEditCommentIndex] = useState(-1);

    const handleEditComment = (e, index, text) => {
        e.preventDefault();
        setEditCommentIndex(index);
        setEditComment(text);
    };

    const [editComment, setEditComment] = useState('')

    const handleSaveComment = async (item) => {
        if (item.user_id !== currentUserData._id) {
            toastr.error("Only Current User can Edit")
            return
        }

        if (editComment.trim() === '') {
            return;
        }

        try {
            const updatedComment = {
                text: editComment,
            };

            let payload = {

                "taskId": myTaskData?._id,
                "comment": updatedComment,
                "createdAt": item.created_at,
            };

            const response = await apiServiceHandler('POST', 'api/tasks/update_comment', payload);

            dispatch(updateRefreshTaskList(true));
            setEditComment('');
            setEditCommentIndex(-1);
            getCommentTask(myTaskData?._id);

        } catch (err) {
            console.error(err);
        }


    };
    const handleEditClickupComment = async (item) => {
        // if (item.user_id !== currentUserData._id) {
        //     toastr.error("Only Current User can Edit")
        //     return
        // }

        if (editComment.trim() === '') {
            return;
        }

        try {
            const updatedComment = {
                text: editComment,
            };

            let payload = {

                "commentId": item?.commentId,
                "comment": editComment,
                // "createdAt": item.created_at,
            };

            const response = await apiServiceHandler('POST', 'api/tasks/edit_task_comment', payload);

            dispatch(updateRefreshTaskList(true));
            setEditComment('');
            setEditCommentIndex(-1);
            getClickupComment(myTaskData?._id);

        } catch (err) {
            console.error(err);
        }


    };


    const handleEditCommentChange = (event) => {
        setEditComment(event.target.value);
    };


    const getCommentTask = async (id) => {

        let payload = {
            "taskId": id
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/comment`, payload);

            setCommentsList(response)

        } catch (err) {
            console.error(err);
        }
    };

    function convertComment(comment) {
        return {
            comment: { text: comment.comment_text },
            user_id: comment.user.id,
            created_at: parseInt(comment.date),
            commentId: comment.id
        };
    }

    // Convert comments data to the desired format


    const getClickupComment = async (id) => {

        let payload = {
            "uniqueUrl": userData?.unique_url,
            "id": id
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/get_task_comment`, payload);


            const convertedComments = response.comments.map(convertComment);
            setCommentsList(convertedComments)

        } catch (err) {
            console.error(err);
        }
    };
    const deleteCommentTask = async (id, item) => {

        if (item.user_id !== currentUserData._id) {
            toastr.error("Only Current User can delete")
            return
        }
        let payload = {
            "taskId": id,
            "createdAt": item.created_at
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/delete_comment`, payload);

            // setCommentsList(response)
            getCommentTask(myTaskData?._id)

            // setCommentText('');
        } catch (err) {
            console.error(err);
        }

    };
    const deleteClickupCommentTask = async (e, item) => {
        e.preventDefault();
        // if (item.user_id !== currentUserData._id) {
        //     toastr.error("Only Current User can delete")
        //     return
        // }
        let payload = {
            "commentId": item.commentId,
            // "createdAt": item.created_at
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/delete_task_comment`, payload);

            // setCommentsList(response)
            getClickupComment(myTaskData?._id)

            // setCommentText('');
        } catch (err) {
            console.error(err);
        }

    };

    useEffect(() => {

        if (myTaskData?.category != "clickup") {
            getCommentTask(myTaskData?._id)
        }
        else {
            getClickupComment(myTaskData?._id)
        }

    }, [myTaskData])

    const getUserLogs = async (id) => {

        let payload = {
            "type_id": id,
            "type": "task",
        }

        try {
            const response = await apiServiceHandler('POST', `api/logs/get`, payload);

            dispatch(updateUserLogsList(response?.data))

        } catch (err) {
            console.error(err);
        }
    };
    const AddUserLogs = async (id, logType, taskData) => {

        let payload = {
            "type_id": id,
            "text": logType,
            "type": "task",
            "updatedTaskData": taskData,
        }

        try {
            const response = await apiServiceHandler('POST', `api/logs/add`, payload);

            dispatch(updateRefreshUserLogs(true))

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (activeTab === "logs") {
            getUserLogs(myTaskData?._id)
        }
    }, [myTaskData, activeTab])

    useEffect(() => {
        if (refreshUserLogs) {
            getUserLogs(myTaskData?._id)
            dispatch(updateRefreshUserLogs(false))
        }
    }, [refreshUserLogs])

    const colors = ['#F13A3A', '#3AF153', '#3A7CF1', '#F1C13A', '#A13AF1', '#F13AA1', '#34A853'];

    const getColorForUser = (userId) => {
        const userIdString = userId?.toString();
        // Use a simple hash function to get an index based on the user ID
        const hash = userIdString?.split('').reduce((acc, char) => char.charCodeAt(0) + (acc << 6) - acc, 0);
        const index = Math.abs(hash % colors.length);
        return colors[index];
    };

    const projectData = useSelector((state) => state.project.projectsData[0]);

    // const [statusOptionMilestone, setStatusOption] = useState([]);
    const [milestones, setMilestones] = useState();

    const handleSelectMilestone = (milestone) => {
        setSelectedMilestone(milestone);
    };
    useEffect(() => {

        const milestoneData = JSON.parse(projectData?.milestone[0]);
        const taskMilestones = [];

        milestoneData.map((milestone) =>
            taskMilestones.push({
                label: milestone?.milestone_name,
                value: milestone?.milestone_name,
            })
        );
        setMilestones(taskMilestones);
    }, [projectData]);

    const updateProject = async () => {
        const updatedMilestones = JSON.parse(projectData?.milestone).map((data) => {
            if (data.milestone_name == selectedMilestone.value) {
                return {
                    ...data,
                    status: currentStatus.value,
                    selected_milestone: selectedMilestone.value,
                };
            }

            return data
        });

        let payload = new FormData();

        projectData?.client_id &&
            projectData?.client_id.map((val) => {
                payload.append("client_id[]", val);
            });

        projectData?.project_user &&
            projectData?.project_user.map((val) => {
                payload.append("project_user[]", val);
            });

        payload.append("project_name", projectData?.project_name);
        payload.append("start_date", projectData?.start_date);
        payload.append("end_date", projectData?.end_date);
        payload.append("project_url", projectData?.project_url);
        payload.append("milestone", JSON.stringify(updatedMilestones));
        // payload.append("logo", projectData?.logo ? projectData?.logo : "empty");
        payload.append("status", projectData?.status);
        payload.append("extended_time", projectData?.extended_time);
        payload.append("id", projectData._id);
        payload.append("progress", projectData?.progress);

        try {
            const response = await apiServiceHandler(
                "POST",
                "api/project/update",
                payload
            );
            // toastr.success(response?.message);
            dispatch(updateRefreshProjectList(true));
            onClose();
        } catch (err) {
            toastr.error(err?.response?.data?.message);
            console.log(err);
        }
    };

    return (
        <div
            className={`modal fade grey-popup edit-task-viewmore ${isOpen ? "show backdrop" : " "}`}
            id="editTaskModal"
            tabindex="-1"
            aria-labelledby="taskModalLabel" aria-hidden="true"
            style={{ display: isOpen ? "block" : "none" }}
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content border-0">
                    <div className="modal-header" id="modal-header">
                        <nav aria-label="breadcrumb">
                            {/* <ol className="breadcrumb mb-0">
                                {projectList.map((client, clientIndex) => (
                                    <React.Fragment key={clientIndex}>
                                        <li className="breadcrumb-item">
                                            <a href="#">{client.client_name}</a>
                                        </li>
                                        {client.projects.map((project, projectIndex) => (
                                            <li className="breadcrumb-item" key={projectIndex}>
                                                <a href="#">{project.project_name}</a>
                                            </li>
                                        ))}
                                    </React.Fragment>
                                ))}
                                <li className="breadcrumb-item active" aria-current="page">Jurni App</li>
                            </ol> */}
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="#">{clientName}</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {selectedProject}
                                </li>
                            </ol>
                        </nav>
                        <div className="mark-done d-flex align-items-center mark-mobile">
                            <button type="button" className="mark-tick me-3 mark-complete-mobile" onClick={statusCompletion}><img src={TickWIcon} />Mark Complete</button>
                            <button type="button" className="mark-tick me-3" onClick={myTaskData?.category != "clickup" ? updateTaskDetails : updateClickupTaskDetails}>Update</button>
                            <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={CloseIcon} />
                            </button>
                        </div>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="task-head d-flex align-items-center justify-content-between">
                            <div className="task-head-left d-flex align-items-center">
                                {/* <button type="button" className="pending-tsk me-3">{updateMyTaskData?.status}</button> */}
                                <div className='new-task-mobile'><Select className={window.screen.width > 760 ? "me-3" : ""}
                                    options={myTaskData?.category === "clickup" ? clickupOptions : userData.role === 'client' ? clientOptions : options}
                                    name='status'
                                    value={currentStatus}
                                    onChange={(e) => handleStatus(e)} />
                                </div>


                                <div className="new-task-mobile"
                                    style={window.screen.width > 760 ? { width: "200px" } : {}}
                                >
                                    {myTaskData?.category != "clickup" && (
                                        <Select
                                            className="btn-color mx-md-3 mt-2 mt-md-0"
                                            options={milestones ? milestones : []}
                                            name="selected_milestone"
                                            value={selectedMilestone}
                                            onChange={(e) => handleSelectMilestone(e)}
                                            placeholder="Milestone..."
                                        />)}
                                </div>
                            </div>
                            <div className="task-head-right d-flex align-items-center">
                                <div className="task-assignto-label">Assigned To:</div>
                                {myTaskData?.category != "clickup" ? (<>
                                    <div className="assignee-icon dropdown me-4">
                                        <div
                                            className="dropdown-toggle"
                                            onClick={(e) => toggleDropdown(e, 1)}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div class="border-assignee">
                                                <img src={UserIcon} />
                                                <button type="submit" style={{ display: "none" }}>
                                                    <div className="user-icon">
                                                        <img src={CloseGreyIcon} />
                                                    </div>
                                                </button>
                                            </div>
                                            {selectedAssignedUsers.map((user) => (
                                                <div
                                                    className="border-assignee"
                                                    style={{ background: "#57A5FB", color: "#FFF" }}
                                                    key={user._id}
                                                >
                                                    {user.first_name[0] ? (
                                                        user.first_name[0]
                                                    ) : (
                                                        <img src={UserIcon} />
                                                    )}
                                                    <button type="submit">
                                                        <div className="user-remove">
                                                            <img
                                                                onClick={() => removeAssignedUser(user)}
                                                                src={CloseGreyIcon}
                                                                alt={`Remove ${user.first_name}`}
                                                            />
                                                        </div>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <ul
                                            className="dropdown-menu"
                                        >
                                            {allAssignedUsersList &&
                                                allAssignedUsersList.map((user) => (
                                                    <li key={user.id}>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            onClick={(e) => handleAssignedUserSelection(e, user)}
                                                        >
                                                            <span className="short-an">
                                                                {user.first_name[0]}
                                                            </span>{" "}
                                                            {user.first_name} {user.last_name}
                                                        </a>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div></>)
                                    :
                                    (<><div className="assignee-icon dropdown me-4">
                                        <div
                                            className="dropdown-toggle"
                                            onClick={(e) => toggleDropdown(e, 1)}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div class="border-assignee">
                                                <img src={UserIcon} />
                                                <button type="submit" style={{ display: "none" }}>
                                                    <div className="user-icon">
                                                        <img src={CloseGreyIcon} />
                                                    </div>
                                                </button>
                                            </div>
                                            {clickupSelectedUsers.map((user) => (
                                                <div
                                                    className="border-assignee"
                                                    style={{ background: "#57A5FB", color: "#FFF" }}
                                                    key={user.id}
                                                >
                                                    {user.username[0] ? (
                                                        user.username[0]
                                                    ) : (
                                                        <img src={UserIcon} />
                                                    )}
                                                    <button type="submit">
                                                        <div className="user-remove">
                                                            <img
                                                                onClick={() => removeClickupUser(user)}
                                                                src={CloseGreyIcon}
                                                                alt={`Remove ${user.username}`}
                                                            />
                                                        </div>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <ul
                                            className="dropdown-menu clickup-users"
                                        >
                                            {clickupAllUsersList &&
                                                clickupAllUsersList.map((user) => (
                                                    <li key={user.id}>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            onClick={(e) => handleClickupUserSelection(e, user)}
                                                        >
                                                            <span className="short-an">
                                                                {user.initials}
                                                            </span>{" "}
                                                            {user.username}
                                                            {/* {user.first_name user.last_name[0][1]}  */}
                                                        </a>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    </>)}
                            </div>

                            {myTaskData?.category != "clickup" && (<> <div className="task-head-right d-flex align-items-center">
                                <div className="task-assignto-label">Visible To:</div>
                                <div className="assignee-icon dropdown me-4">
                                    <div className="dropdown-toggle" onClick={(e) => toggleDropdown(e, 2)} data-bs-toggle="dropdown" aria-expanded="false">
                                        <div class="border-assignee"><img src={UserIcon} />
                                            <button type="submit" style={{ display: "none" }}><div className="user-icon"><img src={CloseGreyIcon} /></div></button>
                                        </div>
                                        {selectedVisibleUsers && selectedVisibleUsers.map((user) => (<div className="border-assignee" style={{ background: '#57A5FB', color: '#FFF' }} key={user._id}>
                                            {user?.first_name[0] ? user?.first_name[0] : <img src={UserIcon} />}
                                            <button type="submit"  ><div className="user-remove"><img onClick={() => removeVisibleUser(user)} src={CloseGreyIcon} alt={`Remove ${user.first_name}`} /></div></button>
                                        </div>
                                        ))}
                                    </div>
                                    <ul
                                        //ref={taskRef}
                                        className="dropdown-menu"
                                    // className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                                    // style={{ display: isDropdownOpen ? "block" : "none" }}
                                    >
                                        <div className="assign-users">
                                            <form>
                                                {/* <input type="text" name="search" placeholder="Search"
                                                    // value={searchText}   onChange={(e) => setSearchText(e.target.value)} 
                                                    className="form-control" /> */}
                                                {/* <button type="submit"><img src={SearchIcon} /></button> */}
                                            </form>
                                        </div>
                                        {allVisibleUsersList && allVisibleUsersList.map((user) => (<li key={user.id}><a className="dropdown-item" href="#" onClick={(e) => handleVisibleUserSelection(e, user)}><span className="short-an">{user.first_name[0]}</span> {user.first_name} {user.last_name}</a></li>
                                        ))}
                                    </ul>

                                </div>

                                <div className="task-head-right d-flex align-items-center">
                                    <div className="tt-track">
                                        <div className="head-tt">Time Tracking:</div>
                                        <div className="head-at">

                                            {/*   <button type="button" className="pause-icon"><img src={PauseIcon} /></button> */}
                                            <button type="button" className="no-default" style={{ cursor: 'default' }} onClick={() => handleTimeTracking()}>{convertArrayToMinutes(myTaskData.time_tracking)}</button>

                                        </div>
                                    </div>
                                </div>
                            </div></>)}
                        </div>
                        <div className="task-body bg-white mb-0">
                            <div className="mb-2">
                                <div className="col-md-12 mb-2">
                                    <input type="text" name="task_name" placeholder="task name" className="form-control" value={updateMyTaskData?.task_name} onChange={(e) => handleChange(e)} />
                                </div>
                                <textarea className="form-control" placeholder="Task Details" rows="5" name='task_details' value={updateMyTaskData?.task_details} onChange={(e) => handleChange(e)}></textarea>
                            </div>
                            <div className="mb-4">
                                <ul className="list-unstyled task-ul m-0">
                                    <li>
                                        <div className="task-label">Created:</div>
                                        <div className="task-info">{formattedDate}</div>
                                    </li>
                                    <li>
                                        <div className="task-label">Due Date:</div>
                                        <input type="date" className="form-control" placeholder="due date" name='due_date' value={updateMyTaskData?.due_date || ""} onChange={(e) => handleChange(e)} required />

                                    </li>
                                    <li>
                                        <div className="task-label">Priority:</div>
                                        <div className="task-info task-pt">

                                            <div className="btn-group">
                                                {/* <Select
                                                    // className=""
                                                    options={priorityOptions}
                                                    name='priority_task'
                                                    // isMulti
                                                    value={priorityTask}
                                                    onChange={(e) => handlePrioritySelection(e)}
                                                /> */}
                                                <button type="button" className="btn btn-sm btn-color" style={{ background: '#3EBB11' }}>{priorityTask}</button>
                                                <button type="button" onClick={(e) => toggleDropdown(e, 3)} className="btn dropdown-toggle dropdown-toggle-split btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                </button>
                                                <ul className="dropdown-menu"
                                                // className={`dropdown-menu ${isDropdownOpenPriority ? "show" : ""}`}
                                                // style={{ display: isDropdownOpenPriority ? "block" : "none" }}
                                                >
                                                    <li><a className="dropdown-item" onClick={(e) => handlePrioritySelection(e, "Low")} href="#">Low</a></li>
                                                    <li><a className="dropdown-item" onClick={(e) => handlePrioritySelection(e, "Medium")} href="#">Medium</a></li>
                                                    <li><a className="dropdown-item" onClick={(e) => handlePrioritySelection(e, "High")} href="#">High</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-4">
                                <label>Sub Tasks</label>
                                <input type="text" className="form-control" placeholder="Enter sub task" name="dependent_task" value={updateMyTaskData?.dependent_task != 'undefined' ? updateMyTaskData?.dependent_task : ''} onChange={(e) => handleChange(e)} />
                            </div>
                            <div class="mb-3">
                                <label>Upload Image:</label>
                                <div className="mb-4 attachments-area">
                                    {selectedImage && <div className="attachment-task">
                                        <div className="attachment-image">
                                            <img src={URL.createObjectURL(selectedImage)} />
                                        </div>

                                    </div>}
                                    <div className="upload-file-project image-dash text-center">
                                    <input
                                        onChange={handleImageChange}
                                        className="box-file-hidded"
                                        type="file"
                                        id="taskFile"
                                        accept="image/*" // Specify that only image files are allowed
                                    />
                                    <div className="upload-txt-project">
                                        <label htmlFor="taskFile">
                                            <img className="m-3" src={PlusIcon} alt="Upload" />
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="comment-log-tab d-flex align-items-center justify-content-between mb-3">
                            <ul className="nav" id="pills-tab" role="tablist">
                                <li className={`nav-item ${activeTab === 'comments' ? 'active' : ''}`} role="presentation">
                                    <button className={`nav-link ${activeTab === 'comments' ? 'active' : ''}`} onClick={() => { setActiveTab('comments') }} id="pills-homes-tab" data-bs-toggle="pill" data-bs-target="#pills-homes" type="button" role="tab" aria-controls="pills-homes" aria-selected="true">Comments
                                    </button>
                                </li>
                                <li className={`nav-item ${activeTab === 'logs' ? 'active' : ''}`} role="presentation">
                                    <button className="nav-link" onClick={() => { setActiveTab('logs'); }} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Activity
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className={`tab-pane fade ${activeTab === 'comments' ? 'show active' : ''}`} id="pills-homes" role="tabpanel" aria-labelledby="pills-homes-tab" tabindex="0">
                                <div className="task-created mb-4 chat-bg all-comments">

                                    <ul className="m-0 list-unstyled">
                                        {commentsList?.map((item, index) => (
                                            <li key={index} className="task-created-inner ticket-comments">
                                                {myTaskData?.category != "clickup" ? <><span className="short-label" style={{ background: getColorForUser(item.user_id) }}>{fetchUserName(item.user_id)}</span>
                                                    {item.user_id === currentUserData._id && editCommentIndex === index ? (
                                                        <><input
                                                            type="text"
                                                            className='form-control'
                                                            value={editComment}
                                                            onChange={(e) => handleEditCommentChange(e)}
                                                        />
                                                            <button type='button' className='save-btn' onClick={() => handleSaveComment(item)}>Save</button>
                                                        </>
                                                    ) : (
                                                        <p className="full-label mb-0"> {item.comment.text}</p>
                                                    )}</> :
                                                    <><span className="short-label" style={{ background: getColorForUser(item.user_id) }}>{fetchUserName(item.user_id)}</span>
                                                        {editCommentIndex === index ? (
                                                            <><input
                                                                type="text"
                                                                className='form-control'
                                                                value={editComment}
                                                                onChange={(e) => handleEditCommentChange(e)}
                                                            />
                                                                <button type='button' className='save-btn' onClick={() => handleEditClickupComment(item)}>Save</button>
                                                            </>
                                                        ) : (
                                                            <p className="full-label mb-0"> {item.comment.text}</p>
                                                        )}</>}

                                                <div className="dropdown edit-chat">
                                                    <button type="button" style={{ background: '#FFFFFF' }} className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={ChatsdotsIcon} />
                                                    </button>
                                                    {myTaskData?.category != "clickup" ? (<ul className="dropdown-menu">
                                                        {item.user_id === currentUserData._id && editCommentIndex === index ? (<li>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                onClick={() => handleSaveComment(item)}
                                                            >
                                                                Save
                                                            </a>
                                                        </li>) : (
                                                            <li>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                    onClick={(e) => handleEditComment(e, index, item.comment.text)}
                                                                >
                                                                    Edit
                                                                </a>
                                                            </li>
                                                        )}

                                                        <li><a className="dropdown-item" href="#" onClick={() => deleteCommentTask(myTaskData._id, item)}>Delete</a></li>
                                                    </ul>) : (<ul className="dropdown-menu">
                                                        {editCommentIndex === index ? (<li>
                                                            {/* <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                    onClick={() => handleEditClickupComment(item)}
                                                                >
                                                                    Save
                                                                </a> */}
                                                        </li>) : (
                                                            <li>
                                                                {/* <a
                                                                        className="dropdown-item"
                                                                        href="#"
                                                                        onClick={(e) => handleEditComment(e, index, item.comment.text)}
                                                                    >
                                                                        Edit
                                                                    </a> */}
                                                            </li>
                                                        )}

                                                        <li><a className="dropdown-item" href="#" onClick={(e) => deleteClickupCommentTask(e, item)}>Delete</a></li>
                                                    </ul>)}
                                                </div>
                                            </li>))}

                                    </ul>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'logs' ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <div className="task-created mb-4 chat-bg all-comments">
                                    <ul className="m-0 list-unstyled">
                                        {usersLogsList.map((log) => (
                                            <li className="task-created-inner-logs" key={log._id}>

                                                <span className="short-userlog-label" style={{ background: getColorForUser(log._id) }}>
                                                    {fetchUserLogsName(log.id)}
                                                </span>
                                                <p>{fetchUserLogsFullName(log.id)} {log.text} {log.type === 'task'} {"at"} {new Date(log.createdAt).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                                                {/* <p>{log.text}</p>
                                                    <p>Type: {log.type}</p>
                                                    <p>Created At: {new Date(log.createdAt).toLocaleString()}</p> */}
                                                {/* Add other properties you want to display */}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="chat-area">
                            <div className="write-chat">
                                <div className="chat-inner">
                                    <input type="text" className="form-control" placeholder="Type Comment..." onKeyDown={handleKeyPress} value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                                    <button type="button" className="chat-attachment"><img src={AttachGreyIcon} /></button>
                                </div>
                                <button type="button" className="no-default send-chat" onClick={addComment}><img src={SendIcon} /></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div >
    )
}

export default EditTask