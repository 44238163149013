import React, { useEffect, useState } from 'react'
import TrashIcon from '../../assests/images/trash-red.svg';
import Attachment from '../../assests/images/attachment.png';
import Close from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import PlusIcon from '../../assests/images/plus-icon.svg';
import TickWIcon from '../../assests/images/tick-white.svg';
import { getTicketsCommentList, updateRefreshTicketsList } from '../../redux/chatsandtickets';
import ChatsdotsIcon from '../../assests/images/chat-dots.svg';
import AttachGreyIcon from '../../assests/images/attachment-grey.svg';
import SendIcon from '../../assests/images/send.svg';
import { updateRefreshTaskList } from '../../redux/task';

const EditTickets = ({ isOpen, onClose }) => {

    const [category, setCategory] = useState([])
    const [selectedImage, setSelectedImage] = useState(null);
    const [ticketsData, setTicketsData] = useState({})
    const [ticketPriority, setTicketPriority] = useState([])
    const [ticketStatus, setTicketStatus] = useState([])
    const projects = useSelector(state => state.project?.showProjectList) || []
    const projectList = useSelector(state => state.project.showProjectList)
    const myTicketData = useSelector(state => state.tickets.editData)
    const [updateMyTicketData, setUpdateMyTicketData] = useState({})
    const dispatch = useDispatch()
    const [selectedUsers, setSelectedUsers] = useState([]);
    const usersList = useSelector(state => state.allUserData.users)
    const [allUsersList, setAllUsersList] = useState([])
    const [commentText, setCommentText] = useState('');
    const [commentsList, setCommentsList] = useState([])


    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}
    let currentUserRole = usermData.role

    const clickupUsersList = JSON.parse(localStorage.getItem("Clickup_Users")) || [];

    const categoryOptions = [
        { value: 'Sales', label: 'Sales' },
        { value: 'Support', label: 'Support' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Other', label: 'Other' },
    ]
    const priorityOptions = [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' },
    ]

    const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Assigned', label: 'Assigned' },
        { value: 'No Issues', label: 'No Issues' }
    ]
    const statusClickupOptions = [
        { value: 'to do', label: 'To Do' },
        { value: 'complete', label: 'Complete' },
       
    ]

    const handleChangeStatus = (selectedValues) => {
        setTicketStatus(selectedValues)
    };
    const handleChangeCategory = (selectedValues) => {
        setCategory(selectedValues)
    };
    const handleChangePriority = (selectedValues) => {
        setTicketPriority(selectedValues)
    };

    const [clickupSelectedUsers, setClickupSelectedUsers] = useState([]);
    const [clickupAllUsersList, setClickupAllUsersList] = useState([]);

    useEffect(() => {
        if (clickupAllUsersList.length == 0) {
            setClickupAllUsersList(clickupUsersList)
        }
    }, [clickupUsersList])

    const handleAssignedUsersClickup = (selectedValue) => {
        setClickupSelectedUsers(selectedValue)
    }

    useEffect(() => {
        if (myTicketData) {
            const allData = { ...myTicketData };
            let formattedDate1 = ""
            if (myTicketData.due_date) {
                const due_date = new Date(myTicketData.due_date);
                formattedDate1 = due_date ? due_date?.toISOString().split('T')[0] : ""
            }

            allData.due_date = formattedDate1;
            // Set the modified copy in your state
            setUpdateMyTicketData(allData);

        }

    }, [myTicketData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUpdateMyTicketData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: window.screen.width < 760 ? 348 : 720, // Set the width as per your requirement
        }),
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (myTicketData?.ticket_category == "clickup") {

            createAttachmentClickup(file)
        }
        setSelectedImage(file)
    };

    useEffect(() => {
        // Set initial selected users from Redux when component mounts
        setAllUsersList(usersList.filter(user => user.status === 1));
    }, [usersList]);

    const transformData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.first_name} ${item.last_name}`
        }));
    }
    const options = transformData(usersList) || [];

    const handleAssignedUsers = (selectedValue) => {
        setSelectedUsers(selectedValue)
    }

    const transformDataClickup = (data) => {
        return data.map((item) => ({
            ...item,
            value: item.id,
            label: `${item.username}`
        }));
    }
    const optionsClickup = transformDataClickup(clickupUsersList) || [];


    const createdDate = myTicketData?.createdAt ? new Date(myTicketData?.createdAt) : null;

    const formattedDate = createdDate instanceof Date && !isNaN(createdDate)
        ? createdDate.toISOString().split('T')[0]
        : '';

    useEffect(() => {
        let data = []
        let existingUser = myTicketData?.assigned_to || []
        let existingData = []
        if (myTicketData?.ticket_category != "clickup") {
            options && options.map(val => {
                if (existingUser.includes(val._id)) {
                    existingData.push(val)
                }
            })
            setSelectedUsers(existingData)
        } else {
            optionsClickup && optionsClickup.map(val => {
                if (existingUser.includes(val.id)) {
                    existingData.push(val)
                }
            })
            setClickupSelectedUsers(existingData)
        }
    }, [myTicketData])

    useEffect(() => {

        if (myTicketData.ticket_status) {
            setTicketStatus({
                label: myTicketData?.ticket_status,
                value: myTicketData?.ticket_status
            })

        }
        if (myTicketData.priority) {
            setTicketPriority({
                label: myTicketData?.priority,
                value: myTicketData?.priority
            })

        }
        if (myTicketData.category) {
            setCategory({
                label: myTicketData?.category,
                value: myTicketData?.category
            })

        }
        if (myTicketData?.supported_doc[0]) {
            // setSelectedImage(myTicketData?.supported_doc[0])
        }

    }, [myTicketData]);


    const updateTicket = async (e, value) => {
        e.preventDefault()
        let payload = new FormData();
        payload.append("ticket_subject", updateMyTicketData?.ticket_subject)
        payload.append("ticket_description", updateMyTicketData?.ticket_description)
        payload.append("ticket_status", value !== undefined ? value : ticketStatus?.value)
        payload.append('file', selectedImage ? selectedImage : 'empty');
        payload.append("id", myTicketData?._id)
        payload.append("category", category.value)
        payload.append("priority", ticketPriority.value)
        selectedUsers && selectedUsers.map((user) => {
            payload.append("assigned_to[]", user._id);
        })

        try {
            const response = await apiServiceHandler("POST", "api/tickets/update", payload)
            console.log(response)
            dispatch(updateRefreshTicketsList(true))
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Updated the Ticket")
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    const updateClickupTicket = async (e, value) => {
        e.preventDefault()
        let userData = localStorage.getItem("userData");
        userData = userData ? JSON.parse(userData) : {};
        let payload = {};
        let assignees = []
        clickupSelectedUsers &&
            clickupSelectedUsers.map((user) => {
                assignees.push(user.id);
            });
        const x2 = myTicketData?.assigned_to

        const rem = x2.filter(num => !assignees.includes(num));
        const add = assignees.filter(num => !x2.includes(num));

        const result = { add, rem };
        let priority = 4
        if (ticketPriority.value == "Low") priority = 4
        if (ticketPriority.value == "Medium") priority = 3
        if (ticketPriority.value == "High") priority = 2
        let data =
        {
            name: updateMyTicketData?.ticket_subject,
            description: updateMyTicketData?.ticket_description,
            assignees: result,
            due_date: new Date(updateMyTicketData?.due_date).getTime(),
            due_date_time: false,
            start_date_time: false,
            notify_all: true,
            parent: null,
            links_to: null,
            check_required_custom_fields: true,
            status: value !== undefined ? value : ticketStatus?.value,
            priority: priority,
            category: category.value
            // tags: [category.value]
        }
        payload["data"] = data;
        payload["uniqueUrl"] = userData?.unique_url;
        payload["id"] = myTicketData?._id;
        // payload["tags"] = [category.value]


        try {
            const response = await apiServiceHandler("POST", "api/task/update_task_list_clickup", payload)
            console.log(response)
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Updated the Ticket")
            // AddUserLogs(myTaskData?._id, "Updated this Ticket", updateMyTaskData);
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }


    const statusCompletion = (e) => {
        setTicketStatus({ value: 'Completed', label: 'Completed' })
        if (myTicketData?.ticket_category != "clickup")
            updateTicket(e, "Completed")
        else
            updateClickupTicket(e, "complete")
    }

    let userData = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : {}
    let senderUniqueUrl = userData?.unique_url

    const createAttachmentClickup = async (selectedFile) => {
        try {
            let userData = localStorage.getItem("userData");
            if (!userData) {
                throw new Error("User data not found in localStorage");
            }
            userData = JSON.parse(userData);


            if (!selectedFile instanceof File) {
                throw new Error("Selected file is invalid");
            }


            const payload = new FormData();
            payload.append('file', selectedFile);
            payload.append('uniqueUrl', userData.unique_url || '');
            payload.append('id', myTicketData?._id || '');

            const response = await apiServiceHandler("POST", "api/task/create_task_attachment", payload);
            console.log(response);
        } catch (error) {
            console.error("Error in createAttachmentClickup:", error);
        }
    };

   

    const currentUserData = usersList.find(user => user.unique_url === senderUniqueUrl);

    const fetchUserName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        if (myTicketData?.ticket_category != "clickup") {
            const user = usersList.find((user) => user._id === userId);

            if (user) {
                const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
                const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
                return `${firstName[0]}${lastName[0]}`;
            }
        }
        else {
            const user = clickupUsersList.find((user) => user.id === userId);
            if (user) {
                const name = user.initials
                return `${name}`;
            }
        }
        return 'Unknown';
    };
    const fetchUserFullName = (userId) => {
        // const user = usersList.find((user) => user._id === userId);
        // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
        if (myTicketData?.ticket_category != "clickup") {
            const user = usersList.find((user) => user._id === userId);

            if (user) {
                const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
                const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
                return `${firstName[0]}${lastName[0]}`;
            }
        }
        else {
            const user = clickupUsersList.find((user) => user.id === userId);
            if (user) {
                const name = user.initials
                return `${name}`;
            }
        }
        return 'Unknown';
    };

    // useEffect(() => {
    //     const items = myTicketData.comment.map((comment) => {
    //         const userName = fetchUserName(comment.userId);
    //         return userName.userName, comment.comment;
    //     });

    //     dispatch(getTicketsCommentList(items))

    // }, [myTicketData.comment]);

    const addComment = async () => {

        if (commentText.trim() === '') {

            return;
        }
        const timestampDate = new Date();
        var timestampString = timestampString || timestampDate.getTime();

        const newComment = {
            text: commentText,
        };
        if (myTicketData?.ticket_category != "clickup") {
            let payload = {
                "userId": currentUserData?._id,
                "ticketId": myTicketData?._id,
                "comment": newComment,
                "createdAt": timestampString
            }

            try {
                const response = await apiServiceHandler('POST', 'api/tickets/update/comment', payload);

                dispatch(updateRefreshTaskList(true))

                getCommentTask(myTicketData?._id)
                setCommentText('');
                // AddUserLogs(myTaskData?._id, "added a comment on this task", newComment)
            } catch (err) {
                console.error(err);
            }
        }
        else {
            let payload = {
                "uniqueUrl": usermData?.unique_url,
                "id": myTicketData?._id,
                "comment": newComment
            }

            try {
                const response = await apiServiceHandler('POST', 'api/tasks/add_task_comment', payload);

                dispatch(updateRefreshTaskList(true))

                getClickupComment(myTicketData?._id)
                setCommentText('');
                // AddUserLogs(myTaskData?._id, "added a comment on this task", newComment)
            } catch (err) {
                console.error(err);
            }

        }

    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent default form submission
            addComment();
        }
    };

    const [editCommentIndex, setEditCommentIndex] = useState(-1);

    const handleEditComment = (e, index, text) => {
        e.preventDefault();
        setEditCommentIndex(index);
        setEditComment(text);
    };

    const [editComment, setEditComment] = useState('')

    const handleSaveComment = async (item) => {
        if (item.user_id !== currentUserData._id) {
            toastr.error("Only Current User can Edit")
            return
        }

        if (editComment.trim() === '') {
            return;
        }

        try {
            const updatedComment = {
                text: editComment,
            };

            let payload = {

                "ticketId": myTicketData?._id,
                "comment": updatedComment,
                "createdAt": item.created_at,
            };

            const response = await apiServiceHandler('POST', 'api/tickets/update_comment', payload);

            dispatch(updateRefreshTicketsList(true));
            setEditComment('');
            setEditCommentIndex(-1);
            getCommentTask(myTicketData?._id);

        } catch (err) {
            console.error(err);
        }


    };

    const handleEditClickupComment = async (item) => {
        // if (item.user_id !== currentUserData._id) {
        //     toastr.error("Only Current User can Edit")
        //     return
        // }

        if (editComment.trim() === '') {
            return;
        }

        try {
            const updatedComment = {
                text: editComment,
            };

            let payload = {

                "commentId": item?.commentId,
                "comment": updatedComment,
                // "createdAt": item.created_at,
            };

            const response = await apiServiceHandler('POST', 'api/tasks/edit_task_comment', payload);

            dispatch(updateRefreshTaskList(true));
            setEditComment('');
            setEditCommentIndex(-1);
            getClickupComment(myTicketData?._id);

        } catch (err) {
            console.error(err);
        }


    };


    const handleEditCommentChange = (event) => {
        event.preventDefault();
        setEditComment(event.target.value);
    };


    const getCommentTask = async (id) => {

        let payload = {
            "ticketId": id
        }

        try {
            const response = await apiServiceHandler('POST', `api/tickets/comment`, payload);

            setCommentsList(response)

        } catch (err) {
            console.error(err);
        }
    };

    function convertComment(comment) {
        return {
            comment: { text: comment.comment_text },
            user_id: comment.user.id,
            created_at: parseInt(comment.date),
            commentId: comment.id
        };
    }

    // Convert comments data to the desired format


    const getClickupComment = async (id) => {

        let payload = {
            "uniqueUrl": userData?.unique_url,
            "id": id
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/get_task_comment`, payload);


            const convertedComments = response.comments.map(convertComment);
            setCommentsList(convertedComments)

        } catch (err) {
            console.error(err);
        }
    };

    const deleteCommentTask = async (id, item) => {

        if (item.user_id !== currentUserData._id) {
            toastr.error("Only Current User can delete")
            return
        }
        let payload = {
            "ticketId": id,
            "createdAt": item.created_at
        }

        try {
            const response = await apiServiceHandler('POST', `api/tickets/delete_comment`, payload);

            // setCommentsList(response)
            getCommentTask(myTicketData?._id)

            // setCommentText('');
        } catch (err) {
            console.error(err);
        }

    };

    const deleteClickupCommentTask = async (e, item) => {
        e.preventDefault();
        // if (item.user_id !== currentUserData._id) {
        //     toastr.error("Only Current User can delete")
        //     return
        // }
        let payload = {
            "commentId": item.commentId,
            // "createdAt": item.created_at
        }

        try {
            const response = await apiServiceHandler('POST', `api/task/delete_task_comment`, payload);

            // setCommentsList(response)
            getClickupComment(myTicketData?._id)

            // setCommentText('');
        } catch (err) {
            console.error(err);
        }

    };

    useEffect(() => {

        if (myTicketData?.ticket_category != "clickup") {
            getCommentTask(myTicketData?._id)
        }
        else {
            getClickupComment(myTicketData?._id)
        }

    }, [myTicketData])

    const colors = ['#F13A3A', '#3AF153', '#3A7CF1', '#F1C13A', '#A13AF1', '#F13AA1', '#34A853'];

    const getColorForUser = (userId) => {
        // Use a simple hash function to get an index based on the user ID
        const userIdString = userId?.toString();
        const hash = userIdString?.split('').reduce((acc, char) => char.charCodeAt(0) + (acc << 6) - acc, 0);
        const index = Math.abs(hash % colors.length);
        return colors[index];
    };
    return (
        <div>
            <div className={`modal fade tickets-modal edit-ticket-viewmore ${isOpen ? "show backdrop" : " "}`} style={{ display: isOpen ? "block" : "none" }} id="newTicketsModal" tabindex="-1" aria-labelledby="newTicketsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content border-0">
                        <div class="modal-header">
                            <h2 class="modal-title">Edit Ticket</h2>
                            <div className="mark-done d-flex align-items-center">
                                <button type="button" className="mark-tick me-3" onClick={statusCompletion}><img src={TickWIcon} />Mark Complete</button>
                                <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={Close} />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body bg-white">
                            <div class="mb-2">
                                <label>Selected Project</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>{myTicketData?.project_name}</option>
                                </select>
                            </div>
                            <div class="mb-2">
                                <label>Ticket Subject</label>
                                <input type="text" class="form-control" placeholder="Enter Ticket Subject" name='ticket_subject' value={updateMyTicketData?.ticket_subject} onChange={(e) => handleChange(e)} />
                            </div>
                            <div class="mb-2 ticket-new-select">
                                <label>Category</label>
                                <div className="dropdown atc-dropdown">
                                    <Select
                                        className="btn-color"
                                        options={categoryOptions}
                                        name='category'
                                        styles={customStyles}
                                        value={category}
                                        onChange={(e) => handleChangeCategory(e)}
                                    />

                                </div>

                            </div>
                            <div class="mb-2 ticket-new-select">
                                <label>Assigned To</label>
                                {myTicketData?.ticket_category != "clickup" ? <Select
                                    className="btn-color"
                                    options={options}
                                    name='assigned_to'
                                    isMulti
                                    styles={customStyles}
                                    value={selectedUsers}
                                    onChange={handleAssignedUsers}
                                /> :
                                    <Select
                                        className="btn-color"
                                        options={optionsClickup}
                                        name='assigned_to'
                                        isMulti
                                        styles={customStyles}
                                        value={clickupSelectedUsers}
                                        onChange={handleAssignedUsersClickup}
                                    />}

                            </div>
                            <div class="mb-2">
                                <label>Description</label>
                                <textarea class="form-control" placeholder="Add Description" rows="3" name='ticket_description' value={updateMyTicketData?.ticket_description} onChange={(e) => handleChange(e)}></textarea>
                            </div>
                            <div class="mb-2 ticket-new-select">
                                <label>Priority</label>
                                <Select
                                    className="btn-color"
                                    options={priorityOptions}
                                    name='priority'
                                    styles={customStyles}
                                    value={ticketPriority}
                                    onChange={(e) => handleChangePriority(e)}
                                />

                            </div>
                            {currentUserRole != "client" && <div class="mb-2 ticket-new-select">
                                <label>Status</label>
                                {myTicketData?.ticket_category != "clickup" ? <Select
                                    className="btn-color"
                                    options={statusOptions}
                                    name='ticket_status'
                                    styles={customStyles}
                                    value={ticketStatus}
                                    onChange={(e) => handleChangeStatus(e)}
                                /> : <Select
                                className="btn-color"
                                options={statusClickupOptions}
                                name='ticket_status'
                                styles={customStyles}
                                value={ticketStatus}
                                onChange={(e) => handleChangeStatus(e)}
                            />}

                            </div>}
                            <div className="mb-3">
                                <ul className="list-unstyled task-ul m-0">
                                    <li>
                                        <div className="task-label">Created By:</div>
                                        <div className="task-info">{myTicketData?.created_by}</div>
                                    </li>
                                    <li>
                                        <div className="task-label">Created at: </div>
                                        <div className="task-info"> {formattedDate}</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3">
                                <label>Upload Image</label>
                                <div className="mb-4 attachments-area">

                                    {selectedImage &&
                                        <div className="attachment-task">
                                            <div className="attachment-image">
                                                <img src={URL.createObjectURL(selectedImage)} />
                                            </div>
                                        </div>}
                                        <div className="upload-file-project image-dash text-center">                                       
                                        <input
                                            onChange={handleImageChange}
                                            className="box-file-hidded"
                                            type="file"
                                            id="taskFile"
                                        // accept="image/*" // Specify that only image files are allowed
                                        />
                                        <div className="upload-txt-project">
                                        <label htmlFor="taskFile">
                                            <img className="m-3" src={PlusIcon} alt="Upload" />
                                        </label>

                                    </div>
                                    </div>

                                </div>
                            </div>
                            <div className="task-created mb-4 chat-bg all-comments">
                                <ul className="m-0 list-unstyled">
                                    {commentsList && commentsList?.map((item, index) => (
                                        <li className="task-created-inner ticket-comments" key={index} >
                                            {myTicketData?.ticket_category != "clickup" ? <><span className="short-label" style={{ background: getColorForUser(item.user_id) }}>{fetchUserName(item.user_id)}</span>
                                                {item.user_id === currentUserData._id && editCommentIndex === index ? (
                                                    <><input
                                                        type="text"
                                                        className='form-control'
                                                        value={editComment}
                                                        onChange={(e) => handleEditCommentChange(e)}
                                                    />
                                                        <button type='button' className='save-btn' onClick={() => handleSaveComment(item)}>Save</button>
                                                    </>
                                                ) : (
                                                    <p className="full-label mb-0"> {item.comment.text}</p>
                                                )}</> :
                                                <><span className="short-label" style={{ background: getColorForUser(item.user_id) }}>{fetchUserName(item.user_id)}</span>
                                                    {editCommentIndex === index ? (
                                                        <><input
                                                            type="text"
                                                            className='form-control'
                                                            value={editComment}
                                                            onChange={(e) => handleEditCommentChange(e)}
                                                        />
                                                            <button type='button' className='save-btn' onClick={() => handleEditClickupComment(item)}>Save</button>
                                                        </>
                                                    ) : (
                                                        <p className="full-label mb-0"> {item.comment.text}</p>
                                                    )}</>}
                                            <div className="dropdown edit-chat">
                                                <button type="button" style={{ background: '#FFFFFF' }} className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={ChatsdotsIcon} />
                                                </button>
                                                {myTicketData?.ticket_category != "clickup" ? (<ul className="dropdown-menu">
                                                    {item.user_id === currentUserData._id && editCommentIndex === index ? (<li>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            onClick={() => handleSaveComment(item)}
                                                        >
                                                            Save
                                                        </a>
                                                    </li>) : (
                                                        <li>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                onClick={(e) => handleEditComment(e,index, item.comment.text)}
                                                            >
                                                                Edit
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li><a className="dropdown-item" href="#" onClick={() => deleteCommentTask(myTicketData._id, item)}>Delete</a></li>
                                                </ul>) : 
                                                (<ul className="dropdown-menu">
                                                {item.user_id === currentUserData._id && editCommentIndex === index ? (<li>
                                                    {/* <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => handleSaveComment(item)}
                                                    >
                                                        Save
                                                    </a> */}
                                                </li>) : (
                                                    <li>
                                                        {/* <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            onClick={() => handleEditClickupComment(index, item.comment.text)}
                                                        >
                                                            Edit
                                                        </a> */}
                                                    </li>
                                                )}
                                                <li><a className="dropdown-item" href="#" onClick={(e) => deleteClickupCommentTask(e, item)}>Delete</a></li>
                                            </ul>)}
                                            </div>
                                        </li>))}

                                </ul>
                            </div>
                            <div className="chat-area">
                                <div className="write-chat">
                                    <div className="chat-inner">
                                        <input type="text" className="form-control" placeholder="Type Comment..." onKeyDown={handleKeyPress} value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                                        <button type="button" className="chat-attachment"><img src={AttachGreyIcon} /></button>
                                    </div>
                                    <button type="button" onClick={addComment} className="send-chat" ><img src={SendIcon} /></button>
                                </div>
                            </div>
                            {myTicketData?.ticket_category != "clickup" ? <button onClick={updateTicket} type="submit" class="tc-submit">Submit</button> : <button onClick={updateClickupTicket} type="submit" class="tc-submit">Submit</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTickets
