import React, { useEffect, useState } from 'react'
import "./auth.css"
import Logo from "../../assests/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom'
import apiServiceHandler from '../../service/apiService'
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updatClickupListIds, updatClickupLoginData, updatClickupSpaceIds, updateClickupJLLProjectsList } from '../../redux/clickup'
import { useDispatch } from 'react-redux'

const Login = () => {
    // const baseUrl = "http://34.204.71.100"
    // const baseUrl = "https://joinlivelook.com"
    // const baseUrlLogin = `${baseUrl}/api/auth/login`
    const { id } = useParams()
    const navigate = useNavigate()
    const [firstDigitOtp, setFirstDigitOtp] = useState("")
    const [secondDigitOtp, setSecondDigitOtp] = useState("")
    const [thirdDigitOtp, setThirdDigitOtp] = useState("")
    const [fourthDigitOtp, setFourthDigitOtp] = useState("")
    const dispatch = useDispatch()
    const digitValidate = (ele) => {
        ele.target.value = ele.target.value.replace(/[^0-9a-zA-Z]/g, '');
    }
    const tabChange = (val) => {
        const ele = document.querySelectorAll('input')
        if (ele[val - 1]?.value !== '') {
            ele[val]?.focus();
        } else if (ele[val - 1]?.value === '') {
            if (ele[val - 2]) {
                ele[val - 2].focus();
            } else if (ele[val - 3]) {
                ele[val - 3].focus();
            }
        }
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        const otp_value = firstDigitOtp + secondDigitOtp + thirdDigitOtp + fourthDigitOtp;
        const payload = {
            loginCode: otp_value,
            uniqueUrl: id
        }
        const payload2 = {
            uniqueUrl: id
        }
        try {
            const response = await apiServiceHandler('POST', "api/auth/login", payload);

            // const resClickup = await apiServiceHandler('POST', "api/auth/login_clickup", payload2);

            // console.log("response1", resClickup)
            handleClickupLogin();

            localStorage.setItem('JLL_TOKEN', response?.userData?.secret_code)

            const userDetails = {
                first_name: response?.userData.first_name,
                email: response?.userData.email,
                unique_url: response?.userData.unique_url
            }
            localStorage.setItem('userDetails', JSON.stringify(userDetails))

            const paymentPayload = {
                uniqueUrl: response.userData?.owned_by ? response.userData.owned_by : id
            }
            const payment_details = await apiServiceHandler('POST', 'api/payment/paypal/payment-details', paymentPayload);

            // const userCount = await apiServiceHandler("GET", `api/auth/all_users_list`, {});
            const userCount = await apiServiceHandler(
                "POST",
                "api/auth/user/users/count",
                { unique_url: response.userData.owned_by ? response.userData.owned_by : response.userData.unique_url }
            );

            const projectCount = await apiServiceHandler(
                "POST",
                "api/auth/user/project/count",
                { company_id: response.userData?.parent_company_id ? response.userData.parent_company_id : response.userData.company_id }
            );

            // Adding 3 more days to Payment Date as leverage
            const paymentDate = new Date(payment_details?.data?.next_billing_time);
            paymentDate.setDate(paymentDate.getDate() + 3);

            const nextBillingDate = new Date(paymentDate) > new Date();

            if (response.userData?.owned_by) {
                if (nextBillingDate) {
                    localStorage.setItem('userData', JSON.stringify(response.userData))
                    return navigate(`/dashboard/${id}`)
                } else {
                    throw { error: 'Payment Pending' }
                }
            } else if (payment_details.data) {
                if ((payment_details.data.status === 'ACTIVE' || payment_details.data.status === 'CANCELLED' || payment_details.data.status === 'SUSPENDED' || payment_details.data.status === 'Free_Trial') && nextBillingDate) {
                    localStorage.setItem('userData', JSON.stringify(response?.userData))
                    navigate(`/dashboard/${id}`)
                } else {
                    navigate(`/choose-plans`, { state: { firstName: response?.userData.first_name, unique_url: response?.userData.unique_url, currentUsers: userCount.data, currentProject: projectCount.data, newUser: false, paymentDetails: payment_details.data } })
                }
            } else {
                navigate(`/choose-plans`, { state: { firstName: response?.userData.first_name, newUser: true, unique_url: response?.userData.unique_url, email: response?.userData.email } })
            }
        } catch (e) {
            // console.log(e);
            if (e.error === 'Payment Pending') {
                toastr.error('Payment Pending, Contact your Admin');
            } else {
                toastr.error(e?.response?.data?.message);
            }
        }
    }

    const handleClickupLogin = async () => {

        const payload2 = {
            uniqueUrl: id
        }
        try {
            const resClickup = await apiServiceHandler('POST', "api/auth/login_clickup", payload2);

            const data = resClickup.clickUpData;
            // console.log("response1", data)
            localStorage.setItem('JLL_SpaceId', data[1])
            // localStorage.setItem('Clickup_SpaceIds', JSON.stringify(data[3]))
            localStorage.setItem('Clickup_Users', JSON.stringify(data[4]))
            let payload = {
                "spaceId": data[1]
            }
            try {
                const response = await apiServiceHandler("POST", "api/project/get_clickup_projects", payload);
                // console.log(response, "fetchClickProjects")
                dispatch(updateClickupJLLProjectsList(response?.data))

            } catch (err) {
                console.log(err);
            }


        } catch (e) {
            console.log(e);
            // toastr.error(e?.response?.data?.message);
        }
    }

    useEffect(() => {
        localStorage.clear()

        return () => {
            // second
        }
    }, [])



    // let tabChange = function (val) {
    //     let ele = document.querySelectorAll('input');
    //     if (ele[val - 1].value != '') {
    //         ele[val].focus()
    //     } else if (ele[val - 1].value == '') {
    //         ele[val - 2].focus()
    //     }
    // }

    // useEffect(() => {


    //   return () => {

    //   }
    // }, [])

    return (
        <div class="bg-image">
            <div class="bg-box">
                <div class="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="enterOTP" class="enter-opt shadow">
                    <h1 class="mb-1">Enter Code</h1>
                    <p>Welcome to <strong>Live Look</strong></p>
                    <form onSubmit={handleLogin}>
                        <div class="opt-col my-4 pb-1">
                            <input onChange={(e) => setFirstDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(1)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setSecondDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(2)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setThirdDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(3)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setFourthDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(4)} maxLength={1} placeholder="" />
                        </div>
                        <button type="submit" class="submit-btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Login
