import React, { useEffect, useState } from 'react'
import TrashIcon from '../../assests/images/trash-red.svg';
import Attachment from '../../assests/images/attachment.png';
import Close from '../../assests/images/close.svg';
import apiServiceHandler from '../../service/apiService';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { updateRefreshTicketsList } from '../../redux/chatsandtickets';
import PlusIcon from '../../assests/images/plus-icon.svg';
import ChatsdotsIcon from '../../assests/images/chat-dots.svg';
import AttachGreyIcon from '../../assests/images/attachment-grey.svg';
import SendIcon from '../../assests/images/send.svg';
import { updateRefreshTaskList } from '../../redux/task';

const NewTickets = ({ isOpen, onClose }) => {

    const [category, setCategory] = useState([])
    const [ticketStatus, setTicketStatus] = useState([{ value: 'Pending', label: 'Pending' }])
    const [ticketPriority, setTicketPriority] = useState([])
    const [ticketsData, setTicketsData] = useState({})
    const projects = useSelector(state => state.project?.showProjectList) || []
    const projectList = useSelector(state => state.project.showProjectList)
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const usersList = useSelector(state => state.allUserData.users)
    const [selectedImage, setSelectedImage] = useState(null);
    const [allUsersList, setAllUsersList] = useState([])
    const [commentText, setCommentText] = useState('');
    const [commentsList, setCommentsList] = useState([])
    const [ticketComments, setTicketComments] = useState([])

    const projectsData =
    useSelector((state) => state.project?.projectsData[0]) || [];
    const FolderSpaces = useSelector(state => state.clickup?.clickupJLLProjectsList) || []
    const activeProject = useSelector((state) => state.project?.projectsData[0]) || ""

    const isActiveProjectInClickup = Object.keys(FolderSpaces).some(projectName =>
        projectName === activeProject?.project_name
    );

    let usermData = localStorage.getItem("userData")
    usermData = usermData ? JSON.parse(usermData) : {}
    let currentUserRole = usermData.role
    let UniqueUrl = usermData?.unique_url

    const clickupUsersList = JSON.parse(localStorage.getItem("Clickup_Users")) || [];


    const categoryOptions = [
        { value: 'Sales', label: 'Sales' },
        { value: 'Support', label: 'Support' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Other', label: 'Other' },
    ]

    const priorityOptions = [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' },
    ]

    const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Assigned', label: 'Assigned' },
        { value: 'No Issues', label: 'No Issues' }
    ]
    const statusClickupOptions = [
        { value: 'to do', label: 'To Do' },
        { value: 'complete', label: 'Complete' },
       
    ]

    const handleChangeCategory = (selectedValues) => {
        setCategory(selectedValues)
    };
    const handleChangeStatus = (selectedValues) => {
        setTicketStatus(selectedValues)
    };
    const handleChangePriority = (selectedValues) => {
        setTicketPriority(selectedValues)
    };

    useEffect(() => {
        // Set initial selected users from Redux when component mounts
        setAllUsersList(usersList.filter(user => user.status === 1));
    }, [usersList]);

    const transformData = (data) => {
        return data.map((item) => ({
            ...item,
            value: item._id,
            label: `${item.first_name} ${item.last_name}`
        }));
    }
    const transformDataClickup = (data) => {
        return data.map((item) => ({
            ...item,
            value: item.id,
            label: `${item.username}`
        }));
    }
    const optionsClickup = transformDataClickup(clickupUsersList) || [];
    
    const options = transformData(allUsersList) || [];

    const handleAssignedUsers = (selectedValue) => {
        setSelectedUsers(selectedValue)
    }

    const [clickupSelectedUsers, setClickupSelectedUsers] = useState([]);
    const [clickupAllUsersList, setClickupAllUsersList] = useState([]);

    useEffect(() => {
        if (clickupAllUsersList.length == 0) {
            setClickupAllUsersList(clickupUsersList)
        }
    }, [clickupUsersList])

    const handleAssignedUsersClickup = (selectedValue) => {
        setClickupSelectedUsers(selectedValue)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTicketsData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the selected file

        setSelectedImage(file);

    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: window.screen.width < 760 ? 220 : 720,
        }),
    };

    const addNewTicket = async () => {
        let payload = new FormData();
        payload.append("ticket_subject", ticketsData?.ticket_subject)
        payload.append("ticket_description", ticketsData?.ticket_description)
        payload.append("category", category?.value)
        payload.append("priority", ticketPriority?.value)
        payload.append('file', selectedImage ? selectedImage : 'empty');
        payload.append("project_id", projects[0]?._id)
        payload.append("ticket_status", ticketStatus?.value);
        selectedUsers && selectedUsers.map((user) => {
            payload.append("assigned_to[]", user._id);
        })

        ticketComments.forEach((commentObj) => {
            const { comment, userId, createdAt } = commentObj;


            const commentPayload = {
                comment: comment,
                userId: userId,
                createdAt: createdAt,
            };


            payload.append("comment[]", JSON.stringify(commentPayload));
        });

        try {
            const response = await apiServiceHandler("POST", "api/tickets", payload)
            console.log(response)
            dispatch(updateRefreshTicketsList(true))
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Created the Ticket")
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    const [selectedClickupTicket, setSelectedClickupTicket] = useState([]);

    const handleSelectMilestone = (milestone) => {
        setSelectedClickupTicket(milestone);
    };
    const [showClickupTicketSelect, setShowClickupTicketSelect] = useState(true); // Initial state

    const handleCheckboxChange = (event) => {
        try {
            // Update state only if event target is a checkbox
            if (event.target.type === 'checkbox') {
                setShowClickupTicketSelect(!event.target.checked);
            } else {
                console.error('Unexpected event type for checkbox:', event.target.type);
            }
        } catch (error) {
            console.error('Error handling checkbox change:', error);
        }
    };

    const addNewClickupTicket = async () => {
        if (clickupSelectedUsers.length === 0) {
            toastr.error("Please assign the task to a user.");
            return;
          }
          let payload = {};
          let assignees = []
          clickupSelectedUsers &&
            clickupSelectedUsers.map((user) => {
              assignees.push(user.id);
            });
          let priority = 4
          if (ticketPriority == "Low") priority = 4
          if (ticketPriority == "Medium") priority = 3
          if (ticketPriority == "High") priority = 2
          let data =
          {
            name: ticketsData?.ticket_subject,
            description: ticketsData?.ticket_description,
            assignees: assignees,
            due_date: new Date(ticketsData?.due_date).getTime(),
            due_date_time: false,
            start_date_time: false,
            notify_all: true,
            parent: null,
            links_to: null,
            check_required_custom_fields: true,
            // status: ticketStatus.value,
            priority: priority,
            category: category.value
          }
          payload["data"] = data;
          payload["folderId"] = FolderSpaces[projectsData?.project_name];
          payload["uniqueUrl"] = UniqueUrl;
          payload["type"] = ["tickets"]

        // ticketComments.forEach((commentObj) => {
        //     const { comment, userId, createdAt } = commentObj;


        //     const commentPayload = {
        //         comment: comment,
        //         userId: userId,
        //         createdAt: createdAt,
        //     };


        //     payload.append("comment[]", JSON.stringify(commentPayload));
        // });

        try {
            const response = await apiServiceHandler("POST", "api/tasks/create_task_clickup", payload)
            console.log(response)
            dispatch(updateRefreshTaskList(true))
            onClose()
            toastr.success("Created the Ticket")
        } catch (err) {
            toastr.error(err?.response?.data?.message)
            console.log(err)
        }

    }

    // let userData = localStorage.getItem("userData")
    // userData = userData ? JSON.parse(userData) : {}
    // let senderUniqueUrl = userData?.unique_url

    // const currentUserData = usersList.find(user => user.unique_url === senderUniqueUrl);

    // const fetchUserName = (userId) => {
    //     // const user = usersList.find((user) => user._id === userId);
    //     // return user ? `${user.first_name[0]} ${user.last_name[0]}` : 'Unknown';
    //     const user = usersList.find((user) => user._id === userId);
    //     if (user) {
    //         const firstName = user.first_name[0].toUpperCase() + user.first_name.slice(1);
    //         const lastName = user.last_name[0].toUpperCase() + user.last_name.slice(1);
    //         return `${firstName[0]}${lastName[0]}`;
    //     }


    //     return 'Unknown';
    // };

    // const handleKeyPress = (event) => {
    //     if (event.key === "Enter") {
    //         event.preventDefault(); // Prevent default form submission
    //         addTicketComment();
    //     }
    // };

    // const addTicketComment = () => {
    //     const timestampDate = new Date();
    //     var timestampString = timestampString || timestampDate.getTime();

    //     let comments = ticketComments
    //     comments.push({"comment": commentText,
    //         "userId": currentUserData?._id,
    //         "createdAt": timestampString
    //     })
    //     setTicketComments(comments)
    // }

    return (
        <div>
            <div className={`modal fade tickets-modal add-ticket-viewmore ${isOpen ? "show backdrop" : " "}`} style={{ display: isOpen ? "block" : "none" }} id="newTicketsModal" tabindex="-1" aria-labelledby="newTicketsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content border-0">
                        <div class="modal-header">
                            <h2 class="modal-title">Create Ticket</h2>
                            <div className="mark-done d-flex align-items-center">
                            {isActiveProjectInClickup && (<label htmlFor="showMilestone" className="me-3">
                                <input
                                    type="checkbox"
                                    id="showMilestone"
                                    className="me-1"
                                    checked={!showClickupTicketSelect}
                                    onChange={handleCheckboxChange}
                                />
                                Sync Ticket With Clickup
                            </label>)}
                            <button type="button" onClick={onClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close} />
                            </button>
                            </div>
                        </div>
                        <div class="modal-body bg-white">
                            <div class="mb-2">
                                <label>Selected Project</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>{projectList[0]?.project_name}</option>
                                    {/* {projectList.map((project, index) => (
                                        <option disabled key={index} value={project}>{project?.project_name}</option>
                                    ))} */}

                                </select>
                            </div>
                            <div class="mb-2">
                                <label>Ticket Subject</label>
                                <input type="text" class="form-control" placeholder="Enter Ticket Subject" name='ticket_subject' value={ticketsData?.ticket_subject} onChange={(e) => handleChange(e)} />
                            </div>
                           <div class="mb-2">
                                <label>Category</label>
                                <div className="dropdown atc-dropdown">
                                    <Select
                                        className="btn-color"
                                        options={categoryOptions}
                                        name='category'
                                        styles={customStyles}
                                        value={category}
                                        onChange={(e) => handleChangeCategory(e)}
                                    />

                                </div>

                            </div>
                            <div class="mb-2 ticket-new-select">
                                <label>Assigned To</label>
                                {showClickupTicketSelect ? <Select
                                    className="btn-color"
                                    options={options}
                                    name='assigned_to'
                                    isMulti
                                    styles={customStyles}
                                    value={selectedUsers}
                                    onChange={handleAssignedUsers}
                                /> : 
                                <Select
                                    className="btn-color"
                                    options={optionsClickup}
                                    name='assigned_to'
                                    isMulti
                                    styles={customStyles}
                                    value={clickupSelectedUsers}
                                    onChange={handleAssignedUsersClickup}
                                />}

                            </div>
                            <div class="mb-2">
                                <label>Description</label>
                                <textarea class="form-control" placeholder="Add Description" rows="3" name='ticket_description' value={ticketsData?.ticket_description} onChange={(e) => handleChange(e)}></textarea>
                            </div>
                            <div class="mb-2 ticket-new-select">
                                <label>Priority</label>
                                <Select
                                    className="btn-color"
                                    options={priorityOptions}
                                    name='priority'
                                    styles={customStyles}
                                    value={ticketPriority}
                                    onChange={(e) => handleChangePriority(e)}
                                />

                            </div>
                            {currentUserRole != "client" && <div class="mb-2">
                                <label>Status</label>
                                {showClickupTicketSelect ? <Select
                                    className="btn-color"
                                    options={[{ value: 'Pending', label: 'Pending' }, { value: 'Assigned', label: 'Assigned' }]}
                                    // options={statusOptions}
                                    name='ticket_status'
                                    styles={customStyles}
                                    value={ticketStatus}
                                    // isDisabled={true}
                                    onChange={(e) => handleChangeStatus(e)}
                                /> : <Select
                                className="btn-color"
                                // options={[{ value: 'Pending', label: 'Pending' }, { value: 'Assigned', label: 'Assigned' }]}
                                options={statusClickupOptions}
                                name='ticket_status'
                                styles={customStyles}
                                value={ticketStatus}
                                // isDisabled={true}
                                onChange={(e) => handleChangeStatus(e)}
                            />}

                            </div>}
                            {showClickupTicketSelect && <div class="mb-3">
                                <label>Upload Image :</label>
                                <div className="mb-4 attachments-area">

                                    {selectedImage &&
                                        <div className="attachment-task">
                                            <div className="attachment-image">
                                                <img src={URL.createObjectURL(selectedImage)} />
                                            </div>
                                        </div>}
                                   
                                         <div className="upload-file-project image-dash text-center">
                                        <input
                                            onChange={handleImageChange}
                                            className="box-file-hidded"
                                            type="file"
                                            id="taskFile"
                                        // accept="image/*" // Specify that only image files are allowed
                                        />
                                        <div className="upload-txt-project">
                                        <label htmlFor="taskFile">
                                            <img className="m-3" src={PlusIcon} alt="Upload" />
                                        </label>
                                    </div>

                                    </div>

                                </div>
                                
                            </div>}
                            {/* <div className="task-created mb-4 chat-bg">
                                <ul className="m-0 list-unstyled">
                                    {commentsList && commentsList?.map((item, index) => (
                                        <li className="task-created-inner">
                                            <span className="short-label" style={{ background: '#F13A3A' }}>{fetchUserName(item.user_id)}</span>
                                            {item.user_id === currentUserData._id && editCommentIndex === index ? (
                                                <><input
                                                    type="text"
                                                    className='form-control'
                                                    value={editComment}
                                                    onChange={(e) => handleEditCommentChange(e)}
                                                />
                                                    <button type='button' className='save-btn' onClick={() => handleSaveComment(item)}>Save</button>
                                                </>
                                            ) : (
                                                <p className="full-label mb-0"> {item.comment.text}</p>
                                         )} 
                                            <div className="dropdown edit-chat">
                                                <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={ChatsdotsIcon} />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    {item.user_id === currentUserData._id && editCommentIndex === index ? (<li>
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                            onClick={() => handleSaveComment(item)}
                                                        >
                                                            Save
                                                        </a>
                                                    </li>) : (
                                                        <li>
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                                onClick={() => handleEditComment(index, item.comment.text)}
                                                            >
                                                                Edit
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li><a className="dropdown-item" href="#">Edit</a></li>
                                                    <li><a className="dropdown-item" href="#">Reply</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => deleteCommentTask(myTicketData._id, item)}>Delete</a></li>
                                                </ul>
                                            </div>
                                        </li>))}

                                </ul>
                            </div> */}
                            {/* <div className="chat-area">
                                <div className="write-chat">
                                    <div className="chat-inner">
                                        <input type="text" className="form-control" placeholder="Type Comment..." onKeyDown={handleKeyPress} value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                                        <button type="button" className="chat-attachment"><img src={AttachGreyIcon} /></button>
                                    </div>
                                    <button type="button" onClick={addTicketComment} className="send-chat" ><img src={SendIcon} /></button>
                                </div>
                            </div> */}
                            {!showClickupTicketSelect ? <button onClick={addNewClickupTicket} type="submit" class="tc-submit">Submit Ticket</button> 
                            : <button onClick={addNewTicket} type="submit" class="tc-submit">Submit</button> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewTickets
