import React, { useEffect, useRef, useState } from "react";
import Folder from "../../assests/images/folder.png";
import Pdf from "../../assests/images/pdf.svg";
import Doc from "../../assests/images/doc.svg";
import NewFileFolder from "./NewFolderFile";
import File from "../../assests/images/simpleFile.svg";
import { useDropzone } from "react-dropzone";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from "../../service/apiService";
import { useDispatch, useSelector } from "react-redux";
import {
  updateRefreshFolderData,
  updateRefreshFolders,
} from "../../redux/documents";
import Dots from "../../assests/images/vertical-dots.svg";
import Spinner from 'react-bootstrap/Spinner';

const AllFiles = (props) => {
  const {
    listRef,
    displayList,
    folders,
    files,
    getAllFiles,
    setShowPageFlag,
    handleDeleteFolder,
    handleChildRenameFolder,
    indexData,
    handleShareFolder,
  } = props;

  const documentsPopup = useSelector((state) => state.viewmore.documentsPopup);

  const folderIndex = useSelector((state) => state.documents.folderIndex);

  const crumbsData = useSelector((state) => state.documents.crumbsData);
  const [showFileInput, setShowFileInput] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllFiles(folderIndex ? folderIndex : crumbsData[0]?._id);
  }, [documentsPopup]);

  const dispatch = useDispatch();

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const onDrop = async (acceptedFile) => {
    const file = acceptedFile[0];
    setShowFileInput(false);
    setLoading(true);

    let payload = new FormData();

    payload.append(`${file?.name}`, file);
    payload.append("folderId", indexData);

    try {
      let response = [];

      response = await apiServiceHandler(
        "POST",
        "api/file/management/add/file",
        payload
      );
      dispatch(updateRefreshFolderData(true));
      dispatch(updateRefreshFolders(true));

      toastr.success(response?.message);
    } catch (err) {
      console.log("Error", err);
      toastr.error('Unable to upload file!');
    } finally {
      setLoading(false);
    }
  };

  const [contextMenuVisible, setContextMenuVisible] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleContextMenu = (e, file) => {
    e.preventDefault();
    setSelectedFile(file);
    setContextMenuPosition(true);
    setContextMenuVisible(file);
  };

  const handleDelete = async (index) => {
    // if (!selectedFile) return;
    try {
      let payload = {
        "folderId": indexData,
        "indexFile": index
      };

      let response = await apiServiceHandler(
        "POST",
        "api/file/management/delete/file",
        payload
      );
      dispatch(updateRefreshFolderData(true));
      dispatch(updateRefreshFolders(true));
      toastr.success(response?.message);
    } catch (err) {
      console.log("Error", err);
    }
    setContextMenuPosition(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuVisible !== null) {
        const isOutside = !event.target.closest(".list-item");
        if (isOutside) {
          setContextMenuVisible(null);
          setContextMenuPosition(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenuVisible]);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: [ "images/*", "text/*", "doc", "docx", "xlsx", "pdf"],
  });

  const handleClick = () => {
    setShowFileInput(true);
  };

  return (
    <>
      <div class="row align-items-center">
        <div
          className="col-lg-4 mb-4 column-css"
          onClick={handleClick}
        >
          <form {...getRootProps()} style={{ cursor: "pointer" }}>
            <div class="upload-file text-center">
              {showFileInput ? (
                <input {...getInputProps()} id="fileInput" style={{ display: "none" }} />
              ) : null}
              {/* <input className="box-file" id="file" /> */}
              {isDragActive ? (
                <p className="upload-txt">Drop your image files here</p>
              ) : (
                <p clasName="upload-txt">
                  Drag file here or
                  <label for="file"> upload from your computer</label>
                </p>
              )}
              {loading ? (
                <div>
                  <div className='error-icon'><Spinner animation="border" /></div>
                  <p className="mb-3 text-center">uploading file...</p>
                </div>
              ) : null}
              <p class="mb-0">
                JPG, PNG, PDF, DOCX, AVI, MKV, MP4, MP3 up to 5MB
              </p>
            </div>
          </form>
        </div>
        <div class="col-lg-8">
          <div class="files-listed" style={{ overflowY: "scroll", maxHeight: "220px" }}>
            <div class="file-folder list-type">
              <div class="row g-0">
                <div
                  class="col-2"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                    width: "auto",
                  }}
                  ref={listRef}
                >
                  {folders &&
                    folders?.map((folder) => (
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                        <div
                          key={folder?._id}
                          class="folder-type"
                        >
                          <div
                            className="dots"
                            onClick={() => displayList(folder._id)}
                          >
                            <img src={Dots} />
                          </div>
                          <div
                            class="file-image"
                            onDoubleClick={() => {
                              getAllFiles(folder._id);
                              setShowPageFlag("allFiles");
                            }}
                          >
                            <img src={Folder} />
                          </div>
                          {folder.listStatus && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                padding: "5px 18px",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
                                zIndex: 1,
                                position: "absolute",
                                left: 70,
                                top: 30,
                                height: "120px",
                                borderRadius: "6px",
                                background: "#fff",
                              }}
                            >
                              <div
                                className="list-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  getAllFiles(folder._id);
                                  setShowPageFlag("allFiles");
                                }}
                              >
                                Open
                              </div>
                              <div
                                className="list-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleShareFolder(folder._id)}
                              >
                                Share
                              </div>
                              <div
                                className="list-item"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleChildRenameFolder(folder._id)
                                }
                              >
                                Rename
                              </div>
                              <div
                                className="list-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteFolder(folder._id)}
                              >
                                Delete
                              </div>
                            </div>
                          )}
                        </div>
                        <label class="file-name text-limit">{folder?.folder_name}</label>
                      </div>
                    ))}
                  {files &&
                    files?.map((file, index) => (
                      <div
                        key={index}
                        onDoubleClick={() => {
                          openInNewTab(file?.path);
                        }}
                      // onContextMenu={(e) => handleContextMenu(e, index)}
                      >
                        <div class="folder-type file-type">
                          <div className="dots" onClick={(e) => handleContextMenu(e, index)}>
                            <img src={Dots} alt="Options" />
                          </div>
                          {contextMenuPosition && (
                            <div
                              style={{
                                display: contextMenuVisible === index ? "block" : "none",
                                padding: "3px 12px", // Adjusted padding to make it smaller
                                alignItems: "flex-start",
                                boxShadow: "2px 2px 5px rgba(0,0,0,0.5)",
                                zIndex: 9999,
                                position: "absolute",
                                top: 0,
                                right: 30,
                                height: "auto", // Removed fixed height
                                borderRadius: "6px",
                                background: "#fff",
                                width: "70px"
                              }}
                            >
                              <div
                                className="list-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </div>

                            </div>
                          )}

                          {file.name.split(".").slice(-1) == "jpg" ||
                            file.name.split(".").slice(-1) == "png" ||
                            file.name.split(".").slice(-1) == "jpeg" ? (
                            <img
                              style={{ width: "80px", height: '80px' }}
                              src={file?.path ? file?.path : File}
                              alt=""
                            />
                          ) : file.name.split(".").slice(-1) == "pdf" ? (
                            <img width="80px" height="80px" src={Pdf} alt="" />
                          ) : file.name.split(".").slice(-1) == "txt" ? (
                            <img width="80px" height="80px" src={Pdf} alt="" />
                          ) : file.name.split(".").slice(-1) == "doc" ||
                            file.name.split(".").slice(-1) == "docx" ? (
                            <img width="80px" height="80px" src={Doc} alt="" />
                          ) : (
                            <img width="80px" height="80px" src={File} alt="" />
                          )}
                        </div>

                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {file?.name.length > 10 ? `${file?.name.substr(0, 8)}...` : file?.name}
                        </div>

                      </div>


                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AllFiles;
