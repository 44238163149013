import React from 'react'
import CtaIcon from '../../assests/icons/cta.png';
import ProjectDashIcon from '../../assests/icons/project-dashboard.png';
import UpdatesIcon from '../../assests/icons/updates.png';
import SecuredIcon from '../../assests/icons/secured.png';
import ChatIcon from '../../assests/icons/chat.png';
import HeaderPage from './header';
import FooterPage from './footer';


const FeaturesPage = () => {
    return (
        <div>
            <HeaderPage />
            <section class="inner-title py-4 text-center">
                <div class="container">
                    <h1 class="m-0">Features</h1>
                </div>
            </section>


            <section class="features-section py-5 bg-white">
                <div class="container">
                    <div class="features-text mb-4">
                        <h2 class="text-center">Prioritize instantly with project dashboards</h2>
                        <p class="text-center w-75 mx-auto mb-0">Get a detailed overview of project and team progress in real time with livelook's project dashboard. Here’s a look at the features you’ll have at your fingertips with LiveLook:</p>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            For Clients
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Projects
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Tasks and Appointments
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Time Tracking and Reports
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Support Tickets and Chats
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-8">
                            <img src={ProjectDashIcon} />
                        </div>
                    </div>

                    <div class="two-btns d-flex align-items-center justify-content-center mt-4">
                        <a href="/contact-us" class="border-btn blue-border me-3">Contact Us</a>
                        <a href="/signup" class="filled-btn blue-filled">Try Live Look for Free</a>
                    </div>

                </div>
            </section>



            <section class="features-opt text-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 mb-4 mb-md-0">
                            <div class="features-col bg-white">
                                <img src={UpdatesIcon} />
                                    <h2>Updates</h2>
                                    <h3>Real Time Updates</h3>
                                    <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                    <a href="#!">Learn More</a>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4 mb-md-0">
                            <div class="features-col bg-white">
                                <img src={SecuredIcon} />
                                    <h2>Secured</h2>
                                    <h3>Secured Platform</h3>
                                    <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                    <a href="#!">Learn More</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="features-col bg-white">
                                <img src={ChatIcon} />
                                    <h2>Chat</h2>
                                    <h3>Encrypted Chat</h3>
                                    <p>There are many variations of passages of lorem ipsum dolor amet available.</p>
                                    <a href="#!">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="home-faq pt-5 bg-white">
                <div class="container">
                    <h2>Frequently Asked Questions</h2>
                    <div class="row">
                        <div class="col-md-6 mb-3 pb-3">
                            <ul class="faq-list list-unstyled m-0 p-0">
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul class="faq-list list-unstyled m-0 p-0">
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                                <li>
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</h3>
                                    <div class="faq-answer"><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="book-a-demo py-5 bg-white">
                <div class="container">
                    <div class="book-inner shadow-sm">
                        <div class="row align-items-center">
                            <div class="col-md">
                                <img src={CtaIcon} />
                            </div>
                            <div class="col-md text-center">
                                <h2>Get started with Join Live Look</h2>
                                <p>Access our guide and discover everything your company needs to become compliant.</p>
                                <a href="" class="filled-btn blue-filled">Book a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterPage />

        </div>
    )
}

export default FeaturesPage
