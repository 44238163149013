import React, { useEffect, useState } from 'react'
import './otp.css';
import Logo from "../../assests/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
// import { baseUrl } from '../../service/apiService';
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import apiServiceHandler from '../../service/apiService';

const OtpVerifyCode = () => {
    // const baseUrl = "http://34.204.71.100"
    const baseUrl = "https://joinlivelook.com"
    const baseUrlOtp = `${baseUrl}/api/auth/signup/verify`
    // const { id } = useParams()
    const navigate = useNavigate()
    const signupData = JSON.parse(localStorage.getItem('signupData'));
    const userData = JSON.parse(localStorage.getItem('responseData'));
    const [firstDigitOtp, setFirstDigitOtp] = useState("")
    const [secondDigitOtp, setSecondDigitOtp] = useState("")
    const [thirdDigitOtp, setThirdDigitOtp] = useState("")
    const [fourthDigitOtp, setFourthDigitOtp] = useState("")
    const digitValidate = (ele) => {
        ele.target.value = ele.target.value.replace(/[^0-9a-zA-Z]/g, '');
    }
    const tabChange = (val) => {
        const ele = document.querySelectorAll('input')
        if (ele[val - 1]?.value !== '') {
            ele[val]?.focus();
        } else if (ele[val - 1]?.value === '') {
            if (ele[val - 2]) {
                ele[val - 2].focus();
            } else if (ele[val - 3]) {
                ele[val - 3].focus();
            }
        }
    };
    const handleSignupVerify = async (e) => {
        e.preventDefault();
        const otp_value = firstDigitOtp + secondDigitOtp + thirdDigitOtp + fourthDigitOtp;
        const payload = {
            otp: otp_value,
            secret: localStorage.getItem('secret'),
        }
        try {
            const response = await axios.post(baseUrlOtp, payload);
            console.log(response)
            toastr.success("OTP Verified successfully")
            if (response?.data?.status === true) {
                // toastr.success(response?.message)
                // const id = response?.data?.userData?.unique_url
                // console.log(signupData, 'name is here...')
                // navigate(`/${id}`) 
                localStorage.setItem('JLL_TOKEN', response?.data?.token)
                navigate(`/choose-plans`, { state: { firstName: signupData.first_name, newUser: true, unique_url: response?.data?.userData?.unique_url, email: signupData.email } })

            }

        } catch (e) {
            toastr.error("Please enter the otp first")
            // toastr.error(e?.data?.message)
            console.error(e)
        }
    }

    const handleResendCode = async (e) => {
        e.preventDefault();

        const payload = {
            "email": signupData.email
        }

        try {
            const response = await apiServiceHandler("POST", "api/auth/resend/code", payload)
            console.log(response)
            toastr.success("OTP Resent successfully")
            if (response?.status === true) {
                localStorage.setItem('responseData', JSON.stringify(response));
                localStorage.setItem('secret', response?.secret)
            }

        } catch (e) {
            toastr.error("Please enter the otp first")
            // toastr.error(e?.data?.message)
            console.error(e)
        }
    }

    return (
        <div class="bg-image">
            <div class="bg-box">
                <div class="logo text-center mb-4">
                    <img src={Logo} alt='icon' />
                </div>
                <div id="enterOTP" class="enter-opt shadow">
                    <h1 className="mb-1">Enter OTP</h1>
                    <p>We have just sent you 4 digit code via your Email ID <a href=''>{signupData.email}</a></p>
                    <form onSubmit={handleSignupVerify}>
                        <div class="opt-col my-4 pb-1">
                            <input onChange={(e) => setFirstDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(1)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setSecondDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(2)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setThirdDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(3)} maxLength={1} placeholder="" />
                            <input onChange={(e) => setFourthDigitOtp(e.target.value)} className="form-control" type="password" onInput={(e) => digitValidate(e)} onKeyUp={() => tabChange(4)} maxLength={1} placeholder="" />
                        </div>
                        <div className="resend-code">
                            <p>Didn't receive the code? <a href='#' onClick={handleResendCode}>Resend Code</a></p>
                        </div>
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default OtpVerifyCode;